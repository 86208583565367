import React from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { PyrotechnicsSaleFormFieldName } from './pyrotechnics-sale-form-field-name.enum'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { tr } from 'translations/translate'
import {
    TEXT_PYRO_SALE_PLACE,
    TEXT_PYRO_SALE_OUTDOORS,
    TEXT_PYRO_SALE_INDOORS,
    TEXT_PYRO_SALE_4,
    TEXT_PYRO_SALE_5,
    TEXT_OUTLET_LOCATION_SKETCH,
    TEXT_SKETCH,
    TEXT_PYRO_KEPT,
    TEXT_PYRO_APPROVED_CONTAINER,
    TEXT_PYRO_OWN_WAREHOUSE,
    TEXT_PYRO_SALE_6,
    TEXT_PYRO_SALE_7,
    TEXT_SKETCH_OF_CONTAINER_LOCATION,
    TEXT_PYRO_COMPANY_TEST,
} from 'translations/keys'

enum PyrotechnicsSalePlace {
    Outdoors = 'OUTDOORS',
    Indoors = 'INDOORS',
}

enum PyrotechnicsStoragePlace {
    Storage = 'STORAGE',
    Container = 'CONTAINER',
}

const Form: React.FC<InjectedFormProps<any>> = () => {
    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.PyrotechnicsSale)
    const pyrotechnicsSalePlace = selector(state, PyrotechnicsSaleFormFieldName.SalePlace)
    const pyrotechnicsStoragePlace = selector(state, PyrotechnicsSaleFormFieldName.StoragePlace)

    return (
        <form>
            <ContentSectionContainer>
                <Field
                    name={PyrotechnicsSaleFormFieldName.SalePlace}
                    label={tr(TEXT_PYRO_SALE_PLACE)}
                    component={CubitRadioGroupAdapter}
                    options={[
                        {
                            label: tr(TEXT_PYRO_SALE_OUTDOORS),
                            value: PyrotechnicsSalePlace.Outdoors,
                        },
                        {
                            label: tr(TEXT_PYRO_SALE_INDOORS),
                            value: PyrotechnicsSalePlace.Indoors,
                        },
                    ]}
                    validate={ValidatorRequired}
                    required
                    autoFocus
                />

                {pyrotechnicsSalePlace &&
                    (pyrotechnicsSalePlace === PyrotechnicsSalePlace.Indoors
                        ? tr(TEXT_PYRO_SALE_4)
                        : tr(TEXT_PYRO_SALE_5))}
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_OUTLET_LOCATION_SKETCH)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload
                    name={PyrotechnicsSaleFormFieldName.ReleaseLocationSketchFiles}
                    buttonTextKey={TEXT_SKETCH}
                />
            </ContentSectionContainer>

            <ContentSectionContainer>
                <Field
                    name={PyrotechnicsSaleFormFieldName.StoragePlace}
                    label={tr(TEXT_PYRO_KEPT)}
                    component={CubitRadioGroupAdapter}
                    options={[
                        {
                            label: tr(TEXT_PYRO_APPROVED_CONTAINER),
                            value: PyrotechnicsStoragePlace.Storage,
                        },
                        {
                            label: tr(TEXT_PYRO_OWN_WAREHOUSE),
                            value: PyrotechnicsStoragePlace.Container,
                        },
                    ]}
                    validate={ValidatorRequired}
                    required
                />

                {pyrotechnicsStoragePlace &&
                    (pyrotechnicsStoragePlace === PyrotechnicsStoragePlace.Storage
                        ? tr(TEXT_PYRO_SALE_6)
                        : tr(TEXT_PYRO_SALE_7))}
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_SKETCH_OF_CONTAINER_LOCATION)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload
                    name={PyrotechnicsSaleFormFieldName.ContainerLocationSketchFiles}
                    buttonTextKey={TEXT_SKETCH}
                />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_PYRO_COMPANY_TEST)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload
                    name={PyrotechnicsSaleFormFieldName.CompanyTestRegistryFiles}
                    buttonTextKey={TEXT_SKETCH}
                />
            </ContentSectionContainer>
        </form>
    )
}

export const PyrotechnicsSaleForm2 = reduxForm<{}>({
    form: FireFormName.PyrotechnicsSale,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
