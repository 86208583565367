import React from 'react'
import { tr } from 'translations/translate'
import {
    TEXT_REQUIRED_INFORMATION,
    TEXT_CONTACT_INFORMATION,
    TEXT_VA_PLANT,
    TEXT_REQUIRED,
} from 'translations/keys'
import { useDispatch } from 'react-redux'
import { actionFireSubmitForm } from 'fire/fire-actions'
import { startSubmit } from 'redux-form'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { FireHydrantControlForm1 } from './fire-hydrant-control-form-1'
import { FireHydrantControlForm2 } from './fire-hydrant-control-form-2'
import { FireHydrantControlForm3 } from './fire-hydrant-control-form-3'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'
import { FireHydrantFormFieldName } from './fire-hydrant-form-field-name.enum'
import { convertObjectToFormData } from 'utils'

const validate = (values: any) => {
    const errors: any = {}

    const checklist = values?.[FireHydrantFormFieldName.ConfirmationChecklist]
    const notAllChecklistItemsSelected = 6 !== checklist?.length

    if (!checklist || notAllChecklistItemsSelected) {
        errors[FireHydrantFormFieldName.ConfirmationChecklist] = tr(TEXT_REQUIRED)
    }
    return errors
}

export const FireHydrantControl: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        const formData = convertObjectToFormData(formValue, {})
        console.warn({ formData })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formData))
    }

    const fireHydrantControlSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_REQUIRED_INFORMATION,
            component: <FireHydrantControlForm1 initialValues={{ type: formDescriptor.type }} validate={validate} />,
        },
        {
            stepLabelTranslationKey: TEXT_CONTACT_INFORMATION,
            component: <FireHydrantControlForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_VA_PLANT,
            component: <FireHydrantControlForm3 onSubmit={handleSubmit} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={fireHydrantControlSteps}
        />
    )
}
