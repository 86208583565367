import React from 'react'
import { WrappedFieldArrayProps } from 'redux-form'

import { tr } from 'translations/translate'
import { TaxationPropertyObject } from 'tax/types/taxation-property-object.type'
import { Complaint } from 'tax/types/complaint.type'
import { ContentSection } from 'common/content-section/content-section'
import { ContentDivider } from 'common/content-divider/content-divider'
import { ComplaintCauseType } from 'tax/enums/complaint-cause-type.enum'
import {
    TEXT_TAX_COMPLAINT_CAUSE_1,
    TEXT_TAX_COMPLAINT_CAUSE_2,
    TEXT_TAX_COMPLAINT_CAUSE_3,
    TEXT_TAX_COMPLAINT_CAUSE_4,
    TEXT_TAX_COMPLAINT_CAUSE_5,
    TEXT_TAX_COMPLAINT_CAUSE_6,
    TEXT_TAX_COMPLAINT_CAUSE_7,
} from 'translations/keys'
import { ComplaintCausePlotArea } from './complaint-causes/complaint-cause-plot-area'
import { ConditionalFormContent } from 'common/conditional-form-content/conditional-form-content'
import { ComplaintCausePlotType } from './complaint-causes/complaint-cause-plot-type'
import { ComplaintCausePlotLocation } from './complaint-causes/complaint-cause-plot-location'
import { ComplaintCauseBuildingStandard } from './complaint-causes/complaint-cause-building-standard'
import { ComplaintCauseBuildingArea } from './complaint-causes/complaint-cause-building-area'
import { ComplaintCauseBuildingPhotos } from './complaint-causes/complaint-cause-building-photos'
import { ComplaintCauseOther } from './complaint-causes/complaint-cause-other'

export const ComplaintsPageFormCausesFieldArray: React.FC<WrappedFieldArrayProps<string> & {
    initialValues: Partial<Complaint>
    propertyObject: TaxationPropertyObject
}> = props => {
    const { fields, initialValues, propertyObject } = props

    const causes = initialValues.causes

    const getCauseFieldByType = (fieldName: string, causeType: ComplaintCauseType) => {
        const causeFieldsByType = {
            [ComplaintCauseType.PlotArea]: () => <ComplaintCausePlotArea fieldName={fieldName} />,
            [ComplaintCauseType.PlotType]: () => (
                <ComplaintCausePlotType fieldName={fieldName} initialValues={initialValues} />
            ),
            [ComplaintCauseType.PlotLocation]: () => <ComplaintCausePlotLocation fieldName={fieldName} />,
            [ComplaintCauseType.BuildingStandard]: () => (
                <ComplaintCauseBuildingStandard fieldName={fieldName} propertyObject={propertyObject} />
            ),
            [ComplaintCauseType.BuildingArea]: () => (
                <ComplaintCauseBuildingArea fieldName={fieldName} propertyObject={propertyObject} />
            ),
            [ComplaintCauseType.BuildingPhotos]: () => (
                <ComplaintCauseBuildingPhotos fieldName={fieldName} propertyObject={propertyObject} />
            ),
            [ComplaintCauseType.Other]: () => <ComplaintCauseOther fieldName={fieldName} />,
        }

        return causeFieldsByType[causeType]()
    }

    const getCauseContentByType = (fieldName: string, causeType: ComplaintCauseType) => (
        <ContentSection>
            <ConditionalFormContent
                label={tr(getCauseTitleTranslationKeyByType(causeType))}
                fieldName={`${fieldName}.selected`}
            >
                {getCauseFieldByType(fieldName, causeType)}
            </ConditionalFormContent>
        </ContentSection>
    )

    return (
        <>
            {fields.map((field: any, index: number) => {
                return (
                    <div key={index}>
                        {causes && getCauseContentByType(field, causes[index].type)}
                        {fields.length !== index + 1 && <ContentDivider />}
                    </div>
                )
            })}
        </>
    )
}

const getCauseTitleTranslationKeyByType = (causeType: ComplaintCauseType) => {
    const causeTitlesByType = {
        [ComplaintCauseType.PlotArea]: TEXT_TAX_COMPLAINT_CAUSE_1,
        [ComplaintCauseType.PlotType]: TEXT_TAX_COMPLAINT_CAUSE_2,
        [ComplaintCauseType.PlotLocation]: TEXT_TAX_COMPLAINT_CAUSE_3,
        [ComplaintCauseType.BuildingStandard]: TEXT_TAX_COMPLAINT_CAUSE_4,
        [ComplaintCauseType.BuildingArea]: TEXT_TAX_COMPLAINT_CAUSE_5,
        [ComplaintCauseType.BuildingPhotos]: TEXT_TAX_COMPLAINT_CAUSE_6,
        [ComplaintCauseType.Other]: TEXT_TAX_COMPLAINT_CAUSE_7,
    }

    return causeTitlesByType[causeType]
}
