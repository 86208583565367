import { AnyAction } from 'redux'
import { CadastreUnit } from './types/cadastre-unit.type'

export const ACTION_FIRE_GET_CADASTRE_UNIT = 'ACTION_FIRE_GET_CADASTRE_UNIT'
export const ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID = 'ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID'
export const ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID_SUCCESS = 'ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID_SUCCESS'
export const ACTION_FIRE_GET_CADASTRE_UNIT_SUCCESS = 'ACTION_FIRE_GET_CADASTRE_UNIT_SUCCESS'
export const ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION = 'ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION'
export const ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION_SUCCESS = 'ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION_SUCCESS'
export const ACTION_FIRE_CLEAR_CADASTRE_UNIT = 'ACTION_FIRE_CLEAR_CADASTRE_UNIT'
export const ACTION_FIRE_SUBMIT_FORM = 'ACTION_FIRE_SUBMIT_FORM'
export const ACTION_FIRE_SUBMIT_FORM_SUCCESS = 'ACTION_FIRE_SUBMIT_FORM_SUCCESS'
export const ACTION_FIRE_SUBMIT_FORM_FAILURE = 'ACTION_FIRE_SUBMIT_FORM_FAILURE'
export const ACTION_FIRE_GET_TOKEN = 'ACTION_FIRE_GET_TOKEN'
export const ACTION_FIRE_GET_TOKEN_SUCCESS = 'ACTION_FIRE_GET_TOKEN_SUCCESS'
export const ACTION_FIRE_GET_OWNER_ID_BY_TOKEN = 'ACTION_FIRE_GET_OWNER_ID_BY_TOKEN'
export const ACTION_FIRE_GET_OWNER_ID_BY_TOKEN_SUCCESS = 'ACTION_FIRE_GET_OWNER_ID_BY_TOKEN_SUCCESS'
export const ACTION_FIRE_SIGN_OUT = 'ACTION_FIRE_SIGN_OUT'
export const ACTION_FIRE_SIGN_OUT_SUCCESS = 'ACTION_FIRE_SIGN_OUT_SUCCESS'
export const ACTION_FIRE_GET_USER_INFO = 'ACTION_FIRE_GET_USER_INFO'
export const ACTION_FIRE_GET_USER_INFO_SUCCESS = 'ACTION_FIRE_GET_USER_INFO_SUCCESS'

export const ACTION_FIRE_FORM_VALIDATE = 'ACTION_FIRE_FORM_VALIDATE'
export const ACTION_FIRE_FORM_VALIDATE_SUCCESS = 'ACTION_FIRE_FORM_VALIDATE_SUCCESS'

export const LOAD_CHIMNEY_TYPES = 'LOAD_CHIMNEY_TYPES'
export const LOAD_CHIMNEY_TYPES_SUCCESS = 'LOAD_CHIMNEY_TYPES_SUCCESS'

export const actionFireGetCadastreUnit = (ownerId: string): AnyAction => ({
    type: ACTION_FIRE_GET_CADASTRE_UNIT,
    ownerId,
})

export const actionFireGetCadastreUnitById = (cadastreId: string): AnyAction => ({
    type: ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID,
    cadastreId,
})
export const actionFireGetCadastreUnitForOrganization = (organizationNumber: string): AnyAction => ({
    type: ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION,
    organizationNumber,
})

export const actionFireSignOut = (): AnyAction => ({
    type: ACTION_FIRE_SIGN_OUT
})
export const actionFireSignOutSuccess = (): AnyAction => ({
    type: ACTION_FIRE_SIGN_OUT_SUCCESS
})


export const actionFireGetCadastreUnitSuccess = (cadastreUnit: CadastreUnit): AnyAction => ({
    type: ACTION_FIRE_GET_CADASTRE_UNIT_SUCCESS,
    cadastreUnit,
})

export const actionFireGetCadastreUnitForOrganizationSuccess = (cadastreUnit: CadastreUnit): AnyAction => ({
    type: ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION_SUCCESS,
    payload: cadastreUnit,
})

export const actionFireClearCadastreUnit = (): AnyAction => ({
    type: ACTION_FIRE_CLEAR_CADASTRE_UNIT,
})

export const actionFireSubmitForm = (formName: string, formValue: any, routeParams?: string[]): AnyAction => ({
    type: ACTION_FIRE_SUBMIT_FORM,
    formName,
    formValue,
    routeParams: routeParams ?? [],
})

export const actionFireSubmitFormSuccess = (response: any): AnyAction => ({
    type: ACTION_FIRE_SUBMIT_FORM_SUCCESS,
    response,
})
export const actionFireSubmitFormFailure = (payload: any): AnyAction => ({
    type: ACTION_FIRE_SUBMIT_FORM_FAILURE,
    payload,
})

export const actionFireGetToken = (code: string): AnyAction => ({
    type: ACTION_FIRE_GET_TOKEN,
    code,
})

export const actionFireGetTokenSuccess = (token: string): AnyAction => ({
    type: ACTION_FIRE_GET_TOKEN_SUCCESS,
    token,
})

export const actionFireGetOwnerIdByToken = (token: string): AnyAction => ({
    type: ACTION_FIRE_GET_OWNER_ID_BY_TOKEN,
    token,
})

export const actionFireGetOwnerIdByTokenSuccess = (payload: any): AnyAction => ({
    type: ACTION_FIRE_GET_OWNER_ID_BY_TOKEN_SUCCESS,
    payload,
})

export const actionFireGetUserInfo = (payload: any): AnyAction => ({
    type: ACTION_FIRE_GET_USER_INFO,
    payload,
})

export const actionFireGetUserInfoSuccess = (payload: any): AnyAction => ({
    type: ACTION_FIRE_GET_USER_INFO_SUCCESS,
    payload,
})

export const actionValidateForm = (payload: any): AnyAction => ({
   type: ACTION_FIRE_FORM_VALIDATE,
    payload,
})

export const actionValidateFormSuccess = (payload: any): AnyAction => ({
   type: ACTION_FIRE_FORM_VALIDATE_SUCCESS,
    payload,
})

export const loadChimneyTypes = (): AnyAction => ({
    type: LOAD_CHIMNEY_TYPES
})
export const loadChimneyTypesSuccess = (payload: any): AnyAction => ({
    type: LOAD_CHIMNEY_TYPES_SUCCESS,
    payload,
})
