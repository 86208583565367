import React from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'

import { FireFormName } from 'fire/enums/form-name.enum'

import { CubitFileUpload, CubitRadioGroupAdapter, CubitDateTimePickerAdapter, CubitGeosuggestAdapter, CubitCheckboxAdapter, CubitYesNoRadio, CubitTextFieldAdapter, ContentSectionHeading, ContentSectionContainer } from 'common'


import { PyrotechnicsUseFormFieldName } from './pyrotechnics-use-form-field-name.enum'
import { Grid, FormControlLabel, FormGroup, FormControl } from '@material-ui/core'

import { ValidatorRequired, ValidatorEmail, ValidatorPhone } from 'utils/validators/validator-required'

import { tr } from 'translations/translate'
import * as t from 'translations/keys'

const Form: React.FC<InjectedFormProps<any>> = () => {
  return (
<form>
<ContentSectionContainer>

    <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_PLACE)}</ContentSectionHeading>

    <Field
    name={PyrotechnicsUseFormFieldName.Location}
    label={tr(t.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
    component={CubitGeosuggestAdapter}
    validate={ValidatorRequired}
    required
    autoFocus
    />
    </ContentSectionContainer>


    <ContentSectionContainer>
        <Field
            name={PyrotechnicsUseFormFieldName.LocationOutdoorsIndoors}
            label={tr(t.TEXT_PYRO_USE_PLACE)}
            component={CubitRadioGroupAdapter}
            options={[
                {
                    label: tr(t.TEXT_PYRO_SALE_OUTDOORS),
                    value: "OUTDOORS",
                },
                {
                    label: tr(t.TEXT_PYRO_SALE_INDOORS),
                    value: "INDOORS",
                },
            ]}
            validate={ValidatorRequired}
            required
        />


    </ContentSectionContainer>

    <Field
    name={PyrotechnicsUseFormFieldName.LocationDescription}
    label={tr(t.TEXT_PYROTECHNICS_LOCATION_DESCRIPTION)}
    component={CubitTextFieldAdapter}
    InputLabelProps={{
      shrink: true,
    }}
    variant="filled"
    />

    <div style={{ margin: '20px'}}></div>


    <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_DATE)}</ContentSectionHeading>


    <Grid container spacing={4}>
        <Grid item>
    <Field
    name={PyrotechnicsUseFormFieldName.DateFrom}
    component={CubitDateTimePickerAdapter}
    label={tr(t.TEXT_DATE_FROM)}
    InputLabelProps={{
      shrink: true,
    }}
    variant="filled"
    validate={ValidatorRequired}
    required
    />
      </Grid>
      <Grid item>

    <Field
    name={PyrotechnicsUseFormFieldName.DateTo}
    component={CubitDateTimePickerAdapter}
    label={tr(t.TEXT_DATE_TO)}
    InputLabelProps={{
      shrink: true,
    }}
    variant="filled"
    validate={ValidatorRequired}
    required
    />
    </Grid>
    </Grid>


    <div style={{ margin: '40px'}}></div>


    <Field
    name={PyrotechnicsUseFormFieldName.Description}
    label={tr(t.TEXT_PYROTECHNICS_DESCRIPTION)}
    component={CubitTextFieldAdapter}
    InputLabelProps={{
      shrink: true,
    }}
    variant="filled"
    />

    <div style={{ margin: '20px'}}></div>

    <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_ATTACHMENTS)}</ContentSectionHeading>

    <ContentSectionContainer>
        <CubitFileUpload
            name={PyrotechnicsUseFormFieldName.Attachments}
        />
    </ContentSectionContainer>


</form>

  )
}

export default reduxForm<{}>({
  form: FireFormName.PyrotechnicsUse,
  enableReinitialize: false,
  destroyOnUnmount: false,
})(Form)
