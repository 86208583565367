import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    sectionContainer: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(1),
        },
    },
}))

export const ContentSectionContainer: React.FC = props => {
    const styles = useStyles()

    return <section className={styles.sectionContainer}>{props.children}</section>
}
