import { tr } from 'translations/translate'

import { TEXT_REQUIRED, TEXT_VALID_EMAIL, TEXT_VALID_PHONE } from 'translations/keys'

export const ValidatorRequired = (value: any) => {
    let valueExists = value !== undefined && value !== null

    if (valueExists && Array.isArray(value)) {
        valueExists = value.length !== 0
    }

    return valueExists ? undefined : tr(TEXT_REQUIRED)
}

export const ValidatorChecked = (value: any) => {
    const valid = (value === true)
    return valid ? undefined : tr(TEXT_REQUIRED)
}

const email_re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const ValidatorEmail = (value: any) => {
  const valid  = email_re.test(String(value).toLowerCase())
  return valid ? undefined : tr(TEXT_VALID_EMAIL)
}

const phone_re = /^(0047|\+47|47)?[2-9]\d{7}$/
export const ValidatorPhone = (value: any) => {
  const valid  = phone_re.test(String(value).toLowerCase())
  return valid ? undefined : tr(TEXT_VALID_PHONE)
}
