import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
// @ts-ignore
import Lightbox from 'react-lightbox-component'
import 'react-lightbox-component/build/css/index.css'
import { find } from 'lodash'
import { InputType } from '../input-type.enum'
import { LightboxImage } from 'common/types/lightbox-image.type'

export type CubitSelectImagesAdapterProps = { type: InputType.Checkbox }

export const CubitSelectImagesAdapter: React.FC<CubitSelectImagesAdapterProps> = (props: any) => {
    const { name, options, valueIsObject = true, input, meta, ...custom } = props

    const images: LightboxImage[] = options.map((o: any) => ({
        src: o.value,
        title: ' ',
        description: ' ',
    }))

    return (
        <Lightbox
            images={images}
            thumbnailWidth="384px"
            thumbnailHeight="216px"
            renderImageFunc={(
                idx: number,
                image: LightboxImage,
                toggleLightbox: any,
                width: string,
                height: string,
            ) => {
                const option = options[idx]
                return (
                    <div
                        key={idx}
                        className="lightbox-img-thumbnail  lightbox-img-thumbnail--cubit"
                        style={{
                            backgroundImage: `url(${image.src})`,
                            width: width,
                            height: height,
                        }}
                        onClick={toggleLightbox.bind(null, idx)}
                    >
                        <Checkbox
                            onClick={e => e.stopPropagation()}
                            name={`${name}[${idx}]`}
                            checked={
                                valueIsObject
                                    ? !!find(input.value, { value: option.value })
                                    : input.value.indexOf(option.value) > -1
                            }
                            color="primary"
                            onChange={(e: any, checked: boolean) => {
                                let newValue = [...input.value]
                                if (checked) {
                                    newValue.push(
                                        valueIsObject ? { label: option.label, value: option.value } : option.value,
                                    )
                                } else {
                                    valueIsObject
                                        ? newValue.splice(newValue.indexOf(find(newValue, { value: option.value })), 1)
                                        : newValue.splice(newValue.indexOf(option.value), 1)
                                }

                                return input.onChange(newValue)
                            }}
                            style={{ backgroundColor: 'rgba(255,255,255,0.75)', margin: '4px' }}
                            {...custom}
                        />
                    </div>
                )
            }}
        />
    )
}
