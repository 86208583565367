import { from } from 'rxjs'
import superagent, { Response, SuperAgentRequest } from 'superagent'
import { Config } from 'config'

const cuddleAgent = (agent: SuperAgentRequest, sessionKey?: string, accessToken?: string) => {
    if (Config.sessionKey) {
        agent.set('Authorization', `Bearer ${Config.sessionKey}`)
    }
    if (sessionKey) {
        agent.set('SessionKey', `${sessionKey}`)
    }
    if (Config.sessionKey) {
        agent.set('Authorization', `Bearer ${Config.sessionKey}`)
        agent.set('Authentication', `session ${Config.sessionKey}`)
        agent.set('SessionKey', `${Config.sessionKey}`)
    }
    if (sessionKey) {
        agent.set('Authorization', `Bearer ${sessionKey}`)
        agent.set('Authentication', `session ${sessionKey}`)
    }
    if (accessToken) {
        agent.set('AccessToken', `${accessToken}`)
    }
    return agent
} // set some additional global headers e.g. return agent.set('Authorization', `Bearer ${sessionKey}`)

const selectBody = (response: Response) => response.body

export const httpGet = (url: string, sessionKey?: string, accessToken?: string) =>
    from(cuddleAgent(superagent.get(url), sessionKey, accessToken).then(selectBody))

export const httpGetWithAuthorization = (url: string, token: string) =>
    from(cuddleAgent(superagent.get(url).set('Authorization', `${token}`)).then(selectBody))

export const httpPost = (url: string, data: any, sessionKey?: string, accessToken?: string) =>
    from(
        cuddleAgent(superagent.post(url), sessionKey, accessToken)
            .send(data)
            .then(selectBody),
    )

export const httpUpload = (url: string, files: any) => {
    const agent = superagent.post(url)

    files.map((file: any) => agent.attach('documents', file))

    return from(cuddleAgent(agent).then(selectBody))
}

export const httpPut = (url: string, data: any) =>
    from(
        cuddleAgent(superagent.put(url))
            .send(data)
            .then(selectBody),
    )

export const httpDelete = (url: string, data: any) =>
    from(
        cuddleAgent(superagent.delete(url))
            .send(data)
            .then(selectBody),
    )

export const lanternUrl = (url: string): string => Config.lanternUrl + url

export const lanternUrlV2 = (url: string): string => Config.lanternUrl?.toString().replace('/api', '') + url

export const plantsUrl = (url: string): string => Config.plantsUrl + url

export const plantsProductionUrl = (url: string): string => Config.plantsUrl + url

export const auditProductionUrl = (url: string): string => Config.auditsUrl + url

export const companiesUrl = (url: string): string => Config.companiesUrl + url
