import React from 'react'
import {ContentContainer} from 'common/content-container/content-container'
import {tr} from 'translations/translate'
import {TEXT_SUBMIT} from 'translations/keys'
import LayoutDefault from 'common/layout-default/layout-default'
import {ContentSection} from 'common/content-section/content-section'
import {useDispatch, useSelector} from 'react-redux'
import {isSubmitting, isValid, submit} from 'redux-form'
import {FireLogo} from 'fire/fire-logo/fire-logo'
import {FireFormPageProps} from 'fire/types/fire-form-page-props.type'
import {OtherFormName} from "../../enums/form-name.enum";
import {CircularProgress, Grid} from "@material-ui/core";
import {ButtonContained} from "../../../common/button-contained/button-contained";
import {useParams} from "react-router-dom";
import {AppState} from "../../../app/app-store";
import {ProjectInfo} from "./project-info";
import {PostalForm} from "../postal-audit/postal-form";
import {actionSaveProjectForm} from "../../other-actions";
import {convertObjectToFormData} from "../../../utils";

export const Project: React.FC<FireFormPageProps> = props => {
    const dispatch = useDispatch()
    const isFormSubmitting = useSelector(isSubmitting(OtherFormName.ProjectForm))
    const isFormValid = useSelector(isValid(OtherFormName.ProjectForm))
    const {tenantId, projectId} = useParams()
    const project = useSelector((state: AppState) => state.other.project)
    const formStuff = useSelector((state: AppState) => state.form)

    const convertToArray = (obj: any) => {
      return  Object.keys(obj).map(((key) => {
            return {field:key, value:obj[key]}
        }));
    }
    const handleSubmit = () => {
        let results = []
        if (project ) {
            results = project.forms.map((form: any) => {
                return {formId: form.id, values: convertToArray(formStuff[form.id].values)}
            })
        }
        const formData = {tenantId, projectId, results: results}
        dispatch(actionSaveProjectForm(convertObjectToFormData(formData, {})))
    }
    return (
        <LayoutDefault logo={<FireLogo/>}>
            <ContentContainer>
                <ProjectInfo />
                <ContentSection>
                <div>
                    { project && project.forms.map((form:any) => <PostalForm key={form.id} form={form.id} formData={form} />)}
                </div>
                </ContentSection>
                <ContentSection>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <ButtonContained
                                    color="secondary"
                                    onClick={() => handleSubmit()}
                                    // onClick={() => dispatch(submit(OtherFormName.ProjectForm))}
                                    disabled={!isFormValid || isFormSubmitting}
                                >
                                    {isFormSubmitting && (
                                        <CircularProgress
                                            size="16px"
                                            style={{position: 'absolute', left: 0, right: 0, margin: 'auto'}}
                                        />
                                    )}
                                    <span style={isFormSubmitting ? {opacity: 0.5} : {}}>{tr(TEXT_SUBMIT)}</span>
                                </ButtonContained>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentSection>
            </ContentContainer>
        </LayoutDefault>
    )
}
