import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        padding: theme.spacing(2, 5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2),
        },
    },
}))

export const ContentSection: React.FC = props => {
    const styles = useStyles()

    return <section className={styles.section}>{props.children}</section>
}
