import React, { useState } from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector, change } from 'redux-form'
import { Grid } from '@material-ui/core'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { ChimneyInstallationFormFieldName } from './chimney-installation-form-field-name.enum'
import { tr } from 'translations/translate'
import {
    TEXT_JOB_APPLIES,
    TEXT_REHABILITATION_OF_EXISTING_CHIMNEY,
    TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY,
    TEXT_HOME_OWNER_CONTACT_PERSON,
} from 'translations/keys'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { ChimneyJobType } from './chimney-job-type.enum'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { HousingUnitAutocomplete } from 'fire/form-components/housing-unit-autocomplete'
import { HousingUnitInfo } from 'fire/components/housing-unit-info'
import { HousingUnitChimneySelect } from 'fire/form-components/housing-unit-chimney-select'
import { HousingUnitAutocompleteObject } from 'fire/types/housing-unit-autocomplete-object.type'
import { HousingUnitOwnerSelect } from 'fire/form-components/housing-unit-owner-select'

type ChimneyInstallationFormProps = {}

const Form: React.FC<InjectedFormProps<any, ChimneyInstallationFormProps> & ChimneyInstallationFormProps> = props => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const [
        housingUnitAutocompleteObject,
        setHousingUnitAutocompleteObject,
    ] = useState<HousingUnitAutocompleteObject | null>(null)

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ChimneyInstallation)
    const chimneyJobType = selector(state, ChimneyInstallationFormFieldName.ConstructionType)

    const handleHousingUnitChange = (value: HousingUnitAutocompleteObject) => {
        setHousingUnitAutocompleteObject(value)

        if (!value) {
            dispatch(change(FireFormName.ChimneyInstallation, ChimneyInstallationFormFieldName.ChimneyId, undefined))
            dispatch(change(FireFormName.ChimneyInstallation, ChimneyInstallationFormFieldName.OwnerId, undefined))
        }
    }

    const handleConstructionTypeChange = () => {
        dispatch(change(FireFormName.ChimneyInstallation, ChimneyInstallationFormFieldName.ChimneyId, undefined))
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <ContentSectionContainer>
                <Grid container spacing={4} direction="column">
                    <Grid item xs={12} sm={12} md={6}>
                        <Field
                            name={ChimneyInstallationFormFieldName.ConstructionType}
                            label={tr(TEXT_JOB_APPLIES)}
                            component={CubitRadioGroupAdapter}
                            onChange={handleConstructionTypeChange}
                            options={[
                                {
                                    label: tr(TEXT_REHABILITATION_OF_EXISTING_CHIMNEY),
                                    value: ChimneyJobType.Rehabilitation,
                                },
                                {
                                    label: tr(TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY),
                                    value: ChimneyJobType.NewConstruction,
                                },
                            ]}
                            validate={ValidatorRequired}
                            required
                            autoFocus
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <HousingUnitAutocomplete
                            name={ChimneyInstallationFormFieldName.HousingUnitId}
                            valueChanged={value => {
                                handleHousingUnitChange(value)
                            }}
                            valueSetter={value => value.housingUnit.id}
                        />
                    </Grid>

                    {housingUnitAutocompleteObject && (
                        <Grid item xs={12}>
                            <HousingUnitInfo housingUnitObject={housingUnitAutocompleteObject} />
                        </Grid>
                    )}

                    {chimneyJobType === ChimneyJobType.Rehabilitation && housingUnitAutocompleteObject && (
                        <Grid item xs={12}>
                            <HousingUnitChimneySelect
                                name={ChimneyInstallationFormFieldName.ChimneyId}
                                housingUnitObject={housingUnitAutocompleteObject}
                            />
                        </Grid>
                    )}

                    {housingUnitAutocompleteObject && (
                        <Grid item xs={6}>
                            <HousingUnitOwnerSelect
                                name={ChimneyInstallationFormFieldName.OwnerId}
                                label={tr(TEXT_HOME_OWNER_CONTACT_PERSON)}
                                housingUnitObject={housingUnitAutocompleteObject}
                            />
                        </Grid>
                    )}
                </Grid>
            </ContentSectionContainer>
        </form>
    )
}

export const ChimneyInstallationForm2 = reduxForm<{}, ChimneyInstallationFormProps>({
    form: FireFormName.ChimneyInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
