import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'

import { find } from 'lodash'
import { InputType } from '../input-type.enum'

export type CubitCheckboxGroupAdapterProps = { type: InputType.Checkbox }

export const CubitCheckboxGroupAdapter: React.FC<CubitCheckboxGroupAdapterProps> = (props: any) => {
    const { name, options, valueIsObject = true, row = true, input, meta, ...custom } = props

    const checkboxes = options.map((option: any, i: any) => {
        return (
            <FormControlLabel
                key={i}
                label={option.label}
                control={
                    <Checkbox
                        name={`${name}[${i}]`}
                        checked={
                            valueIsObject
                                ? !!find(input.value, { value: option.value })
                                : input.value.indexOf(option.value) > -1
                        }
                        label={option.label}
                        color="primary"
                        onChange={(e: any, checked: boolean) => {
                            let newValue = [...input.value]
                            if (checked) {
                                newValue.push(
                                    valueIsObject ? { label: option.label, value: option.value } : option.value,
                                )
                            } else {
                                valueIsObject
                                    ? newValue.splice(newValue.indexOf(find(newValue, { value: option.value })), 1)
                                    : newValue.splice(newValue.indexOf(option.value), 1)
                            }

                            return input.onChange(newValue)
                        }}
                        {...custom}
                    />
                }
            />
        )
    })

    return (
        <FormControl component="fieldset">
            <FormGroup row={row}> {checkboxes}</FormGroup>
        </FormControl>
    )
}
