import { tr } from 'translations/translate'

import { TEXT_REQUIRED } from 'translations/keys'
import { get } from 'lodash'

export const ValidatorRequiredIfOthersEmpty = (otherFieldNames: string[]) => {
    return (fieldValue: any, formValue: any) => {
        const valueExists = fieldValue !== undefined && fieldValue !== null

        if (valueExists) {
            return undefined
        } else {
            const otherValuesExist = otherFieldNames.reduce(
                (exists: boolean, fieldName: string) => (get(formValue, fieldName) ? true : exists),
                false,
            )
            return otherValuesExist ? undefined : tr(TEXT_REQUIRED)
        }
    }
}
