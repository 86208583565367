import {AnyAction} from 'redux'
import {makeReducer} from 'utils'
import {ActionReducer} from './types/action-reducer.type'
import {Reducers} from './types/reducers.type'
import {Config} from "../config";

export type AppDataState = {
    data: any
    sessionKey: string | undefined
    accessToken: string | undefined
    enabledTenants: any[]
    tenantId: string | undefined
    tenantData: any
    tenantSelectorVisible: boolean
    popup: any
}

type AppReducer = ActionReducer<AppDataState, AnyAction>

const loadData: AppReducer = state => state

const storeSessionKey: AppReducer = (state, action) => {
    state.sessionKey = action.sessionKey
    return state
}
const storeTenantId: AppReducer = (state, action) => {
    state.tenantId = action.tenantId
    window.localStorage.setItem('tenantId', action.tenantId)
    return state
}
const storeAccessToken: AppReducer = (state, action) => {
    state.accessToken = action.token
    return state
}
const storeEnabledTenants: AppReducer = (state, action) => {
    state.enabledTenants = action.payload
    return state
}

const storeTenantData: AppReducer = (state, action) => {
    window.localStorage.setItem('tenantData', JSON.stringify(action.payload))
    return {...state, tenantData: action.payload}
}
const setTenantSelector: AppReducer = (state, action) => {
    state.tenantSelectorVisible = action.payload
    return state
}

const reducers: Reducers<AppDataState> = {
    LOAD_DATA: loadData,
    ACTION_STORE_SESSION_KEY: storeSessionKey,
    ACTION_STORE_ACCESS_TOKEN: storeAccessToken,
    ACTION_STORE_ENABLED_TENANTS: storeEnabledTenants,
    ACTION_STORE_TENANT_ID: storeTenantId,
    ACTION_SET_STATE_TENANT_ID: storeTenantId,
    ACTION_STORE_TENANT_DATA: storeTenantData,
    ACTION_SET_TENANT_SELECTOR: setTenantSelector,
    ACTION_POPUP: (state, action) => {
        state.popup = action.payload
        return state
    }
}
const getInitialState = () => {

    const localTenantId = window.localStorage.getItem('tenantId')
    let tenantId = localTenantId ?? undefined

    if (window.location.pathname.startsWith('/tenant/'))
    {
        tenantId = undefined
    }

    const tenantData = null
    return {data: {}, enabledTenants:[],tenantSelectorVisible:false, sessionKey: Config.sessionKey, tenantId: tenantId, accessToken: undefined, popup: null, tenantData}
}

export const appReducer = makeReducer<AppDataState>(reducers, getInitialState())
