import { getPropertyAddresses } from 'utils'
import { PropertyType } from 'tax/enums/property-type.enum'
import { PropertyValueType } from 'tax/enums/property-value-type.enum'
import { Property } from 'tax/types/property.type'
import { Building } from 'tax/types/building.type'

export default <P extends Property, B extends Building>(property: P, buildings: B[]): P => {
    let prop = { ...property }

    const getPlotValue = (
        plotSize: number,
        isRegulated: boolean,
        propertyType: PropertyType,
        valueType: PropertyValueType,
    ): number => {
        const regulatedPlotMultipliersByType = {
            [PropertyValueType.BO]: [300, 50],
            [PropertyValueType.FR]: [150, 50],
            [PropertyValueType.GB]: [300, 0],
            [PropertyValueType.GF]: [150, 0],
            [PropertyValueType.GU]: [0, 0],
            [PropertyValueType.IV]: [0, 0],
            [PropertyValueType.KO]: [300, 50],
            [PropertyValueType.NE]: [300, 50],
            [PropertyValueType.UB]: [50, 50],
            [PropertyValueType.UF]: [50, 50],
            [PropertyValueType.UN]: [50, 50],
            [PropertyValueType.UT]: [1, 1],
            null: [0, 0],
        }

        const nonRegulatedPlotMultipliersByType = {
            [PropertyValueType.BO]: [300, 1],
            [PropertyValueType.FR]: [150, 1],
            [PropertyValueType.GB]: [300, 0],
            [PropertyValueType.GF]: [150, 0],
            [PropertyValueType.GU]: [0, 0],
            [PropertyValueType.IV]: [0, 0],
            [PropertyValueType.KO]: [300, 1],
            [PropertyValueType.NE]: [300, 1],
            [PropertyValueType.UB]: [1, 1],
            [PropertyValueType.UF]: [1, 1],
            [PropertyValueType.UN]: [1, 1],
            [PropertyValueType.UT]: [1, 1],
            null: [0, 0],
        }

        const plotMultiplier = isRegulated ? regulatedPlotMultipliersByType : nonRegulatedPlotMultipliersByType

        const plotValue =
            plotSize > 1000
                ? 1000 * plotMultiplier[valueType][0] + (plotSize - 1000) * plotMultiplier[valueType][1]
                : plotSize * plotMultiplier[valueType][0]

        return propertyType === PropertyType.Lease ? Math.round(plotValue / 2) : Math.round(plotValue)
    }

    prop.address = getPropertyAddresses(buildings)

    prop.plotSize = prop.plots.reduce((plotSize, plot) => plotSize + plot.area, 0)
    prop.plotValue = getPlotValue(prop.plotSize, prop.regulated, prop.type, property.valueType)
    prop.buildingsValue = buildings
        ? buildings.reduce((totalValue: number, building) => totalValue + building.value, 0)
        : 0
    prop.valueBeforeFactors = prop.plotValue + prop.buildingsValue
    prop.totalValue = prop.valueBeforeFactors * prop.zoneFactor * prop.locationFactor
    prop.plotSquareMeterValue = prop.plotSize ? prop.plotValue / prop.plotSize : 0

    return prop
}
