import React from 'react'
import css from './cubit-popup.module.css'

const Popup = (props: any) => {
  const { render, onClose } = props
  return (
    <div className={css.container}>
    <div className={css.child}>
    {false &&
      <div className={css.close}>
        <button onClick={onClose}>X</button>
      </div>
    }
      {render()}
      </div>
    </div>
  )
}

export default Popup
