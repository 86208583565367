export enum AccommodationNotificationFormFieldName {
    // form 1
    IsSomeoneElseResponsibleForReporting = 'isSomeoneElseResponsibleForReporting',
    ResponsibleReporterName = 'responsibleReporterName',
    ResponsibleReporterEmail = 'responsibleReporterEmail',
    ResponsibleReporterMobile = 'responsibleReporterMobile',
    Location = 'location',
    DateFrom = 'dateFrom',
    DateTo = 'dateTo',

    // form 2
    NumberOfPeople = 'numberOfPeople',
    AccommodationDescription = 'accommodationDescription',
    SafeguardInstructionsExist = 'safeguardInstructionsExist',

    // form 3
    FireSafetyChecklist = 'fireSafetyChecklist',
}
