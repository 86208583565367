import React from 'react'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import { InputType } from '../input-type.enum'
import { InputLabel, Divider, Chip, FormHelperText } from '@material-ui/core'
import { SelectOptionType } from '../cubit-form-field.types'

export type CubitSelectAdapterProps = { type: InputType.Select }

const CubitSelectAdapter: React.FC<CubitSelectAdapterProps> = (props: any) => {
    const {
        input: { name, value, onChange, onFocus, onBlur },
        disabled,
        options,
        valueIsObject = true,
        label,
        variant,
        formcontrolprops,
        InputLabelProps,
        meta: { touched, error },
        multiple,
        required,
        ...rest
    } = props

    const valueRendererProps = { selectedValue: value, options, onChange, disabled }
    const valueRenderer = multiple
        ? valueIsObject
            ? renderSelectedMultipleObjects
            : renderSelectedMultipleValues
        : valueIsObject
        ? renderSelectedObject
        : renderSelectedValue

    return (
        <FormControl variant={variant} required={required} error={touched && error ? true : false}>
            <FormGroup row>
                {label ? <InputLabel shrink={InputLabelProps && InputLabelProps.shrink}>{label}</InputLabel> : null}
                <Select
                    {...rest}
                    name={name}
                    value={value}
                    multiple={multiple}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    renderValue={() => valueRenderer(valueRendererProps)}
                    style={{ minWidth: '200px' }}
                >
                    {options.map((option: any, index: number) =>
                        option.type === SelectOptionType.Title ? (
                            <li key={index} style={{ padding: '8px', cursor: 'default' }}>
                                {option.label}
                            </li>
                        ) : option.type === SelectOptionType.Divider ? (
                            <Divider key={index}></Divider>
                        ) : (
                            <MenuItem key={index} value={valueIsObject ? option : option.value} style={option.style}>
                                {option.label}
                            </MenuItem>
                        ),
                    )}
                </Select>
                {touched && error && <FormHelperText>{error}</FormHelperText>}
            </FormGroup>
        </FormControl>
    )
}

export { CubitSelectAdapter }

type ValueRendererProps = {
    selectedValue: any
    options: any
    onChange: (value: any) => void
    disabled: boolean
}

type ChipsRendererProps = {
    selectedValues: any
    selectedValuesLabels: string
    onDelete: (valueToDelete: any) => void
    disabled: boolean
}

type ChipRendererProps = {
    index: number
    label: string
    value: any
    selectedValues: any
    onDelete: (valueToDelete: any) => void
    disabled: boolean
}

const renderSelectedValue = (props: ValueRendererProps) => {
    const option = props.options.filter((o: any) => o.value === props.selectedValue)[0]
    return option ? option.label : ''
}

const renderSelectedObject = (props: ValueRendererProps) => props.selectedValue.label

const renderSelectedMultipleValues = (props: ValueRendererProps) => {
    const { selectedValue: selectedValues, onChange, disabled } = props
    const onDelete = (valueToDelete: any) => onChange(selectedValues.filter((v: any) => v !== valueToDelete))
    const selectedValuesLabels = selectedValues.map((val: any) => {
        const option = props.options.find((o: any) => o.value === val)
        return option.label
    })

    return renderChips({ selectedValues, selectedValuesLabels, onDelete, disabled })
}

const renderSelectedMultipleObjects = (props: ValueRendererProps) => {
    const { selectedValue: selectedValues, onChange, disabled } = props
    const selectedValuesLabels = selectedValues.map((v: any) => v.label)
    const onDelete = (valueToDelete: any) =>
        onChange(selectedValues.filter((v: any) => v.value !== valueToDelete.value))

    return renderChips({ selectedValues, selectedValuesLabels, onDelete, disabled })
}

const renderChips = (props: ChipsRendererProps) => {
    const { selectedValues, selectedValuesLabels, onDelete, disabled } = props
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedValues.map((value: any, index: number) =>
                renderChip({ index, label: selectedValuesLabels[index], value, selectedValues, onDelete, disabled }),
            )}
        </div>
    )
}

const renderChip = (props: ChipRendererProps) => (
    <Chip
        onClick={props.disabled ? undefined : e => e.stopPropagation()}
        key={props.index}
        label={props.label}
        onDelete={props.disabled ? undefined : () => props.onDelete(props.value)}
        style={{ margin: '0 6px 6px 0' }}
        disabled={props.disabled}
    />
)
