import React from 'react'
import { Typography, makeStyles, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        color: theme.palette.common.black,
        padding: theme.spacing(2, 5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2),
        },

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            opacity: 0.8,
        },
    },
    link: {
        textDecoration: 'none',
    },
}))

export const ContentLink: React.FC<{ to: string }> = props => {
    const { to } = props
    const styles = useStyles()

    return (
        <Link to={to} className={styles.link}>
            <Typography variant="h5" className={styles.heading}>
                {props.children}
            </Typography>
        </Link>
    )
}
