import React from 'react'
import { useDispatch } from 'react-redux'
import { startSubmit } from 'redux-form'
import { convertObjectToFormData } from 'utils'

import { actionFireSubmitForm } from 'fire/fire-actions'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'

import {
    TEXT_RESPONSIBLE,
    TEXT_PYROTECHNICS_USE_STEP2,
} from 'translations/keys'

import Form1 from './pyrotechnics-use-form-1'
import Form2 from './pyrotechnics-use-form-2'

const validate = (values: any) => {
  const errors: any = {}
  return errors
}

export const PyrotechnicsUse: React.FC<FireFormPageProps> = props => {

  const { formDescriptor } = props
  const dispatch = useDispatch()

  const handleSubmit = (formValue: any) => {
    console.warn({ formValue })

    const formData = convertObjectToFormData(formValue, {})
    console.warn({ formData })

    dispatch(startSubmit(formDescriptor.name))
    dispatch(actionFireSubmitForm(formDescriptor.name, formData))
  }
  const steps: CubitStepperStep[] = [
    {
      stepLabelTranslationKey: TEXT_RESPONSIBLE,
      component: <Form1 initialValues={{ type: formDescriptor.type }} validate={validate} />,
    },
    {
      stepLabelTranslationKey: TEXT_PYROTECHNICS_USE_STEP2,
      component: <Form2 onSubmit={handleSubmit} />,
    },
  ]
  return (
    <CubitStepper
    formName={formDescriptor.name}
    steps={steps}
    />
  )
}
