import React from 'react'
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { ChimneyJobType } from './chimney-job-type.enum'
import { tr } from 'translations/translate'
import {
    TEXT_SKETCH,
    TEXT_TARGETED_SKETCH,
    TEXT_DRAWINGS,
    TEXT_MOUNTING_DESCRIPTION,
    TEXT_NOTIFICATION_TO_NEIGHBORS,
    TEXT_CENTRAL_APPROVAL,
    TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED,
    TEXT_CENTRAL_APPROVAL_UPLOADS,
} from 'translations/keys'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { ChimneyInstallationFormFieldName } from './chimney-installation-form-field-name.enum'

type ChimneyInstallationFormProps = {}

const Form: React.FC<InjectedFormProps<any, ChimneyInstallationFormProps> & ChimneyInstallationFormProps> = props => {
    const { handleSubmit } = props

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ChimneyInstallation)
    const chimneyJobType = selector(state, ChimneyInstallationFormFieldName.ConstructionType)

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <ContentSectionHeading variant="subtitle1">{tr(TEXT_DRAWINGS)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload autoFocus name={ChimneyInstallationFormFieldName.DrawingFiles} buttonTextKey={TEXT_DRAWINGS} />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_TARGETED_SKETCH)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload
                    name={ChimneyInstallationFormFieldName.TargetedSketchFiles}
                    buttonTextKey={TEXT_SKETCH}
                />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_MOUNTING_DESCRIPTION)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={ChimneyInstallationFormFieldName.MountingDescriptionFiles} />
            </ContentSectionContainer>

            {chimneyJobType === ChimneyJobType.NewConstruction && (
                <>
                    <ContentSectionHeading variant="subtitle1">
                        {tr(TEXT_NOTIFICATION_TO_NEIGHBORS)}
                    </ContentSectionHeading>

                    <ContentSectionContainer>
                        <CubitFileUpload name={ChimneyInstallationFormFieldName.NotificationToNeighboursFiles} />
                    </ContentSectionContainer>
                </>
            )}

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_CENTRAL_APPROVAL)}</ContentSectionHeading>

            <div>{tr(TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED)}</div>
            <div>
                <i>{tr(TEXT_CENTRAL_APPROVAL_UPLOADS)}</i>
            </div>

            <br />

            <ContentSectionContainer>
                <CubitFileUpload name={ChimneyInstallationFormFieldName.CentralApprovalFiles} />
            </ContentSectionContainer>
        </form>
    )
}

export const ChimneyInstallationForm4 = reduxForm<{}, ChimneyInstallationFormProps>({
    form: FireFormName.ChimneyInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
