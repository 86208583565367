export enum FireFormName {
    FireplaceInstallation = 'fireplaceInstallationForm',
    FireplaceRemoval = 'fireplaceRemovalForm',
    ScrapValue = 'scrapValueForm',
    EventNotification = 'eventNotificationForm',
    AccommodationNotification = 'accommodationNotificationForm',
    TankRemediation = 'tankRemediationForm',
    TankRemovalExemption = 'tankRemovalExemptionForm',
    TankRemoval = 'tankRemovalForm',
    FireHydrantControl = 'fireHydrantControlForm',
    ChimneyInstallation = 'ChimneyInstallation',
    PyrotechnicsUse = 'PyrotechnicsUse',
    PyrotechnicsSale = 'PyrotechnicsSale',
    SmokelessGunpowderStore = 'SmokelessGunpowderStore',
    ConcernCase = 'concernCaseForm',
}
