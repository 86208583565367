import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import {
    TEXT_VA_IS_CONFIRMED,
    TEXT_VA_LID_AVAILABLE,
    TEXT_VA_CORRECT_COVER_FITTED,
    TEXT_VA_VALVE_PROTECTION_REMOVED,
    TEXT_VA_WATER_ON_VALVE,
    TEXT_VA_DISTANCE,
    TEXT_VA_WIRED_CAP,
    TEXT_ATTACH_MAP_SECTION,
    TEXT_AS_BUILT_DRAWINGS,
    TEXT_MAP_SECTIONS,
    TEXT_DRAWINGS,
} from 'translations/keys'
import { tr } from 'translations/translate'
import { FireHydrantFormFieldName } from './fire-hydrant-form-field-name.enum'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import {ValidatorRequired} from "../../../utils/validators/validator-required";

const Form: React.FC<InjectedFormProps<any>> = () => {
    const fireHydrantChecklistOptions = [
        {
            label: tr(TEXT_VA_LID_AVAILABLE),
            value: 0,
        },
        {
            label: tr(TEXT_VA_CORRECT_COVER_FITTED),
            value: 1,
        },
        {
            label: tr(TEXT_VA_VALVE_PROTECTION_REMOVED),
            value: 2,
        },
        {
            label: tr(TEXT_VA_WATER_ON_VALVE),
            value: 3,
        },
        {
            label: tr(TEXT_VA_DISTANCE),
            value: 4,
        },
        {
            label: tr(TEXT_VA_WIRED_CAP),
            value: 5,
        },
    ]

    return (
        <form>
            <ContentSectionHeading variant="subtitle1"> {tr(TEXT_VA_IS_CONFIRMED)}:</ContentSectionHeading>

            <ContentSectionContainer>
                <Field
                    name={FireHydrantFormFieldName.ConfirmationChecklist}
                    component={CubitCheckboxGroupAdapter}
                    valueIsObject={false}
                    row={false}
                    options={fireHydrantChecklistOptions}
                />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_ATTACH_MAP_SECTION)}:</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={FireHydrantFormFieldName.MapSectionFiles} validate={ValidatorRequired} buttonTextKey={TEXT_MAP_SECTIONS} />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle1">{tr(TEXT_AS_BUILT_DRAWINGS)}:</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={FireHydrantFormFieldName.DrawingFiles} validate={ValidatorRequired} buttonTextKey={TEXT_DRAWINGS} />
            </ContentSectionContainer>
        </form>
    )
}

export const FireHydrantControlForm1 = reduxForm<{}>({
    form: FireFormName.FireHydrantControl,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
