import { Address } from './address.type'

export enum OwnerType {
    Private = 'PrivateOwner',
    Company = 'CompanyOwner',
}

export type BaseOwner = {
    id: string
    type: string
    number: string
    email: string
    phone: string
    status: string
    acceptElectronicCommunication: boolean
}

export type PrivateOwner = {
    type: OwnerType.Private
    firstName: string
    lastName: string
    residentialAddress: Address
    postalAddress: Address
    birthDate?: Date
} & BaseOwner

export type CompanyOwner = {
    type: OwnerType.Company
    name: string
    businessAddress: Address
    postalAddress: Address
    organizationNumber: string
} & BaseOwner

export type Owner = PrivateOwner | CompanyOwner

export type OwnerAutocompleteResult = {
    firstName: string
    lastName: string
    name: string
    residentialAddress: Address
    postalAddress: Address
    birthDate: Date
    id: string
    number: string
    email: null
    phone: null
    status: null
    acceptElectronicCommunication: false
    noCorrespondance: false
}
