import React from 'react'
import { tr } from 'translations/translate'
import {
    TEXT_ACCOMMODATION,
    TEXT_ABOUT_ACCOMMODATION,
    TEXT_FIRE_SAFETY,
    TEXT_REQUIRED,
} from 'translations/keys'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { useDispatch } from 'react-redux'
import { startSubmit } from 'redux-form'
import { actionFireSubmitForm } from 'fire/fire-actions'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { AccommodationNotificationForm1 } from './accommodation-notification-form-1'
import { AccommodationNotificationForm2 } from './accommodation-notification-form-2'
import { AccommodationNotificationForm3 } from './accommodation-notification-form-3'
import { AccommodationNotificationFormFieldName } from './accommodation-notification-field-name.enum'
import { AccommodationConfirmationOption } from './accommodation-confirmation-option.enum'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

const validate = (values: any) => {
    const errors: any = {}

    console.warn(values)

    const checklist = values[AccommodationNotificationFormFieldName.FireSafetyChecklist]
    const notAllChecklistItemsSelected =
        checklist && Object.keys(AccommodationConfirmationOption).length !== checklist.length

    if (!checklist || notAllChecklistItemsSelected) {
        errors[AccommodationNotificationFormFieldName.FireSafetyChecklist] = tr(TEXT_REQUIRED)
    }
    return errors
}

export const AccommodationNotification: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formValue))
    }

    const fireplaceInstallationSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_ACCOMMODATION,
            component: <AccommodationNotificationForm1 initialValues={{ type: formDescriptor.type }} />,
        },
        {
            stepLabelTranslationKey: TEXT_ABOUT_ACCOMMODATION,
            component: <AccommodationNotificationForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_FIRE_SAFETY,
            component: <AccommodationNotificationForm3 onSubmit={handleSubmit} validate={validate} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={fireplaceInstallationSteps}
        />
    )
}
