import { en } from './languages/en'
import { nb } from './languages/nb'
import { Language } from 'common/enums/language.enum'
import { Config } from 'config'

export const strings: any = {}

strings[Language.English] = en
strings[Language.Norwegian] = nb

const getLang = (): string => {
    const lang = localStorage.getItem(Config.localStorageLangKey)
    return lang !== null ? lang : Config.language
}

export const tr = (key: string, params?: Array<any>): string => {
    const lang = getLang()
    const tenantDataString = window.localStorage.getItem('tenantData')
    const tenantData = (tenantDataString) ? JSON.parse(tenantDataString) : null
    //this is unused intentionally because of eval function below. {tenantName} can be used in translations
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tenantName = tenantData ? tenantData.name : ''
    let translated = ''
    try {
        const translatedString = strings[lang][key] || key
        // eslint-disable-next-line
        translated = eval('`' + translatedString + '`')
    } catch (e) {
        console.error('translation error', { key, params })
    }

    return translated
}
