import {AnyAction} from 'redux'


export const ACTION_OTHER_LOAD_POSTAL_AUDIT = 'ACTION_OTHER_LOAD_POSTAL_AUDIT'
export const ACTION_OTHER_POSTAL_AUDIT_LOADED = 'ACTION_OTHER_POSTAL_AUDIT_LOADED'
export const ACTION_POST_POSTAL_AUDIT = 'ACTION_POST_POSTAL_AUDIT'
export const ACTION_POSTAL_AUDIT_SUCCESS = 'ACTION_POSTAL_AUDIT_SUCCESS'
export const ACTION_SAVE_MAPPING_RESPONSE = 'ACTION_SAVE_MAPPING_RESPONSE'
export const ACTION_OTHER_LOAD_PROJECT = 'ACTION_OTHER_LOAD_PROJECT'
export const ACTION_PROJECT_LOADED = 'ACTION_PROJECT_LOADED'
export const ACTION_SAVE_PROJECT_FORM = 'ACTION_SAVE_PROJECT_FORM'
export const ACTION_SAVE_PROJECT_FORM_SUCCESS = 'ACTION_SAVE_PROJECT_FORM_SUCCESS'

export const actionLoadPostalAudit = (tenantId: string, auditId: string, pin: string): AnyAction => ({
    type: ACTION_OTHER_LOAD_POSTAL_AUDIT,
    tenantId, auditId, pin
})

export const actionLoadProject = (tenantId: string, projectId: string): AnyAction => ({
    type: ACTION_OTHER_LOAD_PROJECT,
    tenantId, projectId
})
export const actionProjectLoaded = (project: any): AnyAction => ({
    type: ACTION_PROJECT_LOADED,
    project
})
export const actionSaveProjectForm = (data: any): AnyAction => ({
    type: ACTION_SAVE_PROJECT_FORM,
    data
})
export const actionSaveProjectFormSuccess = (): AnyAction => ({
    type: ACTION_SAVE_PROJECT_FORM_SUCCESS
})
export const actionPostalAuditLoaded = (audit: any): AnyAction => ({
    type: ACTION_OTHER_POSTAL_AUDIT_LOADED,
    audit
})

export const actionPostPostalAudit = (data: any): AnyAction => ({
    type: ACTION_POST_POSTAL_AUDIT,
    data
})
export const actionPostalAuditSuccess = (audit: any): AnyAction => ({
    type: ACTION_POSTAL_AUDIT_SUCCESS
})
export const actionSaveMappingResponse = (data: any): AnyAction => ({
    type: ACTION_SAVE_MAPPING_RESPONSE,
    data
})