import React from 'react'
import { Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'

export const CubitTextarea: React.FC<{
    name: string
    label: string
    placeholder?: string
    required?: boolean
    autoFocus?: boolean
}> = props => {
    const { label, required = false, autoFocus, ...attributes } = props

    return (
        <Field
            {...attributes}
            label={required ? `${label} * ` : label}
            component={CubitTextFieldAdapter}
            InputLabelProps={{
                shrink: true,
            }}
            variant="filled"
            validate={required ? ValidatorRequired : undefined}
            multiline
            autoFocus={autoFocus}
        />
    )
}
