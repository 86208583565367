import React from 'react'
import Geosuggest, { Suggest } from 'react-geosuggest'
import './cubit-geosuggest-adapter.css'
import { Location } from '../../../common/types/location.type'
import { get } from 'lodash'

export const CubitGeosuggestAdapter: React.FC<any> = props => {
    const { input, field, label, style, variant, meta, ...rest } = props

    const handleSuggestSelect = (suggest: Suggest) => {
        if (suggest) {
            const address =
                suggest.gmaps && suggest.gmaps.formatted_address ? suggest.gmaps.formatted_address : suggest.label
            const name = get(suggest, 'gmaps.name') || '' // workaround missing type with get

            const loc: { lat: number; lng: number } = suggest.location

            const location: Location = {
                ...loc,
                name,
                address,
            }

            input.onChange(location)
        }
    }

    const handleChange = () => {
        // all changes should reset input value, only suggest select should set value to input
        input.onChange('')
    }

    return (
        <Geosuggest
            id="geoSuggest"
            label={label}
            country="NO"
            queryDelay={250}
            placeholder=""
            onSuggestSelect={handleSuggestSelect}
            onChange={handleChange}
            autoComplete="off"
            {...rest}
        />
    )
}
