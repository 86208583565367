import React, { useEffect, useCallback } from 'react'
import { change, Field } from 'redux-form'
import { CubitSelectAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { FormFieldName } from 'fire/forms/form-field-name.enum'
import { useFireCadastreUnit } from '../fire-selectors'
import { useDispatch } from 'react-redux'

const DEFAULT_INPUT_NAME = FormFieldName.HousingUnitId
const FILTERED_CODES = [181, 182]

export const HousingUnitSelect: React.FC<{
    housingUnits: HousingUnit[]
    label: string
    name?: string
    disabled?: boolean
    formName?: string
}> = props => {
    const { housingUnits, label, name = DEFAULT_INPUT_NAME, disabled, formName } = props
    const cadastreUnit = useFireCadastreUnit()
    const dispatch = useDispatch()
    const buildings = (cadastreUnit && cadastreUnit.buildings) || []
    const buildingCodes = (cadastreUnit && cadastreUnit.buildingCodes) || []
    const getLabelFromBuilding = (h: HousingUnit) => {
        const building = buildings.find(b => b.id === h.buildingId)
        const buildingCode = building && buildingCodes.find(bc => bc.code === building.buildingCode)
        if (building && buildingCode) {
            return `${buildingCode.description} (${building.buildingCode})`
        }
    }

    const filterHousingUnits = useCallback(
        (h: HousingUnit) => {
            const building = buildings.find(b => b.id === h.buildingId)
            return building && FILTERED_CODES.indexOf(building.buildingCode) === -1
        },
        [buildings],
    )

    useEffect(() => {
        const filtered = housingUnits.filter(filterHousingUnits)
        if (filtered.length === 1 && formName) {
            dispatch(change(formName, FormFieldName.HousingUnitId, filtered[0].id))
        }
    }, [dispatch, formName, housingUnits, filterHousingUnits])

    
    const getLabel = (h: any) => {
      if (h.address.addressText)
        return `${h.address.addressText} ${h.address.residentialNumber}`
      if (h.matrikkelNumber)
        return `${h.matrikkelNumber} ${h.type}`
      return getLabelFromBuilding(h)
    }

    const selectionOptions = housingUnits.filter(filterHousingUnits).map((h: any) => ({
        label: getLabel(h),
        value: h.id,
    }))

    selectionOptions.sort((a: any, b: any) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    return (
        <Field
            name={name}
            component={CubitSelectAdapter}
            label={label}
            valueIsObject={false}
            options={selectionOptions}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={disabled}
            variant="filled"
            validate={ValidatorRequired}
        />
    )
}
