import { Language } from 'common/enums/language.enum'
import { Config } from 'config'

const langMap: { [key: string]: string } = {
    [Language.Norwegian]: 'nb-no',
    [Language.English]: 'en-gb',
}

export const formatNumber = (number: number, maximumFractionDigits = 2): string => {
    const lang = localStorage.getItem(Config.localStorageLangKey) || Config.language

    return new Intl.NumberFormat(langMap[lang], { style: 'decimal', maximumFractionDigits }).format(number)
}
