import {combineEpics} from 'redux-observable'
import {Observable} from 'rxjs'
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators'
import {AnyAction} from 'redux'
import {auditProductionUrl, httpGet, httpPost, lanternUrl,} from 'common/services/http-service'
import {defaultCatchError} from 'app/app-epics'
import {
    ACTION_OTHER_LOAD_POSTAL_AUDIT,
    ACTION_POST_POSTAL_AUDIT,
    actionPostalAuditLoaded,
    actionPostalAuditSuccess,
    ACTION_SAVE_MAPPING_RESPONSE,
    ACTION_OTHER_LOAD_PROJECT,
    actionProjectLoaded,
    ACTION_SAVE_PROJECT_FORM,
    actionSaveProjectFormSuccess
} from "./other-actions";
import history from "../app/history";
import {FireRoute} from "../app/enums/fire-route.enum";
import {AppState} from "../app/app-store";

export const loadPostalAuditEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_OTHER_LOAD_POSTAL_AUDIT),
        flatMap(
            (action): Observable<AnyAction> =>
                httpGet(
                    lanternUrl(`/crosstenant/getPostalAudit/${action.tenantId}/${action.auditId}/${action.pin}`)
                ).pipe(
                    map((result: any) => {
                        return actionPostalAuditLoaded(result)
                    }),
                    defaultCatchError(),
                ),
        ),

    )

export const postPostalAuditEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_POST_POSTAL_AUDIT),
        flatMap(
            (action): Observable<AnyAction> =>
                httpPost(
                    auditProductionUrl(`/audit/savePostalAudit`),
                    action.data
                ).pipe(
                    map((audit: any) => {
                        //const redirectUrl = `${history.location.pathname}${FireRoute.ThankYou}`
                        history.push(FireRoute.ThankYou)
                        return actionPostalAuditSuccess(audit)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const saveMappingResponseEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_SAVE_MAPPING_RESPONSE),
        flatMap(
            (action): Observable<AnyAction> =>
                httpPost(
                    auditProductionUrl(`/audit/saveMappingResponse`),
                    action.data
                ).pipe(
                    map((audit: any) => {
                        history.push(FireRoute.ThankYou)
                        return actionPostalAuditSuccess(audit)
                    }),
                    defaultCatchError(),
                ),
        ),

    )

export const loadProjectEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_OTHER_LOAD_PROJECT),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const sessionKey = state.appData.sessionKey
               return httpGet(
                    auditProductionUrl(`/projects/${action.projectId}`),
                   sessionKey
                ).pipe(
                    map((result: any) => {
                        return actionProjectLoaded(result)
                    }),
                    defaultCatchError(),
                )
            }

        ),

    )

export const saveProjectFormEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_SAVE_PROJECT_FORM),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const sessionKey = state.appData.sessionKey
               return httpPost(
                    auditProductionUrl(`/projects/saveProjectForm`),
                    action.data,
                   sessionKey
                ).pipe(
                    map(() => {
                        history.push(FireRoute.ThankYou)
                        return actionSaveProjectFormSuccess()
                    }),
                    defaultCatchError(),
                )
            }
        ),
    )

export const otherEpics = combineEpics(loadPostalAuditEpic, postPostalAuditEpic, saveMappingResponseEpic, loadProjectEpic, saveProjectFormEpic)
