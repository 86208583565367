import React from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'
import { Grid } from '@material-ui/core'
import { EventNotificationFormFieldName } from './event-notification-form-field-name.enum'
import { tr } from 'translations/translate'
import {
    TEXT_DESCRIBE_ARRANGEMENT,
    TEXT_DATE_FROM,
    TEXT_DATE_TO,
    TEXT_IS_PYROTECHNICS_WILL_BE_USED,
    TEXT_EVENT_3,
    TEXT_IS_FIRE_SERVICE_SAFEGUARDED,
} from 'translations/keys'
import { ValidatorRequired } from 'utils/validators/validator-required'
import CubitDateTimePickerAdapter from 'common/cubit-inputs/react-form-adapters/cubit-date-time-picker-adapter'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { CubitTextarea } from 'common/cubit-textarea/cubit-textarea'
import { FireFormName } from 'fire/enums/form-name.enum'

type EventNotificationFormProps = {}

const Form: React.FC<InjectedFormProps<any, EventNotificationFormProps> & EventNotificationFormProps> = props => {
    const { handleSubmit } = props

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.EventNotification)
    const isPyrotechnicsUsed = selector(state, EventNotificationFormFieldName.IsPyrotechnicsUsed)

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CubitTextarea
                        name={EventNotificationFormFieldName.ArrangementDescription}
                        label={tr(TEXT_DESCRIBE_ARRANGEMENT)}
                        required
                        autoFocus
                    />
                </Grid>

                <Grid item>
                    <Field
                        name={EventNotificationFormFieldName.DateFrom}
                        component={CubitDateTimePickerAdapter}
                        label={tr(TEXT_DATE_FROM)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item>
                    <Field
                        name={EventNotificationFormFieldName.DateTo}
                        component={CubitDateTimePickerAdapter}
                        label={tr(TEXT_DATE_TO)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <CubitYesNoRadio
                        name={EventNotificationFormFieldName.IsPyrotechnicsUsed}
                        label={tr(TEXT_IS_PYROTECHNICS_WILL_BE_USED)}
                        validate={ValidatorRequired}
                    />
                </Grid>

                {isPyrotechnicsUsed && (
                    <Grid item xs={12}>
                        {tr(TEXT_EVENT_3)}
                    </Grid>
                )}

                <Grid item xs={12}>
                    <CubitYesNoRadio
                        name={EventNotificationFormFieldName.IsAccessibilityToFireServiceSafeguarded}
                        label={tr(TEXT_IS_FIRE_SERVICE_SAFEGUARDED)}
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const EventNotificationForm2 = reduxForm<{}, EventNotificationFormProps>({
    form: FireFormName.EventNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
