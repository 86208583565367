import { ActionReducer } from 'app/types/action-reducer.type'
import { Reducers } from 'app/types/reducers.type'
import { makeReducer } from 'utils'
import { ACTION_AUTOCOMPLETE_GET, ACTION_AUTOCOMPLETE_GET_SUCCESS } from './autocomplete-actions'
import { RequestStatus } from 'common/enums/request-status.enum'

export type AutocompleteState = Record<string, { status: RequestStatus; data: any }>

type AutocompleteReducer = ActionReducer<AutocompleteState>

const getAutocompleteResult: AutocompleteReducer = (state, action) => {
    state[action.name] = { ...state[action.name], status: RequestStatus.Loading }

    return state
}

const getAutocompleteResultSuccess: AutocompleteReducer = (state, action) => {
    state[action.name] = { data: action.response, status: RequestStatus.Success }

    return state
}

const reducers: Reducers<AutocompleteState> = {
    [ACTION_AUTOCOMPLETE_GET]: getAutocompleteResult,
    [ACTION_AUTOCOMPLETE_GET_SUCCESS]: getAutocompleteResultSuccess,
}

export const autocompleteReducer = makeReducer<AutocompleteState>(reducers, {})
