import React from 'react'
import {
    TEXT_APPLICANT,
    TEXT_CONSTRUCTION_SITE,
    TEXT_CHIMNEY,
    TEXT_DOCUMENTATION,
} from 'translations/keys'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'
import { useDispatch } from 'react-redux'
import { startSubmit } from 'redux-form'
import { convertObjectToFormData } from 'utils'
import { actionFireSubmitForm } from 'fire/fire-actions'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { ChimneyInstallationForm1 } from './chimney-installation-form-1'
import { ChimneyInstallationForm2 } from './chimney-installation-form-2'
import { ChimneyInstallationForm3 } from './chimney-installation-form-3'
import { ChimneyInstallationForm4 } from './chimney-installation-form-4'

export const ChimneyInstallation: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    const handleSubmit = (formValue: any) => {

        const formData = convertObjectToFormData(formValue, {})

        dispatch(startSubmit(formDescriptor.name))

        dispatch(actionFireSubmitForm(formDescriptor.name, formData))
    }

    const newChimneySteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_APPLICANT,
            component: <ChimneyInstallationForm1 initialValues={{ type: formDescriptor.type }} />,
        },
        {
            stepLabelTranslationKey: TEXT_CONSTRUCTION_SITE,
            component: <ChimneyInstallationForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_CHIMNEY,
            component: <ChimneyInstallationForm3 onSubmit={handleSubmit} />,
        },
        {
            stepLabelTranslationKey: TEXT_DOCUMENTATION,
            component: <ChimneyInstallationForm4 onSubmit={handleSubmit} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={newChimneySteps} />
    )
}
