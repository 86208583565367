import React, {useEffect} from 'react'
import {reduxForm, formValueSelector, InjectedFormProps} from 'redux-form'
import {tr} from 'translations/translate'
import {
    TEXT_WHAT_IS_THE_ADDRESS,
    TEXT_TANK_EXEMPTION_1,
    TEXT_TANK_EXEMPTION_REASON,
    TEXT_DESCRIBE_REASON_FOR_APPLICATION,
    TEXT_TANK_EXEMPTION_2,
    TEXT_NO_REGISTERED_TANKS,
} from 'translations/keys'
import {Grid} from '@material-ui/core'
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from 'app/app-store'
import {filter, find} from 'lodash'
import {HousingUnit} from 'tax/types/housing-unit.type'
import {HousingUnitSelect} from 'fire/form-components/housing-unit-select'
import {FormFieldName} from '../form-field-name.enum'
import {TankRemovalExemptionFormFieldName} from './tank-removal-exemption-form-field-name.enum'
import {HousingUnitTankSelect} from 'fire/form-components/housing-unit-tank-select'
import {CubitFileUpload} from 'common/cubit-file-upload/cubit-file-upload'
import {CubitTextarea} from 'common/cubit-textarea/cubit-textarea'
import {FireFormName} from 'fire/enums/form-name.enum'
import {actionFireGetCadastreUnit, actionFireGetCadastreUnitById} from 'fire/fire-actions'
import {useFireCadastreUnit} from 'fire/fire-selectors'
import {OwnerSelect} from '../../form-components/owner-select'
import {FireplaceInstallationFormFieldName} from '../fireplace-installation/fireplace-installation-form-field-name.enum'
import {IS_APPLICANT_OWNER, IsApplicantOwnerSelect} from "../../form-components/applicant-select";
import {CompanyContactSelect} from 'fire/form-components/company-contact-select'
import {CaseNumberSelect} from "../../form-components/case-number-select";
import {TankStatus} from "../../enums/tank-status.enum";
import {HousingUnitAutocomplete} from "../../form-components/housing-unit-autocomplete";
import {FireHydrantFormFieldName} from "../fire-hydrant-control/fire-hydrant-form-field-name.enum";
import {HousingUnitAutocompleteObject} from "../../types/housing-unit-autocomplete-object.type";
import {CadastreUnit} from "../../types/cadastre-unit.type";

const Form: React.FC<InjectedFormProps> = props => {
    const {handleSubmit} = props
    const dispatch = useDispatch()
    const [cadastreByAddress, setCadastreByAddress] = React.useState<CadastreUnit | undefined>( undefined)
    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.TankRemovalExemption)
    const ownerId = selector(state, TankRemovalExemptionFormFieldName.OwnerId)
    const isApplicantOwner = selector(state, IS_APPLICANT_OWNER)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])


    let cadastreUnit = useFireCadastreUnit()
    const housingUnits = cadastreUnit?.housingUnits ?? []
    const tanks = cadastreUnit?.tanks ?? []
    const activeTanks = tanks ? filter(tanks, t => t.status !== TankStatus.ApprovedForRemediation && t.status !== TankStatus.Sanitized && t.status !== TankStatus.RemovedExcavated) : []
    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, {id: selectedHousingUnitId})
    const handleHousingUnitChange = (value: HousingUnitAutocompleteObject) => {
        if(value) {
            dispatch(actionFireGetCadastreUnitById(value.housingUnit.matrikkelUnitId))
        } else {

        }
    }
    return (
        <form onSubmit={handleSubmit}>

            <CaseNumberSelect/>
            <IsApplicantOwnerSelect/>
            {isApplicantOwner === false && <CompanyContactSelect/>}

            {isApplicantOwner === true && <OwnerSelect
                showSearch={isApplicantOwner === false}
                name={FireplaceInstallationFormFieldName.OwnerId}
                formName={FireFormName.TankRemovalExemption}
            /> }

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                    { isApplicantOwner === true &&  <HousingUnitSelect
                        housingUnits={housingUnits}
                        disabled={!cadastreUnit || !housingUnits.length || !tanks.length}
                        label={tr(TEXT_WHAT_IS_THE_ADDRESS)}
                    /> }
                    { isApplicantOwner === false && <HousingUnitAutocomplete
                        name={FormFieldName.HousingUnitId}
                        valueChanged={value => {
                            handleHousingUnitChange(value)
                        }}
                        valueSetter={value => value.housingUnit.id}
                    />}
                </Grid>

                {selectedHousingUnit && activeTanks.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <HousingUnitTankSelect housingUnit={selectedHousingUnit}
                                                   name={TankRemovalExemptionFormFieldName.TankId} tanks={activeTanks}/>
                        </Grid>

                        <Grid item xs={12}>
                            {tr(TEXT_TANK_EXEMPTION_1)}
                        </Grid>

                        <Grid item xs={12}>
                            <CubitTextarea
                                name={TankRemovalExemptionFormFieldName.ExemptionReason}
                                label={tr(TEXT_TANK_EXEMPTION_REASON)}
                                placeholder={tr(TEXT_DESCRIBE_REASON_FOR_APPLICATION)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {tr(TEXT_TANK_EXEMPTION_2)}
                        </Grid>

                        <Grid item xs={12}>
                            <CubitFileUpload name={TankRemovalExemptionFormFieldName.ExemptionDocumentation}/>
                        </Grid>
                    </>
                )}
            </Grid>
            {selectedHousingUnit && (!!housingUnits.length || !activeTanks?.length) && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {tr(TEXT_NO_REGISTERED_TANKS)}
                    </Grid>
                </Grid>
            )}
        </form>
    )
}

export const TankRemovalExemptionForm = reduxForm({
    form: FireFormName.TankRemovalExemption,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
