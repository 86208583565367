import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './service-worker'
import { App } from 'app/app'
import { Provider } from 'react-redux'
import { createAppStore } from 'app/app-store'

const store = createAppStore()

const render = (AppComponent: React.ComponentType) => {
    return ReactDOM.render(
        <Provider store={store}>
            <AppComponent />
        </Provider>,
        document.getElementById('root'),
    )
}

render(App)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if ((module as any).hot) {
    (module as any).hot.accept('./app/app', () => {
        const NextApp = require('./app/app').default
        render(NextApp)
    })
}
