import React from 'react'
import { InjectedFormProps, reduxForm, FieldArray } from 'redux-form'
import { TaxationPropertyObject } from 'tax/types/taxation-property-object.type'
import { ComplaintsPageFormCausesFieldArray } from './complaints-page-form-causes-field-array'
import { Complaint } from 'tax/types/complaint.type'

export type ComplaintsPageFormProps = {
    propertyObject: TaxationPropertyObject
}

const Form: React.FC<InjectedFormProps<Complaint, ComplaintsPageFormProps> & ComplaintsPageFormProps> = props => {
    const { handleSubmit, initialValues, propertyObject } = props

    return (
        <form onSubmit={handleSubmit}>
            <FieldArray
                name="causes"
                propertyObject={propertyObject}
                initialValues={initialValues}
                component={ComplaintsPageFormCausesFieldArray}
            ></FieldArray>
        </form>
    )
}

export const ComplaintsPageFormName = 'ComplaintsPageForm'

export const ComplaintsPageForm = reduxForm<Complaint, ComplaintsPageFormProps>({
    form: ComplaintsPageFormName,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(Form)
