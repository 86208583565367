import {FormDescriptor} from 'fire/types/form-descriptor.type'
import {FireFormName} from 'fire/enums/form-name.enum'
import {FireplaceInstallation} from './fireplace-installation/fireplace-installation'
import {FireplaceRemoval} from './fireplace-removal/fireplace-removal'
import {EventNotification} from './event-notification/event-notification'
import {AccommodationNotification} from './accommodation-notification/accommodation-notification'
import {TankRemediation} from './tank-remediation/tank-remediation'
import {TankRemovalExemption} from './tank-removal-exemption/tank-removal-exemption'
import {TankRemoval} from './tank-removal/tank-removal'
import {FireHydrantControl} from './fire-hydrant-control/fire-hydrant-control'
import {ScrapValue} from './scrap-value/scrap-value'
import {FireFormSubmitSuccess} from './fire-form-submit-success/fire-form-submit-success'
import {ConcernCase} from './concern-case/concern-case'
import React from 'react'
import {tr} from 'translations/translate'
import {
    TEXT_ACCOMMODATION_SUCCESS_1,
    TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT,
    TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS,
    TEXT_APPLICATION_FOR_SCRAP_VALUE,
    TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER,
    TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION,
    TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS,
    TEXT_APPLICATION_RECEIVED,
    TEXT_CONCERN_CASE,
    TEXT_FIREPLACE_INSTALL_SUCCESS_1,
    TEXT_FIREPLACE_INSTALLATION_NOTICE,
    TEXT_FIREPLACE_REMOVAL_NOTICE,
    TEXT_FIREPLACE_REMOVED,
    TEXT_MESSAGE_RECEIVED,
    TEXT_NOTICE_OF_ACCOMMODATION,
    TEXT_NOTICE_OF_EVENT,
    TEXT_NOTICE_OF_REHABILITATION_NEW_CHIMNEY,
    TEXT_NOTICE_OF_TANK_REMOVAL,
    TEXT_NOTIFICATION_OF_TANK_REMEDIATION,
    TEXT_SUCCESS_1,
    TEXT_SUCCESS_2,
    TEXT_WE_WILL_CONTACT_YOU,
} from 'translations/keys'
import {FireRoute} from 'app/enums/fire-route.enum'
import {ChimneyInstallation} from './chimney-installation/chimney-installation'
import {EventNotificationFormSubmitSuccess} from './event-notification/event-notification-form-submit-success'
import {PyrotechnicsUse} from './pyrotechnics-use/pyrotechnics-use'
import {PyrotechnicsSale} from './pyrotechnics-sale/pyrotechnics-sale'
import {SmokelessGunpowderStore} from './smokeless-gunpowder-store/smokeless-gunpowder-store'
import {auditProductionUrl} from 'common/services/http-service'

export const FireFormDescriptorsMap: Record<FireFormName, FormDescriptor> = {
    [FireFormName.FireplaceInstallation]: {
        name: FireFormName.FireplaceInstallation,
        type: 'FIREPLACE_INSTALLATION',
        labelTranslationKey: TEXT_FIREPLACE_INSTALLATION_NOTICE,
        submitSuccessComponent: (
            <FireFormSubmitSuccess
                title={tr(TEXT_MESSAGE_RECEIVED)}
                content={[tr(TEXT_FIREPLACE_INSTALL_SUCCESS_1), tr(TEXT_SUCCESS_2)]}
            />
        ),
        submitEndpoint: '/newFireplace',
        route: '/fire/fireplace-installation',
        successRoute: FireRoute.ThankYou,
        component: function () {
            return <FireplaceInstallation formDescriptor={this}/>
        },
    },
    [FireFormName.FireplaceRemoval]: {
        name: FireFormName.FireplaceRemoval,
        type: 'FIREPLACE_REMOVAL',
        labelTranslationKey: TEXT_FIREPLACE_REMOVAL_NOTICE,
        component: function () {
            return <FireplaceRemoval formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess title={tr(TEXT_FIREPLACE_REMOVED)}/>,
        submitEndpoint: '/removeFireplace',
        route: '/fire/fireplace-removal',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.ScrapValue]: {
        name: FireFormName.ScrapValue,
        type: 'SCRAP_VALUE_APPLICATION',
        labelTranslationKey: TEXT_APPLICATION_FOR_SCRAP_VALUE,
        component: function () {
            return <ScrapValue formDescriptor={this}/>
        },
        submitSuccessComponent: (
            <FireFormSubmitSuccess
                title={tr(TEXT_APPLICATION_RECEIVED)}
                content={[tr(TEXT_SUCCESS_1), tr(TEXT_SUCCESS_2)]}
            />
        ),
        submitEndpoint: '/scrapValue',
        route: '/fire/scrap-value-application',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.ChimneyInstallation]: {
        name: FireFormName.ChimneyInstallation,
        type: 'CHIMNEY_INSTALLATION',
        labelTranslationKey: TEXT_NOTICE_OF_REHABILITATION_NEW_CHIMNEY,
        component: function () {
            return <ChimneyInstallation formDescriptor={this}/>
        },
        submitSuccessComponent: (
            <FireFormSubmitSuccess
                title={tr(TEXT_MESSAGE_RECEIVED)}
                content={[tr(TEXT_FIREPLACE_INSTALL_SUCCESS_1), tr(TEXT_SUCCESS_2)]}
            />
        ),
        submitEndpoint: '/new-chimney',
        route: '/fire/chimney-installation',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.TankRemediation]: {
        name: FireFormName.TankRemediation,
        type: 'TANK_REMEDIATION',
        labelTranslationKey: TEXT_NOTIFICATION_OF_TANK_REMEDIATION,
        component: function () {
            return <TankRemediation formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/tankRemediation',
        route: '/fire/tank-remediation',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.TankRemovalExemption]: {
        name: FireFormName.TankRemovalExemption,
        type: 'TANK_REMOVAL_EXEMPTION',
        labelTranslationKey: TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION,
        component: function () {
            return <TankRemovalExemption formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/tankRemovalExemption',
        route: '/fire/tank-removal-exemption',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.TankRemoval]: {
        name: FireFormName.TankRemoval,
        type: 'TANK_REMOVAL',
        labelTranslationKey: TEXT_NOTICE_OF_TANK_REMOVAL,
        component: function () {
            return <TankRemoval formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/tankRemoval',
        route: '/fire/tank-removal',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.AccommodationNotification]: {
        name: FireFormName.AccommodationNotification,
        type: 'ACCOMMODATION_NOTIFICATION',
        labelTranslationKey: TEXT_NOTICE_OF_ACCOMMODATION,
        component: function () {
            return <AccommodationNotification formDescriptor={this}/>
        },
        submitSuccessComponent: (
            <FireFormSubmitSuccess title={tr(TEXT_MESSAGE_RECEIVED)} content={[tr(TEXT_ACCOMMODATION_SUCCESS_1)]}/>
        ),
        submitEndpoint: '/accommodationNotification',
        route: '/fire/accommodation-notice',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.EventNotification]: {
        name: FireFormName.EventNotification,
        type: 'EVENT_NOTIFICATION',
        labelTranslationKey: TEXT_NOTICE_OF_EVENT,
        component: function () {
            return <EventNotification formDescriptor={this}/>
        },
        submitSuccessComponent: <EventNotificationFormSubmitSuccess/>,
        submitEndpoint: '/eventNotification',
        route: '/fire/event-notice',
        successRoute: `${FireRoute.ThankYou}/:eventPyrotechnics`,
    },
    [FireFormName.PyrotechnicsUse]: {
        name: FireFormName.PyrotechnicsUse,
        type: 'PYROTECHNICS_USE',
        labelTranslationKey: TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS,
        component: function () {
            return <PyrotechnicsUse formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/pyrotechnicsUse',
        route: '/fire/pyrotechnics-use',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.PyrotechnicsSale]: {
        name: FireFormName.PyrotechnicsSale,
        type: 'PYROTECHNICS_SALE',
        labelTranslationKey: TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS,
        component: function () {
            return <PyrotechnicsSale formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/pyrotechnicsSale',
        route: '/fire/pyrotechnics-sale',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.SmokelessGunpowderStore]: {
        name: FireFormName.SmokelessGunpowderStore,
        type: 'SMOKELESS_GUNPOWDER_STORE',
        labelTranslationKey: TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER,
        component: function () {
            return <SmokelessGunpowderStore formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess/>,
        submitEndpoint: '/smokelessGunpowderStore',
        route: '/fire/smokeless-gunpowder-store',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.FireHydrantControl]: {
        name: FireFormName.FireHydrantControl,
        type: 'FIRE_HYDRANT_CONTROL',
        labelTranslationKey: TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT,
        component: function () {
            return <FireHydrantControl formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess content={[tr(TEXT_WE_WILL_CONTACT_YOU)]}/>,
        submitEndpoint: '/fireHydrantControl',
        route: '/fire/fire-hydrant-control',
        successRoute: FireRoute.ThankYou,
    },
    [FireFormName.ConcernCase]: {
        name: FireFormName.ConcernCase,
        type: 'FIRE_CONCERN_CASE',
        labelTranslationKey: TEXT_CONCERN_CASE,
        component: function () {
            return <ConcernCase formDescriptor={this}/>
        },
        submitSuccessComponent: <FireFormSubmitSuccess
        title={tr(TEXT_MESSAGE_RECEIVED)}
        content={[]}
        />,
        submitEndpoint: '/createConcernCase',
        route: '/fire/concern-case',
        successRoute: FireRoute.ThankYou,
    },
}

export const getFireFormDescriptorsArray = () =>
    Object.keys(FireFormDescriptorsMap).map(key => FireFormDescriptorsMap[key as FireFormName])

export const getFormSubmitUrl = (formName: FireFormName, tenantId: any): string =>
    auditProductionUrl(`/form${FireFormDescriptorsMap[formName].submitEndpoint}/${tenantId}`)
