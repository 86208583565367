import { AnyAction } from 'redux'

export const ACTION_SHOW_ERROR = 'ACTION_SHOW_ERROR'
export const ACTION_GO_BACK = 'ACTION_GO_BACK'
export const ACTION_STORE_SESSION_KEY = 'ACTION_STORE_SESSION_KEY'
export const ACTION_STORE_TENANT_ID = 'ACTION_STORE_TENANT_ID'
export const ACTION_SET_STATE_TENANT_ID = 'ACTION_SET_STATE_TENANT_ID'
export const ACTION_LOAD_ENABLED_TENANTS = 'ACTION_LOAD_ENABLED_TENANTS'
export const ACTION_STORE_TENANT_DATA = 'ACTION_STORE_TENANT_DATA'
export const ACTION_STORE_ENABLED_TENANTS = 'ACTION_STORE_ENABLED_TENANTS'
export const ACTION_STORE_ACCESS_TOKEN = 'ACTION_STORE_ACCESS_TOKEN'
export const ACTION_REFRESH_ACCESS_TOKEN = 'ACTION_REFRESH_ACCESS_TOKEN'
export const ACTION_SET_TENANT_SELECTOR = 'ACTION_SET_TENANT_SELECTOR'
export const ACTION_POPUP = 'ACTION_POPUP'

export const actionShowError = (message?: string): AnyAction => ({
    type: ACTION_SHOW_ERROR,
    message,
})
export const actionSetTenantSelectorVisible = (payload: any): AnyAction => ({
    type: ACTION_SET_TENANT_SELECTOR,
    payload,
})
export const actionGoBack = (): AnyAction => ({
    type: ACTION_GO_BACK,
})
export const actionLoadEnabledTenants = ( ): AnyAction => ({
    type: ACTION_LOAD_ENABLED_TENANTS
})
export const actionStoreSessionKey = (sessionKey: string | undefined): AnyAction => ({
    type: ACTION_STORE_SESSION_KEY,
    sessionKey,
})
export const actionSetStateTenantId = (tenantId: string | undefined): AnyAction => ({
    type: ACTION_SET_STATE_TENANT_ID,
    tenantId,
})
export const actionStoreTenantId = (tenantId: string | undefined): AnyAction => ({
    type: ACTION_STORE_TENANT_ID,
    tenantId,
})
export const actionStoreTenantData = (payload: any): AnyAction => ({
    type: ACTION_STORE_TENANT_DATA,
    payload,
})
export const actionStoreEnabledTenants = (payload: any): AnyAction => ({
    type: ACTION_STORE_ENABLED_TENANTS,
    payload,
})
export const actionStoreAccessToken = (token: string | undefined): AnyAction => ({
    type: ACTION_STORE_ACCESS_TOKEN,
    token,
})

export const actionRefreshAccessToken = (): AnyAction => ({
    type: ACTION_REFRESH_ACCESS_TOKEN,
})

export const actionPopup = (payload: any): AnyAction => ({
    type: ACTION_POPUP,
    payload,
})
