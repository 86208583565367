import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { appEpics } from './app-epics'
import { reducer as formReducer, FormStateMap } from 'redux-form'
import { AppDataState, appReducer } from './app-reducer'
import { ComplaintsState, complaintsReducer } from 'tax/complaints/complaints-reducer'
import { fireReducer, FireState } from 'fire/fire-reducer'
import { AutocompleteState, autocompleteReducer } from 'common/cubit-autocomplete/autocomplete-reducer'
import {otherReducer, OtherState} from "../other/other-reducer";

export type AppState = {
    appData: AppDataState
    form: FormStateMap
    complaints: ComplaintsState
    fire: FireState
    other: OtherState
    autocomplete: AutocompleteState
}

const rootReducer = combineReducers<AppState>({
    appData: appReducer,
    form: formReducer,
    complaints: complaintsReducer,
    fire: fireReducer,
    other: otherReducer,
    autocomplete: autocompleteReducer,
})

const initialState = {}

export const createAppStore = () => {
    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const epicMiddleware = createEpicMiddleware()
    // @ts-ignore
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(epicMiddleware)))
    epicMiddleware.run(appEpics)
    return store
}
