export enum PropertyValueType {
    /** Residential property */
    BO = 'BO',
    /** Leisure Property */
    FR = 'FR',
    /** Farming / forestry with housing */
    GB = 'GB',
    /** Farm use / forestry with leisure. */
    GF = 'GF',
    /** Farms or forestry */
    GU = 'GU',
    /** Not selected */
    IV = 'IV',
    /** Combined built plot */
    KO = 'KO',
    /** Commercial real estate */
    NE = 'NE',
    /** Undeveloped plot, housing */
    UB = 'UB',
    /** Undeveloped plot, leisure */
    UF = 'UF',
    /** Undeveloped plot, industry */
    UN = 'UN',
    /** Undeveloped plot */
    UT = 'UT',
}
