import React from 'react'
import { Typography, makeStyles, Theme } from '@material-ui/core'
import { TypographyProps } from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontWeight: 500,
        color: theme.palette.common.black,
        padding: theme.spacing(2.5, 0, 2, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0, 1.5, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5, 0, 1, 0),
        },

        '&:nth-of-type(1)': {
            paddingTop: 0,
        },
        fontSize: '1.2em',
    },
}))

export const ContentSectionHeading: React.FC<TypographyProps> = props => {
    const { variant = 'h2' } = props
    const styles = useStyles()
    return (
        <Typography variant={variant} className={styles.heading}>
            {props.children}
        </Typography>
    )
}
