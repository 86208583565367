import React from 'react'
import { tr } from 'translations/translate'
import {
    TEXT_REQUIRED,
    TEXT_RESPONSIBLE,
    TEXT_PLACE_OF_SALE,
    TEXT_HMS_TRAINING,
} from 'translations/keys'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'
import { useDispatch } from 'react-redux'
import { startSubmit } from 'redux-form'
import { convertObjectToFormData } from 'utils'
import { actionFireSubmitForm } from 'fire/fire-actions'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { PyrotechnicsSaleForm1 } from './pyrotechnics-sale-form-1'
import { PyrotechnicsSaleForm2 } from './pyrotechnics-sale-form-2'
import { PyrotechnicsSaleForm3 } from './pyrotechnics-sale-form-3'
import { PyrotechnicsSaleFormFieldName } from './pyrotechnics-sale-form-field-name.enum'

const validate = (values: any) => {
    const errors: any = {}

    const checklist = values?.[PyrotechnicsSaleFormFieldName.ConfirmationChecklist]
    const notAllChecklistItemsSelected = 8 !== checklist?.length

    if (!checklist || notAllChecklistItemsSelected) {
        errors[PyrotechnicsSaleFormFieldName.ConfirmationChecklist] = tr(TEXT_REQUIRED)
    }
    return errors
}

export const PyrotechnicsSale: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        const formData = convertObjectToFormData(formValue, {})
        console.warn({ formData })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formData))
    }

    const pyrotechnicsSaleSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_RESPONSIBLE,
            component: <PyrotechnicsSaleForm1 initialValues={{ type: formDescriptor.type }} validate={validate} />,
        },
        {
            stepLabelTranslationKey: TEXT_PLACE_OF_SALE,
            component: <PyrotechnicsSaleForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_HMS_TRAINING,
            component: <PyrotechnicsSaleForm3 onSubmit={handleSubmit} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={pyrotechnicsSaleSteps}
        />
    )
}
