import React from 'react'
import {
  reduxForm,
  Field,
  formValueSelector,
  InjectedFormProps,
} from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'

import { AppState } from 'app/app-store'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'

import { tr } from 'translations/translate'
import * as k from 'translations/keys'

import { ConcernCaseFormFieldName as FieldName } from './form-field-name.enum'
import { FireFormName } from 'fire/enums/form-name.enum'
import {
  CubitTextFieldAdapter,
  CubitTextarea,
  CubitYesNoRadio,
  CubitGeosuggestAdapter,
  CubitFileUpload,
  ContentSectionHeading,
  ContentSectionContainer,
} from 'common'
import { ValidatorRequired } from 'utils/validators/validator-required'

import { Grid } from '@material-ui/core'
import { OtherFormName } from '../../enums/form-name.enum'

const Row = ({ children }: any) => {
  return (
      <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
              {children}
          </Grid>
      </Grid>
  )
}

const TextField = (props: any) => {
  return (
      <Row>
          <Field
              name={props.name}
              label={props.label}
              placeholder={props.placeholder || ''}
              component={CubitTextFieldAdapter}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="filled"
          />
      </Row>
  )
}

const Form: React.FC<InjectedFormProps> = props => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ConcernCase)

    const isAnonymous = selector(state, FieldName.IsAnonymous)

    return (
        <form onSubmit={handleSubmit}>

            <CubitYesNoRadio
                name={FieldName.IsAnonymous}
                label={tr(k.TEXT_SEND_AS_ANONYMOUS)}
                validate={ValidatorRequired}
            />

            { isAnonymous === false &&
              <>
            <TextField
                name={FieldName.SenderName}
                label={tr(k.TEXT_SENDER)}
                placeholder={tr(k.TEXT_SENDER)}
            />
            <TextField
                name={FieldName.SenderEmail}
                label={tr(k.TEXT_EMAIL)}
                placeholder={tr(k.TEXT_EMAIL)}
            />
            <TextField
                name={FieldName.SenderPhone}
                label={tr(k.TEXT_CONTACT_PHONE)}
                placeholder={tr(k.TEXT_CONTACT_PHONE)}
            />
              </>
            }

            <Row>
                <Field
                    name={FieldName.Address}
                    label={tr(k.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
                    component={CubitGeosuggestAdapter}
                    validate={ValidatorRequired}
                    required
                />
            </Row>

            <Row>
                <CubitTextarea
                    name={FieldName.Description}
                    label={tr(k.TEXT_CONCERN_DESCRIPTION)}
                    required
                />
            </Row>

            <ContentSectionHeading variant="subtitle1">
                {tr(k.TEXT_FILES)}
            </ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={FieldName.Files} />
            </ContentSectionContainer>


        </form>
    )
}

export const ConcernCaseForm = reduxForm({
    form: OtherFormName.CreateConcern,
    enableReinitialize: false,
    destroyOnUnmount: false
})(Form)
