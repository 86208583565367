export enum PyrotechnicsSaleFormFieldName {
    // form 1
    ContactName = 'contactName',
    ContactPhone = 'contactPhone',
    ContactEmail = 'contactEmail',
    SomeoneResponsibleForReport = 'someoneResponsibleForReport',
    ResponsibleReporterName = 'responsibleReporterName',
    ResponsibleReporterEmail = 'responsibleReporterEmail',
    ResponsibleReporterMobile = 'responsibleReporterMobile',
    OrganizationNumber = 'organizationNumber',
    ResponsiblePersonConfirm = 'responsiblePersonConfirm',

    // form 2
    SalePlace = 'salePlace',
    ReleaseLocationSketchFiles = 'releaseLocationSketchFiles',
    ContainerLocationSketchFiles = 'containerPlaceSketchFiles',
    CompanyTestRegistryFiles = 'companyTestRegistryFiles',
    StoragePlace = 'storagePlace',

    // form 3
    ConfirmationChecklist = 'confirmationChecklist',
    LastInternalControlDate = 'lastInternalControlDate',
}
