import React from 'react'
import { useSelector } from 'react-redux'

import { AppState } from './app-store'

const Footer = () => {

    const tenantData = useSelector((state: AppState) => state.appData.tenantData)

    const { formsDesignDeclarationUrl } = tenantData || {}

    if (!formsDesignDeclarationUrl)
        return null

    return (
        <div style={{ textAlign: 'center' }}>
          <a style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none'}} href={formsDesignDeclarationUrl}>Tilgjengelighetserklæring for nettsted</a>
        </div>
    )
}

export default Footer
