import {ActionReducer} from 'app/types/action-reducer.type'
import {Reducers} from 'app/types/reducers.type'
import {makeReducer} from 'utils'
import {ACTION_OTHER_POSTAL_AUDIT_LOADED, ACTION_PROJECT_LOADED} from "./other-actions";
import {Audit} from "../common/audit/audit";

export type OtherState = {
  postalAudit?: Audit,
  project?: any,
}
type OtherReducer = ActionReducer<OtherState>

const setPostalAudit: OtherReducer = (state, action) => {
    return {...state, postalAudit:action.audit}
}
const setProject: OtherReducer = (state, action) => {
    return {...state, project:action.project}
}

const reducers: Reducers<OtherState> = {
    [ACTION_OTHER_POSTAL_AUDIT_LOADED]: setPostalAudit,
    [ACTION_PROJECT_LOADED]: setProject,

}

export const otherReducer = makeReducer<OtherState>(reducers, {})
