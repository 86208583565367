import React from 'react'
import { Typography, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        color: theme.palette.common.black,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5, 2),
        },
        fontSize: '1.7142857142857142rem',
        fontWeight: 400,
    },
}))

export const ContentHeading: React.FC = props => {
    const styles = useStyles()
    return (
        <Typography variant="h1" className={styles.heading}>
            {props.children}
        </Typography>
    )
}
