import React from 'react'
import LayoutDefault from 'common/layout-default/layout-default'
import { ContentContainer } from 'common/content-container/content-container'
import { ContentLink } from 'common/content-link/content-link'
import { tr } from 'translations/translate'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import { getFireFormDescriptorsArray } from 'fire/forms/fire-form-descriptors-map'
import {FireSignOut} from "../fire-sign-out/fire-sign-out";

const fireFormDescriptorsArray = getFireFormDescriptorsArray()

export const FireFormsListPage: React.FC = () => {
    return (
        <LayoutDefault logo={<FireLogo />} rightElement={<FireSignOut />}>
            {fireFormDescriptorsArray.map((d, i) => (
                <ContentContainer key={i}>
                    <ContentLink to={d.route}>{tr(d.labelTranslationKey)}</ContentLink>
                </ContentContainer>
            ))}
        </LayoutDefault>
    )
}
