import React from 'react'
import { reduxForm, Field, InjectedFormProps, formValueSelector, change } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF,
    TEXT_YES,
    TEXT_NEW_FIREPLACE_INSTALLATION_2,
    TEXT_NAME,
    TEXT_BUSINESS,
    TEXT_ADDRESS,
    TEXT_ZIP_CODE,
} from 'translations/keys'
import { Grid } from '@material-ui/core'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { FireplaceInstallationFormFieldName } from './fireplace-installation-form-field-name.enum'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { FireFormName } from 'fire/enums/form-name.enum'

const Form: React.FC<InjectedFormProps> = props => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.FireplaceInstallation)
    const notSelfInstalled = selector(state, FireplaceInstallationFormFieldName.SelfInstalled) === false

    const handleSelfInstalledChange = (selfInstalled: boolean) => {
        if (selfInstalled) {
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.InstallerNameOrCompanyName,
                    undefined,
                ),
            )
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.InstallerAddress,
                    undefined,
                ),
            )
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.InstallerZipCode,
                    undefined,
                ),
            )
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12}>
                    <CubitYesNoRadio
                        name={FireplaceInstallationFormFieldName.SelfInstalled}
                        label={tr(TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF)}
                        onChange={handleSelfInstalledChange}
                        validate={ValidatorRequired}
                        autoFocus
                    />
                </Grid>

                {notSelfInstalled && (
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12}>
                                <Field
                                    name={FireplaceInstallationFormFieldName.InstallerNameOrCompanyName}
                                    label={`${tr(TEXT_NAME)}/ ${tr(TEXT_BUSINESS).toLowerCase()}`}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={FireplaceInstallationFormFieldName.InstallerAddress}
                                    label={tr(TEXT_ADDRESS)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={FireplaceInstallationFormFieldName.InstallerZipCode}
                                    label={tr(TEXT_ZIP_CODE)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Field
                        name={FireplaceInstallationFormFieldName.RegulationsMet}
                        label={tr(TEXT_NEW_FIREPLACE_INSTALLATION_2)}
                        component={CubitRadioGroupAdapter}
                        options={[
                            {
                                label: tr(TEXT_YES),
                                value: 'true',
                            },
                        ]}
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const FireplaceInstallationForm3 = reduxForm({
    form: FireFormName.FireplaceInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
