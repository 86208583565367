export enum FireplaceInstallationFormFieldName {
    // form 1
    OwnerId = 'ownerId',
    AssociatedChimneyId = 'associatedChimneyId',
    OtherChimney = 'otherChimney',
    OtherChimneyDescription = 'otherChimneyDescription',
    RemovedFireplacesIds = 'removedFireplacesIds',

    // form 2
    ProductName = 'productName',
    FuelType = 'fuelType',
    InstallationDate = 'installationDate',

    // form 3

    SelfInstalled = 'selfInstalled',
    InstallerNameOrCompanyName = 'installerNameOrCompanyName',
    InstallerAddress = 'installerAddress',
    InstallerZipCode = 'installerZipCode',
    RegulationsMet = 'regulationsMet',
}
