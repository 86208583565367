import { Owner, OwnerType } from 'tax/types/owner.type'

export default (owner: Owner) => {
    switch (owner.type) {
        case OwnerType.Private:
            return `${owner.firstName} ${owner.lastName}`
        case OwnerType.Company:
            return `${owner.name}`
        default:
            return '-'
    }
}
