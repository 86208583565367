import React from 'react'
import { Field } from 'redux-form'
import { DropzoneAdapter } from './dropzone-adapter'

const ACCEPTED_FILES = 'image/*,.pdf,.docx,.xls,.xlsx'

export const CubitFileUpload: React.FC<{ name: string; buttonTextKey?: string, validate?: any, autoFocus?: boolean }> = props => {
    const { name, buttonTextKey, validate = undefined, autoFocus } = props

    return (
        <Field name={name} component={DropzoneAdapter} acceptedFiles={ACCEPTED_FILES} buttonTextKey={buttonTextKey} validate={validate} autoFocus={autoFocus} />
    )
}
