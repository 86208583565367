import React from 'react'
import {change, Field, formValueSelector, InjectedFormProps, reduxForm} from 'redux-form'
import {FormControlLabel, Grid} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {FireFormName} from 'fire/enums/form-name.enum'
import {ScrapValueFormFieldName} from './scrap-value-form-field-name.enum'
import {CubitRadioGroupAdapter} from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import {ValidatorRequired} from 'utils/validators/validator-required'
import {HeatingSource} from './heating-source.enum'
import {tr} from 'translations/translate'
import {
    TEXT_ASSOCIATED_CHIMNEY,
    TEXT_CHIMNEY_ASSOCIATION_TEXT,
    TEXT_CLEAN_BURNING,
    TEXT_CLEAN_BURNING_FIREPLACE,
    TEXT_DESCRIPTION,
    TEXT_DISTRICT_HEATING,
    TEXT_FIREPLACE_ALREADY_REPROTED,
    TEXT_FUEL_TYPE, TEXT_FUEL_TYPE_SOLID,
    TEXT_HEAT_PUMP,
    TEXT_INSTALLATION_DATE,
    TEXT_INSTALLED_FIREPLACE, TEXT_LIQUID_AND_GAS,
    TEXT_NON_CLEAN_BURNING,
    TEXT_OTHER_CHIMNEY_UNCERTAIN,
    TEXT_PRODUCT_NAME,
    TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE,
    TEXT_SELECT_THE_HEATING_SOURCE,
    TEXT_SOLAR,
    TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH,
    TEXT_WOOD,
} from 'translations/keys'
import {CubitTextFieldAdapter} from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {find} from 'lodash'
import CubitDatePickerAdapter from 'common/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'
import {AppState} from 'app/app-store'
import {CubitCheckboxAdapter} from '../../../common/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import {useFireCadastreUnit} from '../../fire-selectors'
import {getChimneyLabel} from '../fireplace-installation/fireplace-installation-form-1'
import {FormFieldName} from '../form-field-name.enum'
import {HousingUnit} from '../../../tax/types/housing-unit.type'
import {CubitYesNoRadio} from '../../../common/cubit-yes-no-radio/cubit-yes-no-radio'
import {DateTime} from 'luxon'
import {FuelType} from "./fuel-type.enum";

const Form: React.FC<InjectedFormProps<any>> = props => {
    const {handleSubmit} = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ScrapValue)
    const cadastreUnit = useFireCadastreUnit()
    const chimneys = cadastreUnit ? cadastreUnit.chimneys : []
    const housingUnits = (cadastreUnit && cadastreUnit.housingUnits) || []
    const heatingSource = selector(state, ScrapValueFormFieldName.HeatingSource)
    const isOtherChimneySelected = selector(state, ScrapValueFormFieldName.OtherChimney)
    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const isFireplaceAlreadyReported: any = selector(state, ScrapValueFormFieldName.AlreadyReported)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, {id: selectedHousingUnitId})
    const getFireplaceChimneyDescription = (chimneyId: string): string => {
        const chimney = cadastreUnit ? find(cadastreUnit.chimneys, {id: chimneyId}) : undefined
        return chimney ? `${chimney.chimneyNumber} ${chimney.location ? `, ${chimney.location} ` : ''}` : ''
    }
    const selectedHousingUnitFireplaceOptions =
        selectedHousingUnit &&
        selectedHousingUnit.fireplaces.map(f => ({
            label: (
                <div>
                    <div>{`${f.description ? f.description + ', ' : ''}${
                        f.cleanBurning ? tr(TEXT_CLEAN_BURNING) : tr(TEXT_NON_CLEAN_BURNING)
                    }${
                        f.installed
                            ? ', ' +
                            tr(TEXT_INSTALLATION_DATE) +
                            ': ' +
                            DateTime.fromISO(f.installed.toString()).toFormat('dd.MM.yyyy')
                            : ''
                    }`}</div>
                    <div>{`${tr(TEXT_ASSOCIATED_CHIMNEY)} : ${getFireplaceChimneyDescription(f.chimneyId)}`}</div>
                </div>
            ),
            value: f.id,
        }))
    const handleHeatingSourceChange = () => {
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.ProductName, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.FuelType, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.InstallationDate, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.ChimneyId, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.OtherChimneyDescription, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.AlreadyReported, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.OtherChimney, undefined))
        dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.ReportedFireplaceId, undefined))
    }
    const handleOtherChimneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = e.target.checked
        if (selected) {
            dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.ChimneyId, undefined))
        } else {
            dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.OtherChimneyDescription, undefined))
        }
    }

    const selectedHousingUnitChimneyOptions = chimneys.filter(chimney => selectedHousingUnit && selectedHousingUnit.chimneyIds.indexOf(chimney.id) !== -1).map(c => ({
        label: getChimneyLabel(selectedHousingUnit, c),
        value: c.id,
    }))
    const heatingSourceOptions = [
        {
            label: tr(TEXT_CLEAN_BURNING_FIREPLACE),
            value: HeatingSource.CleanBurningFireplace,
        },
        {
            label: tr(TEXT_HEAT_PUMP),
            value: HeatingSource.HeatPump,
        },
        {
            label: tr(TEXT_SOLAR),
            value: HeatingSource.Solar,
        },
        {
            label: tr(TEXT_DISTRICT_HEATING),
            value: HeatingSource.DistrictHeating,
        },
    ]

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12}>
                    <label>{tr(TEXT_SELECT_THE_HEATING_SOURCE)}</label>
                    <Field
                        name={ScrapValueFormFieldName.HeatingSource}
                        component={CubitRadioGroupAdapter}
                        options={heatingSourceOptions}
                        onChange={handleHeatingSourceChange}
                        validate={ValidatorRequired}
                        required
                        autoFocus
                    />
                </Grid>

                {heatingSource === HeatingSource.CleanBurningFireplace && (
                    <>
                        <Grid item xs={12}>
                            <CubitYesNoRadio
                                name={ScrapValueFormFieldName.AlreadyReported}
                                label={tr(TEXT_FIREPLACE_ALREADY_REPROTED)}
                                validate={ValidatorRequired}
                            />
                        </Grid>
                        {isFireplaceAlreadyReported === false && (
                            <>
                                <Grid item xs={12}>
                                    <label>{tr(TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH)}</label>
                                    {!isOtherChimneySelected && (
                                        <Field
                                            name={ScrapValueFormFieldName.ChimneyId}
                                            component={CubitRadioGroupAdapter}
                                            options={selectedHousingUnitChimneyOptions}
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    )}
                                    <div>
                                        <FormControlLabel
                                            label={tr(TEXT_OTHER_CHIMNEY_UNCERTAIN)}
                                            control={
                                                <Field
                                                    name={ScrapValueFormFieldName.OtherChimney}
                                                    component={CubitCheckboxAdapter}
                                                    onChange={handleOtherChimneyChange}
                                                />
                                            }
                                        />
                                    </div>
                                    {isOtherChimneySelected && (
                                        <Field
                                            name={ScrapValueFormFieldName.OtherChimneyDescription}
                                            label={tr(TEXT_DESCRIPTION)}
                                            placeholder={tr(TEXT_CHIMNEY_ASSOCIATION_TEXT)}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Field
                                        name={ScrapValueFormFieldName.ProductName}
                                        label={tr(TEXT_PRODUCT_NAME)}
                                        component={CubitTextFieldAdapter}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        validate={ValidatorRequired}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {tr(TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE)}
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name={ScrapValueFormFieldName.FuelType}
                                        label={`${tr(TEXT_FUEL_TYPE)}`}
                                        component={CubitRadioGroupAdapter}
                                        options={[
                                            {
                                                label: tr(TEXT_WOOD),
                                                value: FuelType.Wood,
                                            },
                                            {
                                                label: 'Pellets',
                                                value: FuelType.Pellets,
                                            },
                                        ]}
                                        validate={ValidatorRequired}
                                    />
                                </Grid>

                                <Grid item>
                                    <Field
                                        name={ScrapValueFormFieldName.InstallationDate}
                                        component={CubitDatePickerAdapter}
                                        label={tr(TEXT_INSTALLATION_DATE)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        validate={ValidatorRequired}
                                    />
                                </Grid>
                            </>
                        )}
                        {isFireplaceAlreadyReported === true && (
                            <Grid item>
                                <label>{tr(TEXT_INSTALLED_FIREPLACE)}</label>

                                <Field
                                    name={ScrapValueFormFieldName.ReportedFireplaceId}
                                    component={CubitRadioGroupAdapter}
                                    options={selectedHousingUnitFireplaceOptions}
                                    validate={ValidatorRequired}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </form>
    )
}

export const ScrapValueForm2 = reduxForm({
    form: FireFormName.ScrapValue,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
