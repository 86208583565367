import { combineEpics } from 'redux-observable'
import { EMPTY, Observable, ObservedValueOf, of, OperatorFunction } from 'rxjs'
import { catchError, flatMap, tap, map, withLatestFrom, filter } from 'rxjs/operators'
import { AnyAction } from 'redux'
import {
    actionShowError,
    ACTION_GO_BACK,
    ACTION_REFRESH_ACCESS_TOKEN,
    actionStoreAccessToken,
    ACTION_STORE_TENANT_ID,
    actionStoreTenantData,
    ACTION_LOAD_ENABLED_TENANTS, actionStoreEnabledTenants
} from './app-actions'
import { complaintsEpic } from 'tax/complaints/complaints-epics'
import { stopSubmit } from 'redux-form'
import { fireEpics } from 'fire/fire-epics'
import { autocompleteEpics } from 'common/cubit-autocomplete/autocomplete-epics'
import {FireRoute} from "./enums/fire-route.enum";
import {otherEpics} from "../other/other-epics";

import {
    httpGet,
    lanternUrl, lanternUrlV2,
} from 'common/services/http-service'


export const defaultCatchError = (
    message?: string,
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError(
        (error): Observable<AnyAction> => {
            return of(actionShowError(message))
        },
    )

export const catchErrorAndStopSubmit = (
    formName: string,
    message?: string,
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError(
        (error): Observable<AnyAction> => {
            return of(actionShowError(message), stopSubmit(formName))
        },
    )
export const catchErrorAndRedirect = (
    message?: string,
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError(
        (error): Observable<AnyAction> => {
            window.location.replace(FireRoute.NotFound);
            return of(actionShowError(message))
        },
    )
export const logEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        withLatestFrom(state$),
        tap(([action, state]) => {
          if (window.location.origin.startsWith('http://localhost'))
            console.log(action.type, { action, state })
        }),
        flatMap(() => EMPTY),
    )

export const goBackEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_GO_BACK),
        flatMap(() => {
            window.history.back()

            return EMPTY
        }),
    )

const refreshAccessTokenError = (
    message?: string,
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError(
        (error): Observable<AnyAction> => {
            return of (actionStoreAccessToken(undefined))
        },
    )

export const refreshAccessToken = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter(x => x.type === ACTION_REFRESH_ACCESS_TOKEN),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {

                const { accessToken } = state.appData
                if (!accessToken) return EMPTY

                const url = lanternUrl(
                  `/openid/refreshAccessToken?accessToken=${accessToken}`
                )
                return httpGet(url).pipe(
                    map((result: any) => {
                        return actionStoreAccessToken(result.accessToken)
                    }),
                    refreshAccessTokenError(),
                )}
          ,
        ),
    )

export const loadTenantData = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter(x => x.type === ACTION_STORE_TENANT_ID),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {

                const { tenantId } = action
                if (!tenantId) return EMPTY
                const url = lanternUrl(
                  `/tenant/public-data/${tenantId}`
                )
                return httpGet(url).pipe(
                    map((result: any) => {
                        return actionStoreTenantData(result)
                    }),
                    defaultCatchError(),
                )}
          ,
        ),
    )

export const loadFormEnabledTenants = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter(x => x.type === ACTION_LOAD_ENABLED_TENANTS),
        withLatestFrom(state$),
        flatMap(
            (): Observable<AnyAction> => {
                const url = lanternUrlV2(
                    `/v2/tenant/forms-enabled-tenants`
                )
                return httpGet(url).pipe(
                    map((result: any) => {
                        return actionStoreEnabledTenants(result)
                    }),
                    defaultCatchError(),
                )}
            ,
        ),
    )
export const appEpics = combineEpics(
  logEpic,
  goBackEpic,
  complaintsEpic,
  fireEpics,
  autocompleteEpics,
  otherEpics,
  refreshAccessToken,
  loadTenantData,
    loadFormEnabledTenants
)
