import { useSelector } from 'react-redux'
import { AppState } from './app-store'

export const useSessionKey = (): string | undefined => useSelector((state: AppState) => state.appData.sessionKey)

export const useAccessToken = (): string | undefined => useSelector((state: AppState) => state.appData.accessToken)

export const useIsAuthenticated = (): boolean =>
    useSelector((state: AppState) => !!(state.appData.accessToken || state.appData.sessionKey))

export const usePopup = (): any => useSelector((state: AppState) => state.appData.popup)
