import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'redux-form'
import { tr } from 'translations/translate'
import { TEXT_PROPER_SITE_USE, TEXT_REGISTERED_SITE_USE } from 'translations/keys'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { getPropertyValueTypeTranslationKey, getPropertyValueTypes } from 'utils'
import { AdditionalComment } from 'common/additional-comment/additional-comment'
import { Complaint } from 'tax/types/complaint.type'
import { PropertyValueType } from 'tax/enums/property-value-type.enum'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'

export const ComplaintCausePlotType: React.FC<{ fieldName: string; initialValues: Partial<Complaint> }> = props => {
    const { fieldName, initialValues } = props

    const propertyValueTypes = getPropertyValueTypes()

    const currentPropertyValueType =
        (initialValues && initialValues._data && (initialValues._data.valueType as PropertyValueType)) ||
        PropertyValueType.BO
    const currentPropertyValueTypeText = tr(getPropertyValueTypeTranslationKey(currentPropertyValueType))

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Field
                    name={`${fieldName}.correctValueType`}
                    label={`${tr(TEXT_PROPER_SITE_USE)} *`}
                    component={CubitRadioGroupAdapter}
                    options={propertyValueTypes.map(valueType => ({
                        label: tr(getPropertyValueTypeTranslationKey(valueType)),
                        value: valueType,
                    }))}
                    validate={ValidatorRequired}
                    required
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    name="_data.valueType"
                    label={tr(TEXT_REGISTERED_SITE_USE)}
                    component={CubitRadioGroupAdapter}
                    value={currentPropertyValueType}
                    options={[
                        {
                            label: currentPropertyValueTypeText,
                            value: currentPropertyValueType,
                        },
                    ]}
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <AdditionalComment name={`${fieldName}.comment`} />
            </Grid>

            <Grid item xs={12}>
                <CubitFileUpload name={`${fieldName}.files`} />
            </Grid>
        </Grid>
    )
}
