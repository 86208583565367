export enum ConcernCaseFormFieldName {
    Address     = 'Location',
    Description = 'Description',
    SenderName  = 'SenderName',
    SenderEmail = 'SenderEmail',
    SenderPhone = 'SenderPhone',
    Files       = 'Files',

    IsAnonymous = 'IsAnonymous',
}
