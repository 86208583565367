import React from 'react'
import {Field, InjectedFormProps, reduxForm} from 'redux-form'
import {tr} from 'translations/translate'
import {
    TEXT_COMPANY,
    TEXT_COMPANY_NAME,
    TEXT_CONTACT_EMAIL,
    TEXT_CONTACT_PERSON,
    TEXT_CONTACT_PHONE,
    TEXT_DESCRIBE_LOCATION,
    TEXT_EVENT_1,
    TEXT_EVENT_1_2,
    TEXT_EVENT_2,
    TEXT_ORGANIZER,
    TEXT_SEARCH_BY_ADDRESS_OR_BUILDING,
} from 'translations/keys'
import {Grid} from '@material-ui/core'
import {ValidatorRequired} from 'utils/validators/validator-required'
import {CubitTextFieldAdapter} from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {EventNotificationFormFieldName} from './event-notification-form-field-name.enum'
import {CubitGeosuggestAdapter} from 'common/cubit-inputs/react-form-adapters/cubit-geosuggest-adapter'
import {CubitTextarea} from 'common/cubit-textarea/cubit-textarea'
import {FireFormName} from 'fire/enums/form-name.enum'

type EventNotificationFormProps = {}

const Form: React.FC<InjectedFormProps<any, EventNotificationFormProps> & EventNotificationFormProps> = props => {
    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    {tr(TEXT_EVENT_1)}
                </Grid>
                <Grid item xs={12}>
                    {tr(TEXT_EVENT_1_2)}
                </Grid>
                <Grid item xs={12}>
                    <strong>{tr(TEXT_EVENT_2)}</strong>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>

                    <Field
                        name={EventNotificationFormFieldName.CompanyOrOrganizerName}
                        label={`${tr(TEXT_COMPANY)} / ${tr(TEXT_ORGANIZER)}`}
                        placeholder={tr(TEXT_COMPANY_NAME)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={EventNotificationFormFieldName.ContactName}

                        label={tr(TEXT_CONTACT_PERSON)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={EventNotificationFormFieldName.ContactEmail}

                        label={tr(TEXT_CONTACT_EMAIL)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={EventNotificationFormFieldName.ContactPhone}
                        label={tr(TEXT_CONTACT_PHONE)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field
                        name={EventNotificationFormFieldName.Location}
                        label={tr(TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
                        component={CubitGeosuggestAdapter}
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <CubitTextarea
                        name={EventNotificationFormFieldName.LocationDescription}
                        label={tr(TEXT_DESCRIBE_LOCATION)}
                        required
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const EventNotificationForm1 = reduxForm<{}, EventNotificationFormProps>({
    form: FireFormName.EventNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
