import { PropertyValueType } from 'tax/enums/property-value-type.enum'

const propertyValueTypes = [
    PropertyValueType.BO,
    PropertyValueType.FR,
    PropertyValueType.GB,
    PropertyValueType.GF,
    PropertyValueType.GU,
    PropertyValueType.IV,
    PropertyValueType.KO,
    PropertyValueType.NE,
    PropertyValueType.UB,
    PropertyValueType.UF,
    PropertyValueType.UN,
    PropertyValueType.UT,
]

export default () => propertyValueTypes
