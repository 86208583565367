import { AdditionalAuditType, Audit, AuditType, AuditMetaDataStatus } from 'common/audit/audit'

export const isSecondStageGenericCase = (audit: Audit) => {
    return (
        audit.additionalType === AdditionalAuditType.POSTAL_AUDIT &&
        (audit.metaData?.documentationStatus === AuditMetaDataStatus.DOCUMENTATION_RECEIVED || audit.metaData?.documentationStatus === AuditMetaDataStatus.ADDITIONAL_REQUEST_SENT) &&
        (!!audit.metaData?.additionalDocumentationRequest || !!audit.metaData?.results?.length)
    )
}

export const getFormData = (audit: Audit | undefined) => {
    if (!audit?.metaData?.forms) {
        return []
    }
    if (audit.type !== AuditType.OTHER_CASE) {
        return audit.metaData?.forms
    }
    if (!isSecondStageGenericCase(audit)) {
        return audit.metaData?.forms[0] ? [audit.metaData?.forms[0]] : []
    }
    if (isSecondStageGenericCase(audit)) {
        return audit.metaData?.forms[1] ? [audit.metaData?.forms[1]] : []
    }
    return audit.metaData?.forms
}

export const isFirstStageDocRequest = (audit: Audit) => {
    if (audit.type !== AuditType.OTHER_CASE) {
        return true
    }
    return !isSecondStageGenericCase(audit)
}

export const isSecondStageDocRequest = (audit: Audit) => {
    if (audit.type !== AuditType.OTHER_CASE) {
        return true
    }
    return isSecondStageGenericCase(audit)
}

export const getFormValuesFromAudit = (audit: Audit, formId: string) => {
    return audit.metaData?.results.find((r: any) => r.formId === formId)?.values ?? []
}

export const isGenericCase = (audit: Audit | undefined) => {
    return audit?.type === AuditType.OTHER_CASE
}
  
export const haveDocRequest = (audit: Audit | undefined) => {
    return !!audit?.metaData?.documentationRequest || !!audit?.metaData?.additionalDocumentationRequest
}
