import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(2),
    },
}))

export const ContentContainer: React.FC = props => {
    const styles = useStyles()

    return <div className={styles.container}>{props.children}</div>
}
