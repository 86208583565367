import React, { useEffect } from 'react'
import {
    TEXT_INSTALLATION_LOCATION,
    TEXT_NEW_HEATING_SOURCE,
    TEXT_DOCUMENTATION,
} from 'translations/keys'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { useDispatch } from 'react-redux'
import { startSubmit } from 'redux-form'
import { actionFireSubmitForm, actionFireClearCadastreUnit } from 'fire/fire-actions'
import { ScrapValueForm1 } from './scrap-value-form-1'
import { ScrapValueForm2 } from './scrap-value-form-2'
import { ScrapValueForm3 } from './scrap-value-form-3'
import { convertObjectToFormData } from 'utils'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

export const ScrapValue: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(actionFireClearCadastreUnit())
        }
    }, [dispatch])

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        const formData = convertObjectToFormData(formValue, {})
        console.warn({ formData })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formData))
    }

    const fireplaceInstallationSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_INSTALLATION_LOCATION,
            component: <ScrapValueForm1 initialValues={{ type: formDescriptor.type }} />,
        },
        {
            stepLabelTranslationKey: TEXT_NEW_HEATING_SOURCE,
            component: <ScrapValueForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_DOCUMENTATION,
            component: <ScrapValueForm3 onSubmit={handleSubmit} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={fireplaceInstallationSteps}
        />
    )
}
