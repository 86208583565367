import React, {useEffect} from "react";
import {destroy} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/app-store";
import {actionLoadPostalAudit} from "../../other-actions";
import {OtherFormName} from "../../enums/form-name.enum";
import {useParams} from "react-router-dom";
import {CircularProgress, makeStyles, Theme} from "@material-ui/core";
import {ContentHeading} from "../../../common/content-heading/content-heading";
import {ContentDivider} from "../../../common/content-divider/content-divider";
import {DisplayValue} from "../../../common/display-value/display-value";
import {ContentSectionHeading} from "../../../common/content-section-heading/content-section-heading";
import {getAuditStatus, getAuditType} from "common/audit/audit-utils";
import {formatDate} from "utils/format/format-date";
import { MappingCaseFormFieldName } from "./mapping-case-form-field-names.enum";
import { FormBuilderData, FieldGroup, TextAreaData } from "./FormBuilderData";
import { getMappingTargetName } from "./mapping-helper";

type Props = {}
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: '16px 40px'
    }
}))
export const PostalAuditInfo: React.FC<Props> = props => {
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const dispatch = useDispatch()
    const classes = useStyles()
    const {tenantId, auditId, pin, targetId} = useParams()
    const formBuilderData: FormBuilderData = audit && audit.formBuilderData ? JSON.parse(audit.formBuilderData) : null;

    useEffect(() => {
        if (tenantId && auditId && pin) {
            dispatch(actionLoadPostalAudit(tenantId, auditId, pin))
        }
        dispatch(destroy(OtherFormName.PostalAudit))

    }, [])
    if (!audit || !audit.formBuilderData) {
        return <ContentHeading><CircularProgress color={'primary'}/></ContentHeading>
    }
    
    const target: any = audit.targets.length ? audit.targets.filter((target: any) => target.id === targetId || (target.owner && target.owner.id === targetId))[0] : null

    return (
        <div>
            <ContentHeading>
                <div className={classes.header}>
                    <span>Kartlegging tilsyn</span>
                    <span>{audit.caseNumber}</span>
                </div>
            </ContentHeading>
            <ContentDivider/>
            <div className={classes.content}>
                <DisplayValue label={'Type'} value={getAuditType(audit)}/>
                <DisplayValue label={'Objekt'}
                              value={target ? getMappingTargetName(target) : '-'}/>
                <DisplayValue label={'Opprettet'} value={formatDate(audit.created)}/>
                <DisplayValue label={'Status'} value={getAuditStatus(audit)}/>
            </div>
            {audit.elCompanyTefts && audit.elCompanyTefts.length > 0 && <div className={classes.content}>
                <ContentSectionHeading>Tema for tilsyn</ContentSectionHeading>
                <div>Følgende er tema for tilsyn:</div>
                <ul>
                    {audit.elCompanyTefts.map((teft: string, index: number) => <li key={index}>{teft}</li>)}
                </ul>

            </div>}

        </div>
    )
}