export enum EventNotificationFormFieldName {
    // form 1
    ContactName = 'contactName',
    ContactEmail = 'contactEmail',
    ContactPhone = 'contactPhone',

    CompanyOrOrganizerName = 'companyOrOrganizerName',
    Location = 'location',
    LocationDescription = 'locationDescription',

    // form 2
    ArrangementDescription = 'arrangementDescription',
    DateFrom = 'dateFrom',
    DateTo = 'dateTo',
    IsPyrotechnicsUsed = 'isPyrotechnicsUsed',
    IsAccessibilityToFireServiceSafeguarded = 'isAccessibilityToFireServiceSafeguarded',

    // form 3
    RiskAssessmentFiles = 'riskAssessmentFiles',
}
