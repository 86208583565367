import React from 'react'
import {InjectedFormProps, reduxForm} from 'redux-form'
import {OtherFormName} from "../../enums/form-name.enum";

import { FormBuilderData } from './FormBuilderData';
import { FormBuilderFields } from './FormBuilderFields'

type MappingFormProps = {
    formBuilderData?: string
}

const Form: React.FC<InjectedFormProps & MappingFormProps> = props => {
    const { handleSubmit, formBuilderData } = props
    const data: FormBuilderData = formBuilderData ? JSON.parse(formBuilderData) : null

    return (
        <form onSubmit={handleSubmit}>
            {data &&
                <FormBuilderFields data={data} mapping={true} />
            }
        </form>
    )
}

export const MappingAuditForm = reduxForm<{}, MappingFormProps>({
    form: OtherFormName.PostalAudit,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
