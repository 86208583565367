import React, {useEffect} from 'react'

const CubitHiddenFieldAdapter: React.FC<any> = (props: any) => {
    const {
        input: { onChange },
        inputValue,
    } = props
    useEffect(() => {
        onChange(inputValue)
    }, [inputValue])

    return null
}
export { CubitHiddenFieldAdapter }
