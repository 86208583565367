import React from "react";
import {makeStyles, Theme, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => ({
    row: {
        maxWidth: 350,
        marginBottom:8,
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        width:120,
        fontWeight:600
    },
    value: {}
}))
type Props = {
    label: string
    value: any
}
export const DisplayValue: React.FC<Props> = ({label, value}) => {
    const classes = useStyles()
    return <div className={classes.row}>
        <Typography className={classes.label}>{label}</Typography>
        <div className={classes.value}>{value}</div>
    </div>
}