import {FireLogo} from "../../fire/fire-logo/fire-logo";
import {ContentContainer} from "../content-container/content-container";
import {ContentSection} from "../content-section/content-section";
import LayoutDefault from "../layout-default/layout-default";
import React from "react";
import {ContentHeading} from "../content-heading/content-heading";

type Props = {}
export const ThankYou: React.FC<Props> = () => {
   return <LayoutDefault logo={<FireLogo/>}>
        <ContentContainer>
            <ContentHeading>Dokumentasjonen er innsendt.</ContentHeading>
            <ContentSection>
                Der som det er behov for ytterligere informasjon vil du få varsel om dette. <br />
                Når tilsynet er gjennomført vil du få en tilsynsrapport tilsendt med status for hvert av temaene og eventuelle funn.
            </ContentSection>
        </ContentContainer>
    </LayoutDefault>
}