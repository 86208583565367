import React from 'react'
import { Field } from 'redux-form'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { HousingUnitAutocompleteObject } from 'fire/types/housing-unit-autocomplete-object.type'
import getOwnerName from 'utils/owner/get-owner-name'
import { CubitSelectAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-adapter'

export const HousingUnitOwnerSelect: React.FC<{
    name: string
    label: string
    housingUnitObject: HousingUnitAutocompleteObject | null
}> = props => {
    const { name, label, housingUnitObject } = props

    const owners = housingUnitObject ? housingUnitObject.owners : []

    const ownerOptions = owners.map(owner => ({
        label: getOwnerName(owner),
        value: owner.id,
    }))

    return (
        <Field
            name={name}
            label={label}
            component={CubitSelectAdapter}
            options={ownerOptions}
            valueIsObject={false}
            InputLabelProps={{
                shrink: true,
            }}
            variant="filled"
            validate={ValidatorRequired}
            required
        />
    )
}
