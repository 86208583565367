import React from 'react'
import { Field } from 'redux-form'
import {
    AutocompleteAdapterProps,
    CubitAutocompleteAdapter,
} from 'common/cubit-autocomplete/cubit-autocomplete-adapter'
import { TEXT_COMPANY } from 'translations/keys'
import { tr } from 'translations/translate'
import { Company } from '../../tax/types/company.type'
import { companiesUrl } from '../../common/services/http-service'
import { useSessionKey } from '../../app/app-selectors'

export const COMPANIES_AUTO_COMPLETE_NAME = 'companiesAutocomplete'
export const CompanyAutocomplete: React.FC<{
    name: string
    isNational?: boolean
    validate?: any
    valueChanged: (company: Company) => any
    valueSetter?: (company: Company) => any
    autoFocus?: boolean
}> = props => {
    const { name, valueChanged, valueSetter, isNational, validate, autoFocus } = props
    const sessionKey = useSessionKey()

    const getOptionSelected = (option: Company, value: Company) => option.id === value.id

    const getOptionLabel = (option: Company) => `${option.name} ${option.organizationNumber}`

    const renderOption = (option: Company) => {
        return <span>{`${option.name} ${option.organizationNumber}`}</span>
    }
    const compare = (a: Company, b: Company) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
    }

    const handleCompanyChange = (company: Company): void => {
        valueChanged(company)
    }

    const autocompleteProps: AutocompleteAdapterProps<Company> = {
        label: tr(TEXT_COMPANY),
        getOptionSelected,
        getOptionLabel,
        renderOption,
        validate,
        compare,
        autoCompleteName: COMPANIES_AUTO_COMPLETE_NAME,
        valueChanged: handleCompanyChange,
        valueSetter,
        fetchUrl: (inputText: string) => companiesUrl(isNational ? `/brreg/${inputText}` : `/company/search/${sessionKey}?q=${inputText}`),
    }

    return <Field name={name} component={CubitAutocompleteAdapter} {...autocompleteProps} autoFocus={autoFocus} />
}
