import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { RegisteredFieldState } from 'redux-form/lib/reducer'

export const useFireCadastreUnit = () => useSelector((state: AppState) => state.fire.cadastreUnit)

export const useFireOwnerId = () => useSelector((state: AppState) => state.fire.ownerId)

export const useFormRegisteredFields = (formName: string): RegisteredFieldState[] | undefined =>
    useSelector((state: AppState) => state.form[formName]?.registeredFields)


export const useUserInfo = () => useSelector((state: AppState) => state.fire.userInfo)
