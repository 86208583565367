import React, { useState } from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { PyrotechnicsSaleFormFieldName } from './pyrotechnics-sale-form-field-name.enum'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { Grid, FormControlLabel, FormGroup, FormControl } from '@material-ui/core'
import { tr } from 'translations/translate'
import {
    TEXT_CONTACT_PHONE,
    TEXT_CONTACT_EMAIL,
    TEXT_CONTACT_PERSON,
    TEXT_SEARCH_BY_ORG_ADDRESS_OR_NAME,
    TEXT_PYRO_SALE_2,
    TEXT_PYRO_SALE_3,
    TEXT_PYRO_SALE_1,
    TEXT_NAME,
    TEXT_EMAIL,
    TEXT_MOBILE_NUMBER,
} from 'translations/keys'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { CubitCheckboxAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { CompanyAutocomplete } from '../../form-components/company-autocomplete'
import { ChimneyInstallationFormFieldName } from '../chimney-installation/chimney-installation-form-field-name.enum'
import { CompanyInfo } from '../../components/company-info'
import { Company } from '../../../tax/types/company.type'
import { AccommodationNotificationFormFieldName } from '../accommodation-notification/accommodation-notification-field-name.enum'
import { useSelector } from 'react-redux'
import { AppState } from '../../../app/app-store'

const Form: React.FC<InjectedFormProps<any>> = () => {
    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.PyrotechnicsSale)
    const isSomeoneResponsibleForReporting =
        selector(state, PyrotechnicsSaleFormFieldName.SomeoneResponsibleForReport) === true
    const [company, setCompany] = useState<Company | null>(null)
    return (
        <form>
            <ContentSectionContainer>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Field
                                    name={PyrotechnicsSaleFormFieldName.ContactName}
                                    label={tr(TEXT_CONTACT_PERSON)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={PyrotechnicsSaleFormFieldName.ContactPhone}
                                    label={tr(TEXT_CONTACT_PHONE)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={PyrotechnicsSaleFormFieldName.ContactEmail}
                                    label={tr(TEXT_CONTACT_EMAIL)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CubitYesNoRadio
                                    name={PyrotechnicsSaleFormFieldName.SomeoneResponsibleForReport}
                                    label={tr(TEXT_PYRO_SALE_1)}
                                    validate={ValidatorRequired}
                                />
                            </Grid>
                            {isSomeoneResponsibleForReporting && (
                                <>
                                    <Grid item xs={12}>
                                        <Field
                                            name={PyrotechnicsSaleFormFieldName.ResponsibleReporterName}
                                            label={'Ansvarlig person'}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name={PyrotechnicsSaleFormFieldName.ResponsibleReporterEmail}
                                            label={'Ansvarlig e-post'}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name={PyrotechnicsSaleFormFieldName.ResponsibleReporterMobile}
                                            label={'Ansvarlig telefon'}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSectionContainer>

            <ContentSectionContainer>
                <Grid container spacing={4}>
                    <Grid item xs={9}>
                        <CompanyAutocomplete
                            isNational={true}
                            name={PyrotechnicsSaleFormFieldName.OrganizationNumber}
                            valueChanged={(company) => {
                                setCompany(company)
                            }}
                            valueSetter={(value) => {
                                return value.organizationNumber
                            }}
                        />
                    </Grid>
                    {company && (
                        <Grid item xs={12}>
                            <CompanyInfo company={company} />
                        </Grid>
                    )}
                </Grid>
            </ContentSectionContainer>

            <ContentSectionContainer>
                <ContentSectionHeading variant="subtitle2">{tr(TEXT_PYRO_SALE_2)}</ContentSectionHeading>

                <FormControl>
                    <FormGroup>
                        <FormControlLabel
                            label={tr(TEXT_PYRO_SALE_3)}
                            control={
                                <Field
                                    name={PyrotechnicsSaleFormFieldName.ResponsiblePersonConfirm}
                                    component={CubitCheckboxAdapter}
                                    validate={ValidatorRequired}
                                    required
                                />
                            }
                        />
                    </FormGroup>
                </FormControl>
            </ContentSectionContainer>
        </form>
    )
}

export const PyrotechnicsSaleForm1 = reduxForm<{}>({
    form: FireFormName.PyrotechnicsSale,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
