import { AnyAction } from 'redux'

export const ACTION_AUTOCOMPLETE_GET = 'ACTION_AUTOCOMPLETE_GET'
export const ACTION_AUTOCOMPLETE_GET_SUCCESS = 'ACTION_AUTOCOMPLETE_GET_SUCCESS'

export const actionAutocompleteGet = (name: string, endpoint: string): AnyAction => ({
    type: ACTION_AUTOCOMPLETE_GET,
    name,
    endpoint,
})

export const actionAutocompleteGetSuccess = (name: string, response: any): AnyAction => ({
    type: ACTION_AUTOCOMPLETE_GET_SUCCESS,
    name,
    response,
})
