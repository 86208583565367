import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { Grid } from '@material-ui/core'
import { TEXT_RISK_ASSESSMENT_FOR_EVENT } from 'translations/keys'
import { tr } from 'translations/translate'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { EventNotificationFormFieldName } from './event-notification-form-field-name.enum'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { FireFormName } from 'fire/enums/form-name.enum'

type EventNotificationFormProps = {}

const Form: React.FC<InjectedFormProps<any, EventNotificationFormProps> & EventNotificationFormProps> = props => {
    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit}>
            <ContentSectionHeading>{tr(TEXT_RISK_ASSESSMENT_FOR_EVENT)}</ContentSectionHeading>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CubitFileUpload name={EventNotificationFormFieldName.RiskAssessmentFiles} autoFocus />
                </Grid>
            </Grid>

            <ContentSectionHeading>Risikovurdering må ivareta følgende momenter:</ContentSectionHeading>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ul>
                        <li>
                            Tidspunkt og tema for arrangementet, størrelse, stedsangivelse, ansvarlig for arrangementet
                        </li>
                        <li>
                            Risikoanalyse med kartlegging av hva som kan gå galt, hvordan dette skal forhindres og
                            hvilke sikkerhetstiltak som er planlagt for å redusere konsekvensene dersom noe galt skjer
                        </li>
                        <li>
                            Beskrivelse av bygningens eller områdets utforming og tilhørende installasjoner eller
                            utstyr, slik som alarm- og lydanlegg, slukkeutstyr og rømningsskilt/ belysning
                        </li>
                        <li>
                            Beskrivelse av innredningens og utstyrets brennbarhet, brannfarlig virksomhet samt
                            bygningskonstruksjonenes brannmotstand og evne til å motstå sammenstyrtning
                        </li>
                        <li>
                            Beskrivelse av rømningsveiene (beliggenhet, antall adkomster, bredde osv.) sett i forhold
                            til beregnet persontall og tilgjengelig rømningstid. Persontall bør eventuelt fastsettes
                            etter særskilt analyse av kvalifisert brannrådgiver
                        </li>
                        <li>Bruk av brannfarlig stoff, herunder propan</li>
                        <li>Prosedyrer for evakuering</li>
                        <li>
                            Oversikt over sikkerhetspersonell (antall, rutiner og ansvar, gjennomført opplæring/øvelse)
                        </li>
                        <li>Tilrettelegging for redningspersonellets adkomst til og i bygning/område</li>
                    </ul>

                    <p>
                        <i>
                            Oversikten er ikke å anse uttømmende og det vises for øvrig til DSBs «Veileder for sikkerhet
                            ved store arrangementer. (Se lenke under)
                        </i>
                    </p>

                    <p>
                        <a
                            href="https://www.dsb.no/veiledere-handboker-og-informasjonsmateriell/veileder-for-sikkerhet-ved-store-arrangementer/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Veileder for sikkerhet ved store arrangementer
                        </a>
                    </p>

                </Grid>
            </Grid>
        </form>
    )
}

export const EventNotificationForm3 = reduxForm<{}, EventNotificationFormProps>({
    form: FireFormName.EventNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
