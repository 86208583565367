import React, { useEffect } from 'react'
import { ContentSection } from 'common/content-section/content-section'
import { FireplaceRemovalForm } from './fireplace-removal-form'
import { useDispatch } from 'react-redux'
import { actionFireSubmitForm, actionFireClearCadastreUnit } from 'fire/fire-actions'
import { destroy, startSubmit } from 'redux-form'
import { ContentActions } from 'common/content-actions/content-actions'
import { FireFormName } from 'fire/enums/form-name.enum'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

export const FireplaceRemoval: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(destroy(FireFormName.FireplaceRemoval))
            dispatch(actionFireClearCadastreUnit())
        }
    }, [dispatch])

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formValue))
    }
  return (
    <ContentSection>
      <FireplaceRemovalForm
        onSubmit={handleSubmit}
        initialValues={{ type: formDescriptor.type }}
        />
      <ContentActions formName={formDescriptor.name} />
    </ContentSection>
  )
}
