import React, {useEffect} from "react";
import {destroy} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/app-store";
import {actionLoadProject} from "../../other-actions";
import {OtherFormName} from "../../enums/form-name.enum";
import {useParams} from "react-router-dom";
import {CircularProgress, makeStyles, Theme} from "@material-ui/core";
import {ContentHeading} from "../../../common/content-heading/content-heading";
import {ContentDivider} from "../../../common/content-divider/content-divider";

type Props = {}
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: '16px 40px'
    }
}))
export const ProjectInfo: React.FC<Props> = props => {
    const project = useSelector((state: AppState) => state.other.project)
    const dispatch = useDispatch()
    const classes = useStyles()
    const {tenantId, projectId} = useParams()
    useEffect(() => {
        if (tenantId && projectId) {
            dispatch(actionLoadProject(tenantId, projectId))
        }
        dispatch(destroy(OtherFormName.ProjectForm))

    }, [])
    if (!project) {
        return <ContentHeading><CircularProgress color={'primary'}/></ContentHeading>
    }
    return (
        <div>
            <ContentHeading>
                <div className={classes.header}>
                    <span>{project.name}</span>
                </div>
            </ContentHeading>
            <ContentDivider/>
        </div>
    )
}