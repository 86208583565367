export enum FireHydrantFormFieldName {
    // form 1
    ConfirmationChecklist = 'confirmationChecklist',
    MapSectionFiles = 'mapSectionFiles',
    DrawingFiles = 'drawingFiles',

    // form 2
    OrganizationName = 'organizationName',
    OrganizationNumber = 'organizationNumber',
    OrganizationAddress = 'organizationAddress',
    ContactEmail = 'contactEmail',
    ContactPhone = 'contactPhone',
    InvoiceAddress = 'invoiceAddress',
    InvoiceNotes = 'invoiceNotes',

    // form 3
    MatrikkelUnitId = 'matrikkelUnitId',
    HousingUnitId = 'housingUnitId',
    WaterOutletName = 'waterOutletName',
    AddressText = 'addressText',
}
