import { ActionReducer } from 'app/types/action-reducer.type'
import { Complaint } from 'tax/types/complaint.type'
import { ACTION_COMPLAINTS_SAVE, ACTION_COMPLAINTS_SAVE_SUCCESS, ACTION_TAXATION_GET, ACTION_TAXATION_GET_SUCCESS } from './complaints-actions'
import { Reducers } from 'app/types/reducers.type'
import { makeReducer } from 'utils'

export type ComplaintsState = { [key: string]: Complaint }

type ComplaintsReducer = ActionReducer<ComplaintsState>

const save: ComplaintsReducer = state => state
const saveSuccess: ComplaintsReducer = state => state

const getTaxation: ComplaintsReducer = state => state

const getTaxationSuccess: ComplaintsReducer = (state, action) => {
    state = {
        ...state,
        [action.taxationId]: action.response
    }

    return state
}

const reducers: Reducers<ComplaintsState> = {
    [ACTION_COMPLAINTS_SAVE]: save,
    [ACTION_COMPLAINTS_SAVE_SUCCESS]: saveSuccess,
    [ACTION_TAXATION_GET]: getTaxation,
    [ACTION_TAXATION_GET_SUCCESS]: getTaxationSuccess,
}

export const complaintsReducer = makeReducer<ComplaintsState>(reducers, {})
