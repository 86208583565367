export enum AccommodationConfirmationOption {
    OwnerConsent = 'OWNER_CONSENT',
    ResponsibleConsent = 'RESPONSIBLE_CONSENT',
    ResponsibleCanFireguard = 'RESPONSIBLE_CAN_FIREGUARD',
    AwakeNightWatch = 'AWAKE_NIGHT_WATCH',
    SufficientNumberOfGuards = 'SUFFICIENT_NUMBER_OF_GUARDS',
    NoFlammableActivity = 'NO_FLAMMABLE_ACTIVITY',
    EveryoneKnowsEscapeRoutes = 'EVERYONE_KNOWS_ESCAPE_ROUTES',
    EscapeRoutesTidyAndAccessible = 'ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE',
    FireAlarmSystemsCheckedAndFunctioning = 'FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING',
    ExtinguishingEquipmentCheckedAndFunctioning = 'EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING',
    ControlSystemCheckedAndFunctioning = 'CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING',
    SprinklerSystemsCheckedAndFunctioning = 'SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING',
    AccommodationLimitedToAgreedBuildingPart = 'ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART',
}
