import React, {useEffect} from "react";
import {destroy, formValueSelector} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/app-store";
import {actionLoadPostalAudit} from "../../other-actions";
import {OtherFormName} from "../../enums/form-name.enum";
import {useParams} from "react-router-dom";
import {CircularProgress, makeStyles, Theme} from "@material-ui/core";
import {ContentHeading} from "../../../common/content-heading/content-heading";
import {ContentDivider} from "../../../common/content-divider/content-divider";
import {DisplayValue} from "../../../common/display-value/display-value";
import {ContentSectionHeading} from "../../../common/content-section-heading/content-section-heading";
import {getAuditStatus, getAuditType} from "common/audit/audit-utils";
import {formatDate} from "utils/format/format-date";
import {AdditionalAuditType} from "../../../common/audit/audit";
import {MappingAuditForm} from "../mapping-case/mapping-case-form";
import {PostalForm} from "./postal-form";
import { isFirstStageDocRequest, isSecondStageDocRequest } from "./postal-audit-helper";

type Props = {}
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: '16px 40px'
    }
}))
export const PostalAuditInfo: React.FC<Props> = props => {
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const dispatch = useDispatch()
    const classes = useStyles()
    const {tenantId, auditId, pin} = useParams()
    useEffect(() => {
        if (tenantId && auditId && pin) {
            dispatch(actionLoadPostalAudit(tenantId, auditId, pin))
        }
        dispatch(destroy(OtherFormName.PostalAudit))

    }, [])
    if (!audit || !audit.metaData) {
        return <ContentHeading><CircularProgress color={'primary'}/></ContentHeading>
    }
    const getTitle = () =>  {
        if(audit.additionalType === AdditionalAuditType.POSTAL_AUDIT) {
            return 'Postalt tilsyn'
        } else {
            return 'Dokumenter før tilsyn'
        }
    }
    const getObjectName = () => {
        if(audit.additionalType === AdditionalAuditType.POSTAL_AUDIT) {
            return audit.targets && audit.targets.length > 0 ? audit.targets[0].specificFireObjectName : '-'
        } else {
            return audit.targets && audit.targets.length > 0 ? audit.targets[0].name : '-'
        }
    }


    return (
        <div>

            <ContentHeading>
                <div className={classes.header}>
                    <span>{getTitle()}</span>
                    <span>{audit.caseNumber}</span>
                </div>
            </ContentHeading>
            <ContentDivider/>
            <div className={classes.content}>
                <DisplayValue label={'Type'} value={getAuditType(audit)}/>
                <DisplayValue label={'Objekt'}
                              value={getObjectName()}/>
                <DisplayValue label={'Opprettet'} value={formatDate(audit.created)}/>
                <DisplayValue label={'Status'} value={getAuditStatus(audit)}/>
            </div>
            {audit.elCompanyTefts && audit.elCompanyTefts.length > 0 && <div className={classes.content}>
                <ContentSectionHeading>Tema for tilsyn</ContentSectionHeading>
                <div>Følgende er tema for tilsyn:</div>
                <ul>
                    {audit.elCompanyTefts?.map((teft: string, index: number) => <li key={index}>{teft}</li>)}
                </ul>

            </div>}

            {isFirstStageDocRequest(audit) && !!audit.metaData?.documentationRequest && <div className={classes.content}>
                <ContentSectionHeading>Vi ber om at følgende dokumenter sendes inn:</ContentSectionHeading>
                <ul>
                    {audit.metaData?.documentationRequest?.split("\n")?.map((comment: string, index: number) => <li key={index}>{comment}</li>)}
                </ul>

            </div>}

            {isSecondStageDocRequest(audit) && !!audit.metaData?.additionalDocumentationRequest && <div className={classes.content}>
                <ContentSectionHeading>Vi ber om at følgende dokumenter sendes inn:</ContentSectionHeading>
                <ul>
                    {audit.metaData?.additionalDocumentationRequest?.split("\n")?.map((comment: string, index: number) => <li key={index}>{comment}</li>)}
                </ul>

            </div>}

        </div>
    )
}
