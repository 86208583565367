import React from 'react'
import { Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ImageIcon from '@material-ui/icons/Image'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import { DateTime } from 'luxon'
import { includes } from 'lodash'

import { TEXT_REMOVE } from 'translations/keys'
import { tr } from 'translations/translate'

const useStyles = makeStyles((theme: Theme) => ({
    fileContainer: {
        position: 'relative',
        transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontSize: 0,
    },

    fileTypeIcon: {
        fontSize: theme.spacing(8),
        padding: theme.spacing(2),
    },

    fileText: {
        fontSize: theme.spacing(2),
    },
}))

export const FilePreview: React.FC<{ index: number; file: File; onRemove: (index: number) => void }> = props => {
    const { index, file, onRemove } = props
    const styles = useStyles()

    const fileName = file.name
        .split('.')
        .slice(0, -1)
        .join('.')

    const fileLastModified = DateTime.fromMillis(file.lastModified)
        .setLocale('nb-no')
        .toLocaleString(DateTime.DATE_SHORT)

    const getFileTypeIcon = (fileType: string): JSX.Element => {
        if (includes(fileType, 'image')) {
            return <ImageIcon className={styles.fileTypeIcon} />
        } else if (includes(fileType, 'pdf')) {
            return <PictureAsPdfIcon className={styles.fileTypeIcon} />
        } else {
            return <InsertDriveFileIcon className={styles.fileTypeIcon} />
        }
    }

    return (
        <div className={styles.fileContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>{getFileTypeIcon(file.type)}</Grid>
                        <Grid item xs className={styles.fileText}>
                            <Typography noWrap>{fileName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item className={styles.fileText}>
                            <Typography>{fileLastModified}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => onRemove(index)} color="primary" aria-label={`${tr(TEXT_REMOVE)} ${file.name}`}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
