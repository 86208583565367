import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { flatMap, filter, map, catchError, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { ACTION_COMPLAINTS_SAVE, actionComplaintSaveSuccess, ACTION_TAXATION_GET, actionTaxationGetSuccess } from './complaints-actions'
import { httpPost, plantsUrl, httpGet } from 'common/services/http-service'
import { Complaint } from 'tax/types/complaint.type'
import { catchErrorAndStopSubmit } from 'app/app-epics'
import { stopSubmit } from 'redux-form'
import { ComplaintsPageFormName } from './complaints-page-form/complaints-page-form'

export const saveComplaintEpic = (action$: Observable<AnyAction>, state$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_COMPLAINTS_SAVE),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(plantsUrl(`/tax/complaints`), action.complaintFormData, state.appData.sessionKey).pipe(
                    flatMap((complaint: Complaint) =>
                        of(actionComplaintSaveSuccess(complaint), stopSubmit(ComplaintsPageFormName)),
                    ),
                    catchErrorAndStopSubmit(ComplaintsPageFormName),
                ),
        ),
    )

export const getTaxationEpic = (action$: Observable<AnyAction>, state$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_TAXATION_GET),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(plantsUrl(`/tax/taxations/${action.taxationId}`), state.appData.sessionKey, state.appData.accessToken).pipe(
                    map((response: any) => actionTaxationGetSuccess(response, action.taxationId)),
                    catchError(
                        (error): Observable<AnyAction> => {
                            console.error(error)
                            return of(actionTaxationGetSuccess([], action.taxationId))
                        },
                    ),
                ),
        ),
    )

export const complaintsEpic = combineEpics(saveComplaintEpic, getTaxationEpic)
