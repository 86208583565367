import React, { useEffect } from 'react'
import { FireFormName } from 'fire/enums/form-name.enum'
import { Grid } from '@material-ui/core'
import { OwnerAutocomplete } from './owner-autocomplete'
import { useSessionKey, useAccessToken } from 'app/app-selectors'
import { actionFireGetOwnerIdByToken } from 'fire/fire-actions'
import { change } from 'redux-form'
import { useFireOwnerId } from 'fire/fire-selectors'
import { useDispatch } from 'react-redux'

export const OwnerSelect: React.FC<{ name: string; formName: FireFormName, showSearch?: boolean }> = ({name, formName, showSearch = false}) => {
    const dispatch = useDispatch()

    const sessionKey = useSessionKey()
    const accessToken = useAccessToken()

    const ownerId = useFireOwnerId()

    useEffect(() => {
        if (accessToken && !showSearch) {
            dispatch(actionFireGetOwnerIdByToken(accessToken))
        }
    }, [dispatch, accessToken, showSearch])

    useEffect(() => {
        if (ownerId) {
            dispatch(change(formName, name, ownerId))
        }
    }, [dispatch, ownerId, formName, name])

    if (sessionKey || showSearch) {
        return (
            <>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                        <OwnerAutocomplete name={name} formName={formName} />
                    </Grid>
                </Grid>
            </>
        )
    } else {
        return null
    }
}
