import {combineEpics} from 'redux-observable'
import {Observable, of} from 'rxjs'
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators'
import {AnyAction} from 'redux'
import {
    httpGet,
    httpGetWithAuthorization,
    httpPost,
    lanternUrl,
    plantsProductionUrl,
    plantsUrl,
} from 'common/services/http-service'
import {catchErrorAndStopSubmit, defaultCatchError} from 'app/app-epics'
import {
    ACTION_FIRE_GET_CADASTRE_UNIT,
    ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID,
    ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION,
    ACTION_FIRE_GET_OWNER_ID_BY_TOKEN,
    ACTION_FIRE_GET_TOKEN,
    ACTION_FIRE_GET_USER_INFO,
    ACTION_FIRE_SIGN_OUT,
    ACTION_FIRE_SUBMIT_FORM,
    actionFireGetCadastreUnitForOrganizationSuccess,
    actionFireGetCadastreUnitSuccess,
    actionFireGetOwnerIdByTokenSuccess,
    actionFireGetTokenSuccess,
    actionFireGetUserInfoSuccess,
    actionFireSignOutSuccess,
    actionFireSubmitFormFailure,
    actionFireSubmitFormSuccess,
    LOAD_CHIMNEY_TYPES,
    loadChimneyTypesSuccess,
} from './fire-actions'
import {CadastreUnit} from './types/cadastre-unit.type'
import {stopSubmit} from 'redux-form'
import history from './../app/history'
import {FireRoute} from 'app/enums/fire-route.enum'
import {getFormSubmitUrl} from './forms/fire-form-descriptors-map'
import {AppState} from 'app/app-store'

export const getFirePropertyEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_CADASTRE_UNIT),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const sessionKey = state.appData.sessionKey
                const tenantId = state.appData.tenantId

                return httpGet(
                    plantsProductionUrl(
                        `/matrikkelunit/getByOwner/${tenantId}/${action.ownerId}${
                            sessionKey ? `?sessionKey=${sessionKey}` : ''
                        }`,
                    ),
                ).pipe(
                    map((cadastreUnit: CadastreUnit) => actionFireGetCadastreUnitSuccess(cadastreUnit)),
                    defaultCatchError(),
                )
            },
        ),
    )

export const getFirePropertyByIdEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_CADASTRE_UNIT_BY_ID),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const sessionKey = state.appData.sessionKey
                const accessToken =  state.appData.accessToken
                const tenantId = state.appData.tenantId
                return httpGet(
                    plantsProductionUrl(
                        `/matrikkelunit/getById/${tenantId}/${action.cadastreId}`,
                    ),sessionKey, accessToken
                ).pipe(
                    map((cadastreUnit: CadastreUnit) => actionFireGetCadastreUnitSuccess(cadastreUnit)),
                    defaultCatchError(),
                )
            },
        ),
    )

export const getFirePropertyForOrganizationEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const tenantId = state.appData.tenantId
                return httpGet(
                    plantsProductionUrl(
                        `/matrikkelunit/getByOrgNumber/${tenantId}/${action.organizationNumber}`,
                    ),
                ).pipe(
                    map((cadastreUnit: CadastreUnit) => actionFireGetCadastreUnitForOrganizationSuccess(cadastreUnit)),
                    defaultCatchError(),
                )
            },
        ),
    )

export const fireSignOutEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_SIGN_OUT),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                const token = state.appData.accessToken
                const redirectUrl = `${window.location.origin}/good-bye`
                const url = lanternUrl(`/openid/end-session?accessToken=${token}&redirectUrl=${redirectUrl}`)
                window.location.href = url;
                return of(actionFireSignOutSuccess())
            },
        ),
    )
export const getFireTokenEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_TOKEN),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> => {
                return httpGet(lanternUrl(`/openid/getToken/${action.code}`)).pipe(
                    map((token: string) => actionFireGetTokenSuccess(token)),
                    defaultCatchError(),
                )
            },
        )
    )

export const submitFormEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_SUBMIT_FORM),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpPost(
                    getFormSubmitUrl(action.formName, state.appData.tenantId),
                    action.formValue,
                    state.appData.sessionKey,
                    state.appData.accessToken,
                ).pipe(
                    flatMap((result: any) => {
                        if (result && result.responseType === 'Error') {
                            return of(
                                actionFireSubmitFormFailure(result),
                                stopSubmit(action.formName))
                        }

                        const redirectUrl = (history.location.pathname.endsWith('/'))
                            ? `${history.location.pathname}${FireRoute.ThankYou.replace('/', '')}`
                            : `${history.location.pathname}${FireRoute.ThankYou}`
                        const routeParams = action.routeParams.length ? `/${action.routeParams.join('/')}` : ''


                        history.push(redirectUrl + routeParams)
                        return of(actionFireSubmitFormSuccess(result), stopSubmit(action.formName))
                    }),
                    catchErrorAndStopSubmit(action.formName),
                ),
        ),
    )

export const getOwnerIdByTokenEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_OWNER_ID_BY_TOKEN),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpGetWithAuthorization(
                    plantsUrl(`/owner/getOwnerWithAccessToken/${state.appData.tenantId}`),
                    action.token,
                ).pipe(
                    map((result: any) => {
                        return actionFireGetOwnerIdByTokenSuccess(result)
                    }),
                    defaultCatchError(),
                ),
        ),
    )


export const getUserInfo = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_FIRE_GET_USER_INFO),
        flatMap(
            (action): Observable<AnyAction> =>
                httpGet(
                    lanternUrl(`/openId/getUserInfo/?accessToken=${action.payload}`)
                ).pipe(
                    map((result: any) => {
                        return actionFireGetUserInfoSuccess(result)
                    }),
                    defaultCatchError(),
                ),
        ),
    )


export const epicLoadChimneyTypes = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === LOAD_CHIMNEY_TYPES),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(
                  plantsProductionUrl(
                      `/listOptions/all/${state.appData.tenantId}`
                  ),
                  state.appData.sessionKey,
                  state.appData.accessToken,
                ).pipe(
                    map((result: any) => {
                        return loadChimneyTypesSuccess(result)
                    }),
                    defaultCatchError(),
                ),
        ),
    )

export const fireEpics = combineEpics(getFirePropertyEpic, getFirePropertyForOrganizationEpic, getFirePropertyByIdEpic, submitFormEpic, getFireTokenEpic, getOwnerIdByTokenEpic, fireSignOutEpic, getUserInfo, epicLoadChimneyTypes)
