import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { flatMap, filter, map, catchError, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { httpGet } from 'common/services/http-service'
import { ACTION_AUTOCOMPLETE_GET, actionAutocompleteGetSuccess } from './autocomplete-actions'
import { AppState } from 'app/app-store'

export const getAutocompleteResultEpic = (action$: Observable<AnyAction>, state$: Observable<AppState>) =>
    action$.pipe(
        filter(x => x.type === ACTION_AUTOCOMPLETE_GET),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                httpGet(action.endpoint, state.appData.sessionKey, state.appData.accessToken).pipe(
                    map((response: any) => actionAutocompleteGetSuccess(action.name, response)),
                    catchError(
                        (error): Observable<AnyAction> => {
                            console.error(error)
                            return of(actionAutocompleteGetSuccess(action.name, []))
                        },
                    ),
                ),
        ),
    )

export const autocompleteEpics = combineEpics(getAutocompleteResultEpic)
