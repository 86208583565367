import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { ButtonGoBack } from 'common/button-go-back/button-go-back'
import { ButtonContained } from 'common/button-contained/button-contained'
import { tr } from 'translations/translate'
import { TEXT_SUBMIT } from 'translations/keys'
import { useSelector, useDispatch } from 'react-redux'
import { isValid, isSubmitting, submit } from 'redux-form'
import { useFormRegisteredFields } from 'fire/fire-selectors'

export const ContentActions: React.FC<{
    formName: string
}> = props => {
    const { formName } = props

    const dispatch = useDispatch()

    const isFormValid = useSelector(isValid(formName))
    const isFormSubmitting = useSelector(isSubmitting(formName))
    const fireError = useSelector(state => (state as any).fire.error)
    const submitForm = () => {
        dispatch(submit(formName))
    }

    const isFormWithFields = useFormRegisteredFields(formName)

    return (
        <>
        { fireError &&
          <div className="error" role="alert" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {tr(fireError.message)}
          </div>
        }
        <Grid container justifyContent="space-between">
            <Grid item>
                <ButtonGoBack />
            </Grid>
            {isFormWithFields && (
                <Grid item>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <ButtonContained
                                color="secondary"
                                onClick={submitForm}
                                disabled={!isFormValid || isFormSubmitting}
                            >
                                {isFormSubmitting && (
                                    <CircularProgress
                                        size="16px"
                                        style={{ position: 'absolute', left: 0, right: 0, margin: 'auto' }}
                                    />
                                )}
                                <span style={isFormSubmitting ? { opacity: 0.5 } : {}}>{tr(TEXT_SUBMIT)}</span>
                            </ButtonContained>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
        </>
    )
}
