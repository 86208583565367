import React from 'react'
import { WrappedFieldArrayProps, Field, FieldArray, change } from 'redux-form'
import { TEXT_CHOOSE_BUILDING, TEXT_ADD_BUILDING } from 'translations/keys'
import { CubitSelectAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { tr } from 'translations/translate'
import { Grid, IconButton } from '@material-ui/core'
import { ButtonOutlined } from 'common/button-outlined/button-outlined'
import ClearIcon from '@material-ui/icons/Clear'
import { TaxationPropertyObject } from 'tax/types/taxation-property-object.type'
import { ComplaintsPageFormBuildingFloors } from './complaints-page-form-building-floors'
import { useDispatch } from 'react-redux'
import { ComplaintsPageFormName } from './complaints-page-form'
import { find } from 'lodash'
import { Building } from 'tax/types/building.type'
import { ValidatorRequired } from 'utils/validators/validator-required'

export const ComplaintsPageFormBuildingsFieldArray: React.FC<WrappedFieldArrayProps<string> & {
    propertyObject: TaxationPropertyObject
    buildingCodes: { [key: number]: string }
    includeFloors: boolean
}> = props => {
    const { fields, propertyObject, buildingCodes, includeFloors } = props
    const dispatch = useDispatch()

    const buildings = propertyObject.buildings

    const handleRemoveBuilding = (index: number) => {
        fields.remove(index)
    }

    const handleAddBuilding = () => {
        fields.push('')
    }

    const handleBuildingChange = (e: any, field: string) => {
        if (!includeFloors) {
            return
        }
        const buildingId = e.target.value
        const building = find(propertyObject.buildings, (b: Building) => b.id === buildingId)

        dispatch(change(ComplaintsPageFormName, `${field}.floors`, building ? building.floors : []))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {fields.map((field: any, index: number) => {
                    return (
                        <Grid container spacing={2} alignItems="center" key={index}>
                            <Grid item xs>
                                <Field
                                    name={`${field}.id`}
                                    component={CubitSelectAdapter}
                                    label={tr(TEXT_CHOOSE_BUILDING)}
                                    valueIsObject={false}
                                    options={buildings.map(b => ({
                                        label: `${propertyObject.property.holdingNumber}/${
                                            propertyObject.property.subHoldingNumber
                                        } - ${b.buildingNumber} ${b.buildingCode} ${buildingCodes[b.buildingCode]}`,
                                        value: b.id,
                                    }))}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e: any) => handleBuildingChange(e, field)}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item>
                                {fields.length > 1 && (
                                    <IconButton onClick={() => handleRemoveBuilding(index)} color="primary">
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </Grid>

                            {includeFloors && (
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    <FieldArray
                                        name={`${field}.floors`}
                                        component={ComplaintsPageFormBuildingFloors}
                                    ></FieldArray>
                                </Grid>
                            )}
                        </Grid>
                    )
                })}
            </Grid>

            {fields.length < buildings.length && (
                <Grid item xs={12}>
                    <ButtonOutlined color="primary" onClick={handleAddBuilding}>
                        {tr(TEXT_ADD_BUILDING)}
                    </ButtonOutlined>
                </Grid>
            )}
        </Grid>
    )
}
