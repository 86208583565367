import React, {useEffect} from 'react'
import {useLocation, Redirect} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionStoreAccessToken} from "../app/app-actions";

const useQuery = () => new URLSearchParams(useLocation().search);

export const OpenIdLoginPage: React.FC = () => {
    const params = useQuery()
    const token = params.get("token")
    const redirect = params.get("redirect")
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(actionStoreAccessToken(token ? encodeURIComponent(token) : ''))
    }, [token, dispatch])

    return <Redirect to={`${redirect}`}/>
}
