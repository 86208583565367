import React from 'react'
import {TEXT_ABOUT_EVENT, TEXT_LOCATION, TEXT_RISK_ASSESSMENT} from 'translations/keys'
import {useDispatch, useSelector} from 'react-redux'
import {formValueSelector, startSubmit} from 'redux-form'
import {CubitStepperStep} from 'common/cubit-stepper/cubit-stepper-step.type'
import {CubitStepper} from 'common/cubit-stepper/cubit-stepper'
import {EventNotificationForm1} from './event-notification-form-1'
import {EventNotificationForm2} from './event-notification-form-2'
import {EventNotificationForm3} from './event-notification-form-3'
import {actionFireSubmitForm} from 'fire/fire-actions'
import {convertObjectToFormData} from 'utils'
import {AppState} from 'app/app-store'
import {EventNotificationFormFieldName} from './event-notification-form-field-name.enum'
import {EventPyrotechnics} from 'fire/enums/event-pyrotechnics.enum'
import {FireFormPageProps} from 'fire/types/fire-form-page-props.type'

export const EventNotification: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(formDescriptor.name)

    // const tenantId = useSelector((state: AppState) => state.appData.tenantId)
    //   useEffect(() => {
    //       if (tenantId) {
    //           dispatch(actionStoreTenantId(encodeURIComponent(tenantId)))
    //       }
    //   }, [dispatch, tenantId])

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        const formData = convertObjectToFormData(formValue, {})
        console.warn({ formData })

        dispatch(startSubmit(formDescriptor.name))

        const isPyrotechnicsUsed = selector(state, EventNotificationFormFieldName.IsPyrotechnicsUsed)

        dispatch(
            actionFireSubmitForm(formDescriptor.name, formData, [
                isPyrotechnicsUsed ? EventPyrotechnics.PyrotechnicsUsed : EventPyrotechnics.PyrotechnicsNotUsed,
            ]),
        )
    }

    const fireplaceInstallationSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_LOCATION,
            component: <EventNotificationForm1 initialValues={{ type: formDescriptor.type }} />,
        },
        {
            stepLabelTranslationKey: TEXT_ABOUT_EVENT,
            component: <EventNotificationForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_RISK_ASSESSMENT,
            component: <EventNotificationForm3 onSubmit={handleSubmit} />,
        },
    ]

    return (
      <CubitStepper
        formName={formDescriptor.name}
        steps={fireplaceInstallationSteps}
        />
    )
}
