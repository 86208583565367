import React from 'react'

export const ContentDivider: React.FC = () => {
    return (
        <div
            style={{
                width: '100%',
                height: '0px',
                borderTop: '1px solid rgba(0,0,0,0.1)',
                borderBottom: '1px solid rgba(255,255,255,0.55)',
            }}
        ></div>
    )
}
