import React from "react";
import {reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/app-store";
import {makeStyles, Theme, Divider} from "@material-ui/core";
import {FormBuilderFields} from "../mapping-case/FormBuilderFields";

type Props = {
    formData: any,
    form: string
}
const useStyles = makeStyles((theme: Theme) => ({
    form: {
        padding: '16px 40px'
    }
}))
export const Form: React.FC<Props> = ({formData, form}) => {
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const dispatch = useDispatch()
    const classes = useStyles()


    let data = {formId:form, formTitle: formData.name, fieldGroups: JSON.parse(formData.formBuilderData)}


    return (
        <div className={classes.form}>
            <form onSubmit={(v) => console.log(v)}>
                {data &&
                    <FormBuilderFields data={data}/>
                }
            </form>
        </div>
    )
}

export const PostalForm = reduxForm<{}, Props>({
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)