import React, {useState} from 'react'
import {CubitYesNoRadio} from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import {Grid} from '@material-ui/core'
import {ValidatorRequired} from 'utils/validators/validator-required'
import {tr} from 'translations/translate'
import {TEXT_IS_APPLICANT_OWNER} from 'translations/keys'

export const IS_APPLICANT_OWNER = 'isApplicantOwner'

export const IsApplicantOwnerSelect: React.FC = () => {
    const [isApplicantOwner, setIsApplicantOwner] = useState(false)

    const handleSelectionChange = (value: boolean): void => {
        setIsApplicantOwner(value)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <CubitYesNoRadio
                    name={IS_APPLICANT_OWNER}
                    label={tr(TEXT_IS_APPLICANT_OWNER)}
                    onChange={value => handleSelectionChange(value)}
                    validate={ValidatorRequired}
                />
            </Grid>
        </Grid>
    )
}
