// const convertObjectToFormData = (model: any, form: FormData, namespace: string = ''): FormData => {
//     let formData = form || new FormData()

//     for (let propertyName in model) {
//         if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue
//         let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
//         if (model[propertyName] instanceof Date) formData.append(formKey, model[propertyName].toISOString())
//         else if (model[propertyName] instanceof Array) {
//             model[propertyName].forEach((element: any, index: any) => {
//                 const tempFormKey = `${formKey}[${index}]`
//                 convertObjectToFormData(element, formData, tempFormKey)
//             })
//         } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
//             convertObjectToFormData(model[propertyName], formData, formKey)
//         else formData.append(formKey, model[propertyName].toString())
//     }
//     return formData
// }

const mergeObjects = (object1: any, object2: any) => {
    var objectsToMerge = [object1, object2]

    return objectsToMerge.reduce(function(carry, objectToMerge) {
        Object.keys(objectToMerge).forEach(function(objectKey) {
            carry[objectKey] = objectToMerge[objectKey]
        })

        return carry
    }, {})
}

const isArray = (val: any) => {
    var toString = {}.toString

    return toString.call(val) === '[object Array]'
}

const isJsonObject = (val: any) => {
    return !isArray(val) && typeof val === 'object' && !!val && !(val instanceof Blob)
}

const convert = (jsonObject: any, options: any) => {
    var defaultOptions = {
        showLeafArrayIndexes: true,
        includeNullValues: false,
        mapping: function(value: any) {
            if(typeof value === 'string') {
                return value.trim()
            }
            return value
        },
    }

    var mergedOptions = mergeObjects(defaultOptions, options || {})

    return convertRecursively(jsonObject, mergedOptions)
}

const convertRecursively = (jsonObject: any, options: any, parentKey?: any, carryFormData?: any) => {
    var formData = carryFormData || new FormData()

    var index = 0

    for (var key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            var propName = parentKey || key
            var value: any = options.mapping(jsonObject[key])

            if (parentKey && isJsonObject(jsonObject)) {
                propName = parentKey + '[' + key + ']'
            }

            if (parentKey && isArray(jsonObject)) {
                if (isArray(value) || options.showLeafArrayIndexes) {
                    propName = parentKey + '[' + index + ']'
                } else {
                    propName = parentKey + '[]'
                }
            }

            if (isArray(value) || isJsonObject(value)) {
                convertRecursively(value, options, propName, formData)
            } else if (value instanceof FileList) {
                for (var j = 0; j < value.length; j++) {
                    formData.append(propName + '[' + j + ']', value.item(j))
                }
            } else if (value instanceof Blob) {
                let val = value as any
                formData.append(propName, val, val.name)
            } else if (((value === null && options.includeNullValues) || value !== null) && value !== undefined) {
                formData.append(propName, value)
            }
        }

        index++
    }

    return formData
}

export default convert
