import React from 'react'
import classes from './input-like-value.module.css'

export const InputLikeValue: React.FC<{
    upperValue: string | number
    lowerValue: string | number
}> = props => {
    const { upperValue, lowerValue } = props
    return (
        <div>
            <span className={classes.upperValue}>{upperValue}</span>
            <span className={classes.lowerValue}>{lowerValue}</span>
        </div>
    )
}
