import React from 'react'
import { Field } from 'redux-form'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { tr } from 'translations/translate'
import { TEXT_YES, TEXT_NO } from 'translations/keys'

export const CubitYesNoRadio: React.FC<{
    name: string
    label: string
    onChange?: (value: any) => void
    validate?: (value: any) => string | undefined
    autoFocus?: boolean
}> = props => {
    const { name, label, onChange, validate, autoFocus } = props

    return (
        <Field
            name={name}
            label={!!validate ? `${label}` : label}
            component={CubitRadioGroupAdapter}
            options={[
                {
                    label: tr(TEXT_YES),
                    value: 'true',
                },
                {
                    label: tr(TEXT_NO),
                    value: 'false',
                },
            ]}
            onChange={onChange}
            validate={validate}
            required={!!validate}
            autoFocus={autoFocus}
        />
    )
}
