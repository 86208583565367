export enum ScrapValueFormFieldName {
    // form 1
    OwnerId = 'ownerId',
    AccountNumber = 'accountNumber',
    ContactName = 'contactName',
    ContactNumber = 'contactNumber',
    ContactEmail = 'contactEmail',
    AssociatedFireplaceId = 'associatedFireplaceId',
    OtherFireplace = 'otherFireplace',
    OtherFireplaceDescription = 'otherFireplaceDescription',

    // form 2
    HeatingSource = 'heatingSource',
    AlreadyReported = 'alreadyReported', //if fireplace was previously added using other form
    ReportedFireplaceId = 'reportedFireplaceId', //Id of previously reported fireplace so we do not add it one more time
    ChimneyId = 'chimneyId',
    OtherChimney = 'otherChimney',
    OtherChimneyDescription = 'otherChimneyDescription',
    ProductName = 'productName',
    FuelType = 'fuelType',
    InstallationDate = 'installationDate',

    // form 3
    CompanyId = 'company',
    InstallerNameOrCompanyName = 'installerNameOrCompanyName',
    InstallerPhoneNumber = 'installerPhoneNumber',
    InstallerAddress = 'installerAddress',
    InstallerZipCode = 'installerZipCode',
    ProofOfRefundFiles = 'proofOfRefundFiles',
    InspectorVerificationFiles = 'inspectorVerificationFiles',
    Comment = 'comment',
}
