import React from 'react'
import { LatLong } from 'common/types/lat-long.type'

type IframeMapProps = {
    coords: LatLong | null
}

export const IframeMap: React.FC<IframeMapProps> = props => {
    const { coords } = props

    if (coords) {
        setTimeout(() => {
            const container = document.getElementById('property-map-parent')
            const propertyMapFrame: any = document.getElementById('property-map')

            propertyMapFrame.remove()
            propertyMapFrame.setAttribute('src', 'about:blank')

            container && container.append(propertyMapFrame)

            const url = `https://dev-cubit-norgeskart.azurewebsites.net/#!?project=seeiendom&layers=1002,1013,1014,1015&zoom=17&lat=${coords.lat}&lon=${coords.long}&panel=Seeiendom&markerLat=${coords.lat}&markerLon=${coords.long}&showSelection=true`

            propertyMapFrame && propertyMapFrame.contentWindow.location.replace(url)
        })
    }

    return (
        <div id="property-map-parent" style={{ width: '100%', height: '100%' }}>
            <iframe
                id="property-map"
                title="propertyMap"
                width="100%"
                height="500px"
                frameBorder="0"
                src="about:blank"
            />
        </div>
    )
}
