import { AnyAction } from 'redux'
import { Complaint } from 'tax/types/complaint.type'

export const ACTION_COMPLAINTS_SAVE = 'ACTION_COMPLAINTS_SAVE'
export const ACTION_COMPLAINTS_SAVE_SUCCESS = 'ACTION_COMPLAINTS_SAVE_SUCCESS'
export const ACTION_TAXATION_GET = 'ACTION_TAXATION_GET'
export const ACTION_TAXATION_GET_SUCCESS = 'ACTION_TAXATION_GET_SUCCESS'

export const actionComplaintSave = (complaintFormData: FormData): AnyAction => ({
    type: ACTION_COMPLAINTS_SAVE,
    complaintFormData,
})

export const actionComplaintSaveSuccess = (complaint: Complaint): AnyAction => ({
    type: ACTION_COMPLAINTS_SAVE_SUCCESS,
    complaint,
})

export const actionTaxationGet = (taxationId: string): AnyAction => ({
    type: ACTION_TAXATION_GET,
    taxationId
})

export const actionTaxationGetSuccess = (response: any, taxationId: string): AnyAction => ({
    type: ACTION_TAXATION_GET_SUCCESS,
    taxationId: taxationId,
    response,
})