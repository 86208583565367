import React, { useEffect } from 'react'
import { ContentSection } from 'common/content-section/content-section'
import { actionFireSubmitForm, actionFireClearCadastreUnit } from 'fire/fire-actions'
import { destroy, startSubmit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { ContentActions } from 'common/content-actions/content-actions'
import { TankRemediationForm } from './tank-remediation-form'
import { convertObjectToFormData } from 'utils'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

export const TankRemediation: React.FC<FireFormPageProps> = props => {
    const { formDescriptor } = props
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(actionFireClearCadastreUnit())
            dispatch(destroy(formDescriptor.name))
        }
    }, [dispatch, formDescriptor.name])

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        const formData = convertObjectToFormData(formValue, {})
        console.warn({ formData })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formData))
    }

  return (
    <ContentSection>
      <TankRemediationForm
        onSubmit={handleSubmit}
        initialValues={{ type: formDescriptor.type }}
        />
      <ContentActions formName={formDescriptor.name} />
    </ContentSection>
  )
}
