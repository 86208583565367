import React from 'react'
import { Field } from 'redux-form'
import {
    AutocompleteAdapterProps,
    CubitAutocompleteAdapter,
} from 'common/cubit-autocomplete/cubit-autocomplete-adapter'
import { TEXT_SEARCH_BY_ADDRESS_GNR_BNR } from 'translations/keys'
import { tr } from 'translations/translate'
import { plantsUrl } from '../../common/services/http-service'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { HousingUnitAutocompleteObject } from 'fire/types/housing-unit-autocomplete-object.type'
import { Config } from 'config'
import {useSelector} from "react-redux";
import {AppDataState} from "../../app/app-reducer";
import {AppState} from "../../app/app-store";

export const HOUSING_UNIT_AUTO_COMPLETE_NAME = 'housingUnitAutocomplete'

const getOptionSelected = (option: HousingUnitAutocompleteObject, value: HousingUnitAutocompleteObject) =>
    option.housingUnit.id === value.housingUnit.id
const getOptionLabel = (option: HousingUnitAutocompleteObject) => `${option.housingUnit.address.addressText}`
const renderOption = (option: HousingUnitAutocompleteObject) => {
    return <span>{`${option.housingUnit.address.addressText}`}</span>
}
const compare = (a: HousingUnitAutocompleteObject, b: HousingUnitAutocompleteObject) => {
    if (a.housingUnit.address.addressText > b.housingUnit.address.addressText) return 1
    if (a.housingUnit.address.addressText < b.housingUnit.address.addressText) return -1
    return 0
}

export const HousingUnitAutocomplete: React.FC<{
    name: string
    valueChanged: (housingUnit: HousingUnitAutocompleteObject) => any
    valueSetter?: (housingUnit: HousingUnitAutocompleteObject) => any
    autoFocus?: boolean
}> = props => {
    const { name, valueChanged, valueSetter, autoFocus } = props
    const tenantId = useSelector((state:AppState) => state.appData.tenantId)
    const handleHousingUnitChange = (value: HousingUnitAutocompleteObject): void => {
        valueChanged(value)
    }

    const autocompleteProps: AutocompleteAdapterProps<HousingUnitAutocompleteObject> = {
        label: tr(TEXT_SEARCH_BY_ADDRESS_GNR_BNR),
        getOptionSelected,
        getOptionLabel,
        renderOption,
        compare,
        autoCompleteName: HOUSING_UNIT_AUTO_COMPLETE_NAME,
        valueChanged: handleHousingUnitChange,
        valueSetter,
        fetchUrl: (inputText: string) => plantsUrl(`/housingUnit/search/${tenantId}?q=${inputText}`),
    }

    return (
        <Field
            name={name}
            component={CubitAutocompleteAdapter}
            {...autocompleteProps}
            validate={ValidatorRequired}
            required
            autoFocus={autoFocus}
        />
    )
}
