import { ActionReducer } from 'app/types/action-reducer.type'
import { Reducers } from 'app/types/reducers.type'
import { makeReducer } from 'utils'
import {
    ACTION_FIRE_GET_CADASTRE_UNIT,
    ACTION_FIRE_GET_CADASTRE_UNIT_SUCCESS,
    ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION_SUCCESS,
    ACTION_FIRE_SUBMIT_FORM,
    ACTION_FIRE_SUBMIT_FORM_SUCCESS,
    ACTION_FIRE_SUBMIT_FORM_FAILURE,
    ACTION_FIRE_CLEAR_CADASTRE_UNIT,
    ACTION_FIRE_GET_TOKEN_SUCCESS,
    ACTION_FIRE_GET_OWNER_ID_BY_TOKEN,
    ACTION_FIRE_GET_OWNER_ID_BY_TOKEN_SUCCESS,
    ACTION_FIRE_GET_USER_INFO_SUCCESS,
    ACTION_FIRE_FORM_VALIDATE,
    ACTION_FIRE_FORM_VALIDATE_SUCCESS,
    LOAD_CHIMNEY_TYPES_SUCCESS,
} from './fire-actions'
import { CadastreUnit } from './types/cadastre-unit.type'

export type FireState = {
  ownerId?: string,
  ownerName?: string,
  ownerEmail?: string,
  ownerPhone?: string,
  cadastreUnit?: CadastreUnit,
  organizationCadastreUnit?: CadastreUnit,
  userInfo?: any,
  error?: any,
  plantListOptions?: any
}

type FireReducer = ActionReducer<FireState>


const mapCadastreUnit = (cadastreUnit: any) => {
  if (!cadastreUnit) return cadastreUnit
  if (!cadastreUnit.matrikkelUnits) return cadastreUnit

  const reducer = (acc: any, curr: any) => {
    const matrikkel = cadastreUnit.matrikkelUnits.find((p: any) => p.id === curr.matrikkelUnitId)

    const { holdingNumber, subHoldingNumber, leaseNumber, sectionNumber } = matrikkel || {}

    const matrikkelNumber = `4601/${holdingNumber}/${subHoldingNumber}/${leaseNumber}/${sectionNumber}`
    acc.push({...curr, matrikkelNumber })
    return acc
  }

  const housingUnits = cadastreUnit.housingUnits.reduce(reducer, [])
  return { ...cadastreUnit, housingUnits }
}

const getCadastreUnit: FireReducer = state => state
const getCadastreUnitSuccess: FireReducer = (state, action) => {
    state.cadastreUnit = {
        ...mapCadastreUnit(action.cadastreUnit),
        tanks: action.cadastreUnit.tanks,
    }

    return state
}

const clearCadastreUnit: FireReducer = (state, action) => {
    state.cadastreUnit = void 0
    return state
}

const submitForm: FireReducer = state => state

const getTokenSuccess: FireReducer = (state, action) => {
    return { ...state, token: action.token }
}

const getOwnerIdByToken: FireReducer = state => state
const getOwnerIdByTokenSuccess: FireReducer = (state, action) => {
    const { id, firstName, lastName, email, phone } = action.payload || {}
    return {
      ...state,
      ownerId: id,
      ownerName: (firstName && lastName) ? `${firstName} ${lastName}` : undefined,
      ownerEmail: email,
      ownerPhone: phone,
    }
}


const reducers: Reducers<FireState> = {
    [ACTION_FIRE_GET_CADASTRE_UNIT]: getCadastreUnit,
    [ACTION_FIRE_GET_CADASTRE_UNIT_SUCCESS]: getCadastreUnitSuccess,
    [ACTION_FIRE_GET_CADASTRE_UNIT_FOR_ORGANIZATION_SUCCESS]: (state, action) => {
      const { ownerId, ...rest } = action.payload || {}
      if (!ownerId) return { ...state, cadastreUnit: undefined }
      return {
        ...state,
        ownerId: ownerId,
        cadastreUnit: mapCadastreUnit(rest),
       }
    },
    [ACTION_FIRE_CLEAR_CADASTRE_UNIT]: clearCadastreUnit,
    [ACTION_FIRE_SUBMIT_FORM]: submitForm,
    [ACTION_FIRE_SUBMIT_FORM_SUCCESS]: (state, action) => {
      return { ...state, error: null }
    },
    [ACTION_FIRE_SUBMIT_FORM_FAILURE]: (state, action) => {
      return { ...state, error: action.payload }
    },
    [ACTION_FIRE_GET_TOKEN_SUCCESS]: getTokenSuccess,
    [ACTION_FIRE_GET_OWNER_ID_BY_TOKEN]: getOwnerIdByToken,
    [ACTION_FIRE_GET_OWNER_ID_BY_TOKEN_SUCCESS]: getOwnerIdByTokenSuccess,
    [ACTION_FIRE_GET_USER_INFO_SUCCESS]: (state, action) => {
      return { ...state, userInfo: action.payload }
    },
    [ACTION_FIRE_FORM_VALIDATE]: (state, action) => {
      return { ...state, error: null }
    },
    [ACTION_FIRE_FORM_VALIDATE_SUCCESS]: (state, action) => {
      return { ...state, error: action.payload }
    },
    [LOAD_CHIMNEY_TYPES_SUCCESS]: (state, action) => {
      const data = action.payload || []
      const reducer = (acc : any, curr : any) => {
        acc[curr.listType] = curr.options.map((p : any) => ({value: p.value, label: p.text}))
        return acc
      }
      const result = data.reduce(reducer, {})
      return { ...state, plantListOptions: result }
    },
}

const initialState = { plantListOptions: {} }

export const fireReducer = makeReducer<FireState>(reducers, initialState )
