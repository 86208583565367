import React, { useState, useEffect } from 'react'
import { CubitStepperStep } from './cubit-stepper-step.type'
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    makeStyles,
    withStyles,
    StepConnector,
    Grid,
    CircularProgress,
} from '@material-ui/core'
import { tr } from 'translations/translate'
import { ContentDivider } from 'common/content-divider/content-divider'
import classnames from 'classnames'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AdjustIcon from '@material-ui/icons/Adjust'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { ContentSection } from 'common/content-section/content-section'
import { TEXT_BACK, TEXT_NEXT, TEXT_SUBMIT, TEXT_STEP } from 'translations/keys'
import { ButtonContained } from 'common/button-contained/button-contained'
import { ButtonGoBack } from 'common/button-go-back/button-go-back'
import { isInvalid, submit, isSubmitting, destroy } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { useFormRegisteredFields } from 'fire/fire-selectors'
import { useIsAuthenticated } from '../../app/app-selectors'
import { Authorization } from '../../login-page/authorization'

const DEFAULT_ACTIVE_STEP = 0

const QontoConnector = withStyles(theme => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    line: {
        borderColor: theme.palette.primary.main,
        borderTopWidth: 3,
        borderRadius: 1,
        opacity: 0.5,
    },
}))(StepConnector)

const useQontoStepIconStyles = makeStyles(theme => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    default: {
        color: theme.palette.primary.main,
        opacity: 0.5,
    },
    active: {
        color: theme.palette.primary.main,
    },
    completed: {
        color: theme.palette.primary.main,
    },
    icon: {
        zIndex: 1,
        fontSize: 18,
        borderRadius: '50%',
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}))

const QontoStepIcon = (props: any) => {
    const classes = useQontoStepIconStyles()
    const { active, completed } = props

    return (
        <div
            className={classnames(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <CheckCircleOutlineIcon className={classnames(classes.completed, classes.icon)} />
            ) : active ? (
                <AdjustIcon className={classnames(classes.active, classes.icon)} />
            ) : (
                <RadioButtonUncheckedIcon className={classnames(classes.default, classes.icon)} />
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent',
        padding: theme.spacing(2),
    },
    label: {
        color: theme.palette.primary.main,
        opacity: 0.9,
        '&.MuiStepLabel-active': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
        '&.MuiStepLabel-completed': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
}))

export const CubitStepper: React.FC<{
    formName: string
    steps: CubitStepperStep[]
    withIdPortalAuthentication?: boolean
}> = props => {
    const { formName, steps, withIdPortalAuthentication = true } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const fireError = useSelector(state => (state as any).fire.error)


    useEffect(() => {
        return () => {
            dispatch(destroy(formName))
        }
    }, [dispatch, formName])

    const isFormInvalid = useSelector(isInvalid(formName))
    const isFormSubmitting = useSelector(isSubmitting(formName))
    const isFormWithFields = useFormRegisteredFields(formName)
    const authenticated = useIsAuthenticated()

    const nextDisabled = isFormInvalid || !isFormWithFields

    const [activeStep, setActiveStep] = useState(DEFAULT_ACTIVE_STEP)

    const handleStepNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleStepPrevious = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleSubmitClick = () => {
        dispatch(submit(formName))
    }
    if (!authenticated && withIdPortalAuthentication) {
        return <Authorization />
    }

    return (
        <>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                classes={{ root: styles.root }}
                connector={<QontoConnector />}
            >

              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel classes={{ label: styles.label }} StepIconComponent={QontoStepIcon}>
                    <div>
                      {index === activeStep &&
                        <div
                          role="progressbar"
                          aria-valuenow={index}
                          aria-valuemin={0}
                          aria-valuetext={`${tr(TEXT_STEP)} ${index}: ${tr(step.stepLabelTranslationKey)}`}
                          aria-valuemax={steps.length -1}>
                          {tr(step.stepLabelTranslationKey)}
                        </div>
                      }
                      { index !== activeStep &&
                        <>{tr(step.stepLabelTranslationKey)}</>
                      }
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <ContentDivider />

            <ContentSection>{activeStep < steps.length && steps[activeStep].component}</ContentSection>

            <ContentDivider />

            <ContentSection>
                <>
                { fireError &&
                  <div className="error" role="alert" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {tr(fireError.message)}
                  </div>
                }
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <ButtonGoBack />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                {activeStep !== 0 && <Button onClick={handleStepPrevious}>{tr(TEXT_BACK)}</Button>}
                            </Grid>
                            <Grid item>
                                {activeStep === steps.length - 1 ? (
                                    <ButtonContained
                                        color="secondary"
                                        onClick={handleSubmitClick}
                                        disabled={isFormInvalid || isFormSubmitting}
                                    >
                                        {isFormSubmitting && (
                                            <CircularProgress
                                                size="16px"
                                                style={{ position: 'absolute', left: 0, right: 0, margin: 'auto' }}
                                            />
                                        )}
                                        <span style={isFormSubmitting ? { opacity: 0.5 } : {}}>{tr(TEXT_SUBMIT)}</span>
                                    </ButtonContained>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleStepNext}
                                        disabled={nextDisabled}
                                    >
                                        {tr(TEXT_NEXT)}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </>
            </ContentSection>
        </>
    )
}
