import React from 'react'
import { Grid, InputAdornment } from '@material-ui/core'
import { Field } from 'redux-form'
import { tr } from 'translations/translate'
import { TEXT_CORRECT_AREA, TEXT_REGISTERED_AREA } from 'translations/keys'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { AdditionalComment } from 'common/additional-comment/additional-comment'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'

export const ComplaintCausePlotArea: React.FC<{ fieldName: string }> = props => {
    const { fieldName } = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
                <Field
                    name={`${fieldName}.correctPlotSize`}
                    label={tr(TEXT_CORRECT_AREA)}
                    component={CubitTextFieldAdapter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                    }}
                    validate={ValidatorRequired}
                    type="number"
                    variant="filled"
                    required
                />
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
                <Field
                    name="_data.plotSize"
                    label={tr(TEXT_REGISTERED_AREA)}
                    component={CubitTextFieldAdapter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                    }}
                    variant="filled"
                    className="noBackground"
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <AdditionalComment name={`${fieldName}.comment`} />
            </Grid>
            <Grid item xs={12}>
                <CubitFileUpload name={`${fieldName}.files`} />
            </Grid>
        </Grid>
    )
}
