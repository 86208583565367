export enum TankRemovalFormFieldName {
    OwnerId = 'ownerId',
    TankId = 'tankId',
    DidLeak = 'didLeak',
    PipesRemoved = 'pipesRemoved',
    RemovalDate = 'removalDate',
    CompanyName = 'companyName',
    CompanyAddress = 'companyAddress',
    CompanyZipCode = 'companyZipCode',
    RemovalDocumentation = 'removalDocumentation',
}
