export enum PyrotechnicsUseFormFieldName {

  ContactEmail = 'contactEmail',
  ContactPhone = 'contactPhone',
  IsSenderResponlible = 'isSenderResponlible',

  IsConsumerTypeFireworks = 'isConsumerTypeFireworks',
  IsProfessionalTypeFireworks = 'isProfessionalTypeFireworks',
  IsStageTypeFireworks = 'isStageTypeFireworks',

  CertificateNumber = 'certificateNumber',
  HasWrittenSafetyInstructions = 'hasWrittenSafetyInstructions',

  Location = 'location',
  LocationOutdoorsIndoors = 'LocationOutdoorsIndoors',
  LocationDescription = 'locationDescription',

  DateFrom = 'dateFrom',
  DateTo = 'dateTo',

  Description = 'description',
  Attachments = 'attachments'
}
