import React from 'react'
import {reduxForm, InjectedFormProps, Field} from 'redux-form'

import {FireFormName} from 'fire/enums/form-name.enum'
import {
    CubitCheckboxAdapter,
    CubitYesNoRadio,
    CubitTextFieldAdapter,
    ContentSectionHeading,
    ContentSectionContainer
} from 'common'
import {Grid, FormControlLabel, FormGroup, FormControl} from '@material-ui/core'
import {ValidatorRequired, ValidatorEmail, ValidatorPhone} from 'utils/validators/validator-required'

import {PyrotechnicsUseFormFieldName} from './pyrotechnics-use-form-field-name.enum'
import {tr} from 'translations/translate'
import * as t from 'translations/keys'

import css from './form.module.css'

const Form: React.FC<InjectedFormProps<any>> = () => {
    return (
        <form>
            <Grid container>
                <div className={css.item}>{tr(t.TEXT_PYROTECHNICS_USE_FORM1_DESCRIPTION)}</div>

                <Grid item xs={12} sm={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Field
                                type="email"
                                name={PyrotechnicsUseFormFieldName.ContactEmail}
                                label={tr(t.TEXT_CONTACT_EMAIL)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={[ValidatorRequired, ValidatorEmail]}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Field
                                type="phone"
                                name={PyrotechnicsUseFormFieldName.ContactPhone}
                                label={tr(t.TEXT_CONTACT_PHONE)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={[ValidatorRequired, ValidatorPhone]}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <CubitYesNoRadio
                                name={PyrotechnicsUseFormFieldName.IsSenderResponlible}
                                label={tr(t.TEXT_PYROTECHNICS_USE_IS_SENDER_RESPONSIBLE)}
                                validate={ValidatorRequired}
                            />
                        </Grid>
                    </Grid>

                    <div className={css.item}>{tr(t.TEXT_PYROTECHNICS_USE_IS_TYPE_FIREWORKS)}</div>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={tr(t.TEXT_PYROTECHNICS_USE_IS_CONSUMER_TYPE_FIREWORKS)}
                                control={<Field
                                    name={PyrotechnicsUseFormFieldName.IsConsumerTypeFireworks}
                                    component={CubitCheckboxAdapter}
                                />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={tr(t.TEXT_PYROTECHNICS_USE_IS_PROFESSIONAL_TYPE_FIREWORKS)}
                                control={<Field
                                    name={PyrotechnicsUseFormFieldName.IsProfessionalTypeFireworks}
                                    component={CubitCheckboxAdapter}
                                />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={tr(t.TEXT_PYROTECHNICS_USE_IS_STAGE_TYPE_FIREWORKS)}
                                control={<Field
                                    name={PyrotechnicsUseFormFieldName.IsStageTypeFireworks}
                                    component={CubitCheckboxAdapter}
                                />}
                            />
                        </Grid>
                    </Grid>

                </Grid>


                <div className={css.item}>{tr(t.TEXT_PYROTECHNICS_USE_CERTIFICATE_INFO)}</div>

                <Grid container spacing={4}>
                    <Grid item xs={6}>

                        <Field
                            name={PyrotechnicsUseFormFieldName.CertificateNumber}
                            label={tr(t.TEXT_PYROTECHNICS_USE_CERTIFICATE_NUMBER)}
                            component={CubitTextFieldAdapter}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        />
                    </Grid>
                </Grid>


                <div style={{margin: '20px'}}></div>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <CubitYesNoRadio
                            name={PyrotechnicsUseFormFieldName.HasWrittenSafetyInstructions}
                            label={tr(t.TEXT_PYROTECHNICS_USE_HAS_WRITTEN_SAFETY_INSTRUCTIONS)}
                            validate={ValidatorRequired}
                        />
                    </Grid>
                </Grid>


            </Grid>
        </form>
    )
}

export default reduxForm<{}>({
    form: FireFormName.PyrotechnicsUse,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
