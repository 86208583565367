import React from 'react'
import LayoutDefault from 'common/layout-default/layout-default'
import { ContentContainer } from 'common/content-container/content-container'
import { tr } from 'translations/translate'
import { TEXT_THANK_YOU_FOR_USING_OUR_SERVICES, TEXT_APPLICATION_RECEIVED } from 'translations/keys'
import { makeStyles, Theme, Grid } from '@material-ui/core'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import {FireSignOut} from "../../fire-sign-out/fire-sign-out";

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: theme.typography.h4.fontSize,
        padding: theme.spacing(4, 2),
        letterSpacing: '1.1px',
        fontWeight: 300,
    },
    text: {
        padding: theme.spacing(1, 2),
    },
    thankYouText: {
        padding: theme.spacing(4, 2),
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 300,
    },
}))

export const FireFormSubmitSuccess: React.FC<{ title?: string; content?: string[] }> = props => {
    const { title, content, children } = props

    const styles = useStyles()

    return (
        <Grid container alignItems="center" direction="column">
            <Grid item className={styles.heading}>
                <h1 role="alert">{title || tr(TEXT_APPLICATION_RECEIVED)}</h1>
            </Grid>

            {content?.map((c, i) => (
                <Grid key={i} item className={styles.text}>
                    {c}
                </Grid>
            ))}

            {children && <Grid className={styles.text}>{children}</Grid>}

            <Grid item className={styles.thankYouText}>
                {tr(TEXT_THANK_YOU_FOR_USING_OUR_SERVICES)}
            </Grid>
        </Grid>
    )
}
