import React, { useState } from 'react'
import { FormControlLabel, Grid, makeStyles, Theme } from '@material-ui/core'
import { CubitCheckboxAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { Field } from 'redux-form'

const useStyles = makeStyles((theme: Theme) => ({
    checkboxContainer: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(1),
        },
    },

    contentHidden: {
        height: '0px',
        overflow: 'hidden',
        zIndex: -1,
    },
}))

export const ConditionalFormContent: React.FC<{ label: string; fieldName: string }> = props => {
    const { label, fieldName, children } = props
    const styles = useStyles()
    const [contentVisible, setContentVisible] = useState(false)

    const handleChange = (e: any) => {
        setContentVisible(e.target.checked)
    }
    return (
        <Grid container>
            <Grid item xs={12} className={contentVisible ? styles.checkboxContainer : ''}>
                <FormControlLabel
                    label={label}
                    control={<Field name={fieldName} component={CubitCheckboxAdapter} onChange={handleChange} />}
                />
            </Grid>
            {contentVisible && (
                <Grid item xs={12} className={!contentVisible ? styles.contentHidden : ''}>
                    {children}
                </Grid>
            )}
        </Grid>
    )
}
