import React from 'react'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { Grid } from '@material-ui/core'
import { TaxationPropertyObject } from 'tax/types/taxation-property-object.type'
import { CubitSelectImagesAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-images.adapter'
import classes from './complaints-page-form.module.css'

export const ComplaintsPageFormBuildingsPhotosFieldArray: React.FC<WrappedFieldArrayProps<string> & {
    propertyObject: TaxationPropertyObject
    buildingCodes: any
}> = props => {
    const { fields, propertyObject, buildingCodes } = props

    const buildings = propertyObject.buildings

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} className={classes.buildingPhotosContainer}>
                            <span className={classes.buildingPhotosLabel}>
                                {`${propertyObject.property.holdingNumber}/${
                                    propertyObject.property.subHoldingNumber
                                } - ${buildings[index].buildingNumber} ${buildings[index].buildingCode} ${
                                    buildingCodes[buildings[index].buildingCode]
                                }`}
                            </span>

                            <Field
                                name={field}
                                component={CubitSelectImagesAdapter}
                                valueIsObject={false}
                                options={buildings[index].files.map(f => ({
                                    label: 'image',
                                    value: f.url,
                                }))}
                            />
                        </div>
                    )
                })}
            </Grid>
        </Grid>
    )
}
