import React from 'react'

import { FireLogo } from 'fire/fire-logo/fire-logo'
import { ContentSection } from 'common/content-section/content-section'
import { ContentContainer } from 'common/content-container/content-container'
import { ContentHeading } from 'common/content-heading/content-heading'

import LayoutDefault from 'common/layout-default/layout-default'
import {tr} from 'translations/translate'
import { TEXT_MESSAGE_RECEIVED, TEXT_THANK_YOU_FOR_USING_OUR_SERVICES } from 'translations/keys'

type Props = {}
const ThankYou: React.FC<Props> = () => {
   return <LayoutDefault logo={<FireLogo/>}>
        <ContentContainer>
            <ContentHeading>{tr(TEXT_MESSAGE_RECEIVED)}</ContentHeading>
            <ContentSection>
                {tr(TEXT_THANK_YOU_FOR_USING_OUR_SERVICES)}
            </ContentSection>
        </ContentContainer>
    </LayoutDefault>
}

export default ThankYou
