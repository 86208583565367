import React from 'react'
import { FormBuilderData, FieldGroup } from './FormBuilderData'
import { Grid, Slider } from '@material-ui/core'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { MappingCaseFormFieldName } from './mapping-case-form-field-names.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { Field, formValueSelector } from 'redux-form'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitSelectAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { useSelector } from 'react-redux'

type FormBuilderprops = {
    data: FormBuilderData
    mapping?: boolean
}

const GetField: React.FC<any> = ({ group, index, data, mapping = false }) => {
    const required: any = group.required ? true : undefined
    const selector = formValueSelector(data.formId || 'error')
    const conditionalField = useSelector((state) => selector(state, group.conditional))
    if (group.conditional && group.conditionalValue !== conditionalField) return null
    return (
        <Grid item xs={12} key={index}>
            <h3>{checkRequired(group.inputTitle, group)}</h3>
            <Field
                {...getAdditionalProps(group)}
                name={group.id && !mapping ? group.id :  `response__${index}`}
                variant="filled"
                component={getComponent(group.inputType, group)}
                validate={required ? ValidatorRequired : undefined}
                group={group}
            />
        </Grid>
    )
}

const getComponent = (type: string, group: FieldGroup) => {
    switch (type) {
        case MappingCaseFormFieldName.FreeText:
            return CubitTextFieldAdapter
        case MappingCaseFormFieldName.Checkboxes:
            return CubitCheckboxGroupAdapter
        case MappingCaseFormFieldName.RadioButtons:
            return CubitRadioGroupAdapter
        case MappingCaseFormFieldName.Dropdown:
            return CubitSelectAdapter
        case MappingCaseFormFieldName.Slider:
            return SliderComponent
        default:
            return CubitTextFieldAdapter
    }
}
const getOptions = (variants: any) => {
    return variants.map((variant: any, i: number) => ({ label: variant, value: i.toString() }))
}
const checkRequired = (label: string, group: FieldGroup) => {
    return group.required ? `${label} *` : label
}
const getAdditionalProps = (group: FieldGroup) => {
    const type: string = group.inputType
    switch (type) {
        case MappingCaseFormFieldName.FreeText:
            return {
                label: 'Svartekst',
            }
        case MappingCaseFormFieldName.Checkboxes:
            return {
                options: getOptions(group.variants),
                valueIsObject: false,
                row: false,
            }
        case MappingCaseFormFieldName.RadioButtons:
            return {
                options: getOptions(group.variants),
            }
        case MappingCaseFormFieldName.Dropdown:
            return {
                options: getOptions(group.variants),
                label: 'Velg et alternativ',
                valueIsObject: false,
            }
        default:
            return null
    }
}
const SliderComponent: React.FC<any> = (props) => {
    const { input, group } = props
    const slideroptions: any = group.variants[0]
    return (
        <Grid container>
            <Grid item style={{ marginRight: 50 }}>
                {slideroptions.fromText}
            </Grid>
            <Grid item xs>
                <Slider
                    valueLabelDisplay="auto"
                    {...input}
                    value={typeof input.value === 'number' ? input.value : 0}
                    onChange={(e: any, val: number) => {
                        input.onChange(val)
                    }}
                    min={slideroptions.from}
                    max={slideroptions.to}
                    marks
                />
            </Grid>
            <Grid item style={{ marginLeft: 50 }}>
                {slideroptions.toText}
            </Grid>
        </Grid>
    )
}

export const FormBuilderFields: React.FC<FormBuilderprops> = (props) => {
    const { data, mapping } = props
    if (!data.fieldGroups) return null
    const documentsDataGroup: any = data.fieldGroups?.filter(
        (fg: FieldGroup) => fg.inputType === MappingCaseFormFieldName.Documents,
    )[0]
    const documentsData: string = documentsDataGroup && documentsDataGroup.variants[0]
        ? documentsDataGroup.variants[0][MappingCaseFormFieldName.Documents]
        : ''

    return (
        <>
            <ContentSectionHeading>{data.formTitle}</ContentSectionHeading>
            {data.fieldGroups
                .filter((group: FieldGroup) => group.inputType !== MappingCaseFormFieldName.Documents)
                .map((group: FieldGroup, index: number) => (
                    <GetField group={group} key={index} index={index} data={data} mapping={mapping} />
                ))}
            {documentsData && (
                <>
                    <ContentSectionHeading>
                        {checkRequired(documentsDataGroup.inputTitle, documentsDataGroup)}
                    </ContentSectionHeading>
                    <ul>
                        {documentsData.split('\n').map((comment: string, index: number) => (
                            <li key={index}>{comment}</li>
                        ))}
                    </ul>
                    <CubitFileUpload
                        name={MappingCaseFormFieldName.Documentation}
                        validate={documentsDataGroup.required ? ValidatorRequired : undefined}
                    />
                </>
            )}
        </>
    )
}
