export enum ChimneyInstallationFormFieldName {
    // form 1
    CompanyId = 'companyId',
    CompanyName = 'companyName',
    CompanyOrgNr = 'companyOrgNr',
    CompanyAddress = 'companyAddress',
    CompanyPostalCode = 'companyPostalCode',
    ContactEmail = 'contactEmail',
    ContactPhoneNumber = 'contactPhoneNumber',
    LiabilityFiles = 'liabilityFiles',

    // form 2
    ConstructionType = 'constructionType',
    ChimneyId = 'chimneyId',
    HousingUnitId = 'housingUnitId',
    OwnerId = 'ownerId',

    // form 3
    ChimneyTypeNewConstruction = 'chimneyTypeNewConstruction2',
    ChimneyTypeBeforeRehab = 'chimneyTypeBeforeRehab2',
    NumberOfRaces = 'numberOfRaces',
    ChimneyTypeAfterRehab = 'chimneyTypeAfterRehab2',
    CrossSectionDiameter = 'crossSectionDiameter2',
    OldCrossSectionDiameter = 'oldCrossSectionDiameter2',
    NewCrossSectionDiameter = 'newCrossSectionDiameter2',
    NumberOfRunningMeters = 'numberOfRunningMeters',
    Placement = 'placement',
    TechnicalApprovalMarking = 'technicalApprovalMarking',
    ProductName = 'productName',
    InstallationTerms = 'installationTerms',

    // form 3
    DrawingFiles = 'drawingFiles',
    TargetedSketchFiles = 'targetedSketchFiles',
    MountingDescriptionFiles = 'mountingDescriptionFiles',
    NotificationToNeighboursFiles = 'notificationToNeighboursFiles',
    CentralApprovalFiles = 'centralApprovalFiles',
}
